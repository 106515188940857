import { useMemo } from 'react';
import { IAllocationDetails } from 'src/apps/admin/components/pages/ProjectAllocations/AllocationsList';

import PaymentConfirmationCell from '@/admin/components/common/PaymentConfirmationCell';
import PaymentProcessCell from '@/admin/components/common/PaymentProcessCell';
import PaymentUpdateCell from '@/admin/components/common/PaymentUpateCell';
import UnitCartPaymentAddCell from '@/admin/components/common/UnitCartPaymentAddCell';
import { useGetProject } from '@/admin/hooks/useGetProject';
import { Icon } from '@/atoms/Icon';
import { Text } from '@/atoms/Text/Text';
import Modal from '@/booking/components/common/modal/ModalTS';
import type { IPaymentOrder } from '@/booking/interfaces';
import { useLazyGetPaymentAuditLogsQuery } from '@/booking/services/paymentsAPISlice';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

import styles from './styles.module.css';

interface IPaymentActionModalProps {
  allocation?: IAllocationDetails;
  paymentOrder?: IPaymentOrder;
  paymentActionType: 'process' | 'confirm' | 'update' | 'add' | 'addToken';
  onPaymentUpdate?: () => void;
  onClose: () => void;
}

export const PaymentActionModal = (props: IPaymentActionModalProps) => {
  const {
    allocation,
    paymentOrder,
    paymentActionType,
    onPaymentUpdate,
    onClose,
  } = props;

  const { project } = useGetProject();

  if (!project) {
    throw new Error('Project not found');
  }

  const [getPaymentAuditLogsAPI] = useLazyGetPaymentAuditLogsQuery();

  const modalHeaderInfo = useMemo(() => {
    let title = '';
    let subtitle = '';

    switch (paymentActionType) {
      case 'add':
        title = 'Add Payment';
        subtitle = 'Clicking on Submit button moves record to add the payment';
        break;
      case 'process':
        title = 'Process Payment';
        subtitle =
          'Clicking on Submit button moves record to process the payment';
        break;
      case 'confirm':
        title = 'Confirm Payment';
        subtitle =
          'Clicking on Submit button moves record to confirm the payment';
        break;
      case 'addToken':
        title = 'Add Token Payment';
        subtitle = 'Add payment for the booking';
        break;
      default:
        title = 'Update Payment';
        subtitle = 'Clicking on Submit button to update payment details.';
    }
    return {
      title,
      subtitle,
    };
  }, [paymentActionType]);

  const handlePaymentUpdate = () => {
    getPaymentAuditLogsAPI({ paymentId: paymentOrder?.id });
    onPaymentUpdate && onPaymentUpdate();
    onClose();
  };

  const handleAddPayment = () => {
    onPaymentUpdate && onPaymentUpdate();
    onClose();
  };

  const isPaymentAddition =
    (paymentActionType === 'add' || paymentActionType === 'addToken') &&
    allocation;

  const modalContent = useMemo(() => {
    if (paymentOrder) {
      switch (paymentActionType) {
        case 'process':
          return (
            <PaymentProcessCell
              paymentOrder={paymentOrder}
              project={project}
              onPaymentUpdate={handlePaymentUpdate}
            />
          );
        case 'confirm':
          return (
            <PaymentConfirmationCell
              paymentOrder={paymentOrder}
              project={project}
              onPaymentUpdate={handlePaymentUpdate}
            />
          );

        default:
          return (
            <PaymentUpdateCell
              paymentOrder={paymentOrder}
              project={project}
              onPaymentUpdate={handlePaymentUpdate}
            />
          );
      }
    }

    if (isPaymentAddition) {
      return (
        <UnitCartPaymentAddCell
          unitCartId={allocation.id}
          unitId={allocation.unitId}
          userId={allocation.leadId}
          paymentOrders={allocation.extendedDetails.paymentOrders || []}
          project={project}
          onPaymentAdd={handleAddPayment}
          isBookingAmount={paymentActionType === 'addToken'}
        />
      );
    }
  }, [paymentActionType, allocation, paymentOrder, project]);

  return (
    <Modal onOutsideClick={onClose} additionalClasses={styles.modelWrap}>
      <div className={styles.headerWrap}>
        <FlexDeprecated flexColumn spacing={0.5}>
          <Text
            size='lg'
            weight='extrabold'
            color='color-cp-primary-32'
            text={modalHeaderInfo.title}
          />

          <FlexDeprecated spacing={0} alignItems='baseline'>
            <Text
              size='sm'
              color='color-primary-text-grey-45'
              text={modalHeaderInfo.subtitle}
            />
          </FlexDeprecated>
        </FlexDeprecated>
        <Icon name='close' propStyles={styles.closeIcon} onClick={onClose} />
      </div>

      <div className={styles.contentWrapper}>{modalContent}</div>
      <span className={styles.fixedBottom} />
    </Modal>
  );
};
