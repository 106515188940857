import { Button } from '@/atoms/Button';
import Image from '@/booking/components/common/ImageTS';
import { awsBaseUrl } from '@/constants/urls';

import styles from './styles.module.css';

interface ILoadingProps {
  errorMessage: string;
  pageType: 'loading' | 'error' | 'overlay';
  loadingSize?: 'small' | 'large';
}

/**
 // FIXME - Is this component correct?
 * Which scenarios this component to be used?
 * Is there any other alternative Loading component in use in the codebase?
 * What are the standard ways to handle the Loading at Page, Component level? 
 */
const LoadingPage = (props: ILoadingProps) => {
  const { errorMessage, pageType, loadingSize = 'large' } = props;

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div
      className={`${styles.container} ${styles[loadingSize]} ${styles[pageType]}`}>
      <p className={styles.errorMessage}>{errorMessage}</p>
      {pageType !== 'overlay' && (
        <div className={styles.loaderWrapper}>
          <Image
            src={
              pageType === 'loading'
                ? `${awsBaseUrl}/common/animated-house.gif`
                : `${awsBaseUrl}/common/lost-connection.gif
              `
            }
            imgClass={styles.gifWrapper}
          />
          {pageType === 'error' && (
            <Button propStyles={styles.refreshBtn} onClick={handleRefresh}>
              Refresh
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default LoadingPage;
