import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseUrl } from '@/constants/urls';

export const paymentAPISlice = createApi({
  reducerPath: 'paymentAPISlice',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      return headers;
    },
  }),
  endpoints: (build) => ({
    createPaymentOrder: build.mutation({
      query: (unit_cart_id) => ({
        url: `payment_order?unit_cart_id=${unit_cart_id}`,
        method: 'POST',
      }),
    }),

    successfulPayment: build.mutation({
      query: ({ paymentOrderId, ...body }) => ({
        url: `successful_payment/${paymentOrderId}`,
        method: 'PUT',
        body,
      }),
    }),

    failedPayment: build.mutation({
      query(paymentOrderId) {
        return {
          url: `failed_payment/${paymentOrderId}`,
          method: 'PUT',
        };
      },
      // invalidatesTags: (result, error, { id }) => [{ type: 'Posts', id }],
    }),
  }),
});

export const {
  useCreatePaymentOrderMutation,
  useSuccessfulPaymentMutation,
  useFailedPaymentMutation,
} = paymentAPISlice;
