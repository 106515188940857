import { FallbackProps } from 'react-error-boundary';

import LoadingPage from '@/apps/admin/components/common/LoadingPage';
import { Copy } from '@/atoms/Copy';
import { Text } from '@/atoms/Text/Text';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { publishErrorToDatadog } from '@/datadogRumSetup';

export const ErrorFallback = (props: FallbackProps) => {
  const { error } = props;

  const errorId = crypto.randomUUID();
  const wrappedError = new Error(`Error Id: ${errorId}`, error);

  publishErrorToDatadog(wrappedError);

  return (
    <FlexDeprecated flexColumn alignItems='center'>
      <FlexDeprecated alignItems='center' justifyContent='center'>
        <Text text='Error Id:' size='xs' fontFamily='poppins' />
        <Copy>
          <Text text={errorId} size='xs' fontFamily='poppins' />
        </Copy>
      </FlexDeprecated>
      <LoadingPage errorMessage={'Something went wrong!'} pageType={'error'} />
    </FlexDeprecated>
  );
};
