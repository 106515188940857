import type { Range } from '@/interfaces/range';

import styles from './styles.module.css';

interface IGapProps {
  numberOfSpaces?: Range<1, 101>;
  numberOfLines?: Range<0, 101>;
}

// TODO: make this take single props at a time

const Gap = (props: IGapProps) => {
  const { numberOfLines = 0, numberOfSpaces = 0 } = props;

  const spaces = ' '.repeat(numberOfSpaces);
  const lines = numberOfLines > 0 ? '\n'.repeat(numberOfLines) : '';
  const content = spaces + lines;

  return <pre className={styles.spaceWrapper}>{content}</pre>;
};

export default Gap;
