export const ConditionalRendering = (props: IConditionalRenderingProps) => {
  const { showIf, children } = props;

  if (!showIf) return null;
  return children;
};

interface IConditionalRenderingProps {
  showIf: unknown;
  children: React.ReactElement;
}
