import { createSlice } from '@reduxjs/toolkit';
import { LanguageTypes } from '@/constants/multiLanguageTexts';

interface IState {
  language: LanguageTypes;
}

const initialState: IState = {
  language: 'en' as LanguageTypes,
};

const sliceName = 'userLanguageSlice';

interface IUserLanguageSlice {
  [sliceName]: IState;
}

export const userLanguageSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setUserLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { setUserLanguage } = userLanguageSlice.actions;

export const getUserLanguage = (state: IUserLanguageSlice) =>
  state.userLanguageSlice.language;
