import {
  FirstDataRenderedEvent,
  PaginationChangedEvent,
} from 'ag-grid-community';
import { useCallback } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

const ZERO_INDEX_ADJUSTMENT = 1;

export const usePageNumberUrlParam = (isNestedTable?: boolean) => {
  const [pageNumber, setPageNumber] = useQueryParam('page', NumberParam);

  const onPaginationChanged = useCallback(
    (params: PaginationChangedEvent) => {
      if (isNestedTable) {
        return;
      }
      if (params.newPage) {
        const currentPage = params.api.paginationGetCurrentPage();
        setPageNumber(currentPage + ZERO_INDEX_ADJUSTMENT);
      }
    },
    [isNestedTable, setPageNumber]
  );

  const navigateToDefaultPage = useCallback(
    (params: FirstDataRenderedEvent) => {
      if (isNestedTable) {
        return;
      }
      if (pageNumber) {
        params.api.paginationGoToPage(pageNumber - ZERO_INDEX_ADJUSTMENT);
      }
    },
    [isNestedTable, pageNumber]
  );

  return {
    onPaginationChanged,
    navigateToDefaultPage,
  };
};
