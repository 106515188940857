import { createSlice } from '@reduxjs/toolkit';

interface AppState {
  isFetching: boolean;
}

export const appSlice = createSlice({
  name: 'appSlice',
  initialState: {
    isFetching: false,
  },
  reducers: {
    setAppSliceIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
  },
});

export const { setAppSliceIsFetching } = appSlice.actions;
export const getAppSliceIsFetching = (state: { appSlice: AppState }) =>
  state.appSlice.isFetching;
