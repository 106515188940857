import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  dateExpiryCountDownStateInvalidValue,
  IDateExpiryCountDown,
} from '@/booking/hooks/useDateExpiryCountdown';
import type { IApplicant, IPaymentOrder } from '@/booking/interfaces';
import type { TObjectId } from '@/interfaces';

export enum CRMDashboardStates {
  LANDING_PAGE,
  QR_CODE_PAGE,

  // Add all MainPageStates here
  LEAD_PROGRESS,
  LEAD_DETAILS,
  EOI_DETAILS,
  KYC_FORM,
  FEEDBACK_FORM,
  BOOKING_DETAILS,

  // Booking
  UNIT_RESERVE, //reserveUnitByUnitCartId
  UNIT_BLOCK, // blockUnitByUnitCartId
}

export interface IScannedDetails {
  leadId: TObjectId;
  summary: {
    id: TObjectId;
    scannedTimestamp: Date;
    zoneId: TObjectId;
    zoneName: string;
  }[];
}

interface IState {
  crmDashboardState: CRMDashboardStates;
  crmDashboardPrevState: CRMDashboardStates;
  scannedCodeDetails?: IScannedDetails;
  recentPaymentOrder?: IPaymentOrder;
  dateExpiryCountDown: IDateExpiryCountDown; // For selected UnitCart
  selectedApplicant?: IApplicant;
}

const initialState: IState = {
  crmDashboardState: CRMDashboardStates.LANDING_PAGE,
  crmDashboardPrevState: CRMDashboardStates.LANDING_PAGE,
  scannedCodeDetails: undefined,
  recentPaymentOrder: undefined,
  dateExpiryCountDown: dateExpiryCountDownStateInvalidValue,
  selectedApplicant: undefined,
};

const sliceName = 'crmDashboardSlice';

export interface ISiteVisit2Slice {
  [sliceName]: IState;
}

export const crmDashboardSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCRMDashboardState: (
      state,
      action: PayloadAction<CRMDashboardStates>
    ) => {
      state.crmDashboardPrevState = state.crmDashboardState;
      state.crmDashboardState = action.payload;
    },

    setScannedCodeDetails: (state, action: PayloadAction<IScannedDetails>) => {
      state.scannedCodeDetails = action.payload;
    },

    setRecentPaymentOrder: (
      state,
      action: PayloadAction<IPaymentOrder | undefined>
    ) => {
      state.recentPaymentOrder = action.payload;
    },

    setDateExpiryCountDown: (
      state,
      action: PayloadAction<IDateExpiryCountDown>
    ) => {
      state.dateExpiryCountDown = action.payload;
    },

    setSelectedApplicant: (state, action: PayloadAction<IApplicant>) => {
      state.selectedApplicant = action.payload;
    },
  },
});

export const {
  setCRMDashboardState,

  setScannedCodeDetails,

  setRecentPaymentOrder,

  setDateExpiryCountDown,

  setSelectedApplicant,
} = crmDashboardSlice.actions;

export const getCRMDashboardState = (state: ISiteVisit2Slice) =>
  state.crmDashboardSlice.crmDashboardState;

export const getCRMDashboardPrevState = (state: ISiteVisit2Slice) =>
  state.crmDashboardSlice.crmDashboardPrevState;

export const getScannedCodeDetails = (state: ISiteVisit2Slice) =>
  state.crmDashboardSlice.scannedCodeDetails;

export const getRecentPaymentOrder = (state: ISiteVisit2Slice) =>
  state.crmDashboardSlice.recentPaymentOrder;

export const getDateExpiryCountDown = (state: ISiteVisit2Slice) =>
  state.crmDashboardSlice.dateExpiryCountDown;

export const getSelectedApplicant = (state: ISiteVisit2Slice) =>
  state.crmDashboardSlice.selectedApplicant;
