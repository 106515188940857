import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
// TODO: Setup an eslint to show errors for wrong imports
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { ErrorFallback } from '@/components/atoms/ErrorBoundary/ErrorFallback';

import Routes from './routers/Routes';
import { store } from './store';
// import { parse, stringify } from 'query-string';
const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className='App'>
        <Provider store={store}>
          <BrowserRouter basename=''>
            <QueryParamProvider adapter={ReactRouter5Adapter}>
              <Routes />
            </QueryParamProvider>
          </BrowserRouter>
        </Provider>
      </div>
    </ErrorBoundary>
  );
};
export default App;
