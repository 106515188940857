import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search: queryString } = useLocation();
  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(queryString)),
    [queryString]
  );

  const query = useMemo(() => {
    return {
      ...(queryParams.redirectTo && { redirectTo: queryParams.redirectTo }),
      ...(queryParams.fileUrl && { fileUrl: queryParams.fileUrl }),
      ...(queryParams.ei && { ei: queryParams.ei }),
      ...(queryParams.pi && { projectId: queryParams.pi }),
      ...(queryParams.projectEventId && {
        projectEventId: queryParams.projectEventId,
      }),
      ...(queryParams.showProjectSelection && {
        showProjectSelection: queryParams.showProjectSelection,
      }),
      ...(queryParams.showAssignExecutive && {
        showAssignExecutive: queryParams.showAssignExecutive,
      }),
    };
  }, [queryParams]);

  return { query, queryParams, queryString } as const;
};

export type QueryParamsObjType = ReturnType<typeof useQueryParams>['query'];
export type QueryParamsList = Array<keyof QueryParamsObjType>;
