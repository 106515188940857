export const COLORS = {
  D50: '#FAFAFA',
  D100: '#F5F5F5',
  D200: '#E5E5E5',
  D300: '#D4D4D4',
  D400: '#A3A3A3',
  D500: '#737373',
  D600: '#525252',
  D700: '#404040',
  D800: '#262626',
  D900: '#171717',

  P50: '#F0F0F0',
  P100: '#ABBBC8',
  P200: '#8A959D',
  P300: '#5C778B',
  P400: '#405361',
  P500: '#374753',
  P600: '#252F37',
  P700: '#1B242A',

  G50: '#DBFCE6',
  G100: '#86EFAC',
  G200: '#4ADE80',
  G300: '#22C55E',
  G400: '#16A34A',
  G500: '#15803D',
  G600: '#166534',
  G700: '#14532D',

  O50: '#FDF2ED',
  O100: '#F5A28E',
  O200: '#F2926F',
  O300: '#EF7F49',
  O400: '#E77A3C',
  O500: '#D66535',
  O600: '#C8622F',
  O700: '#B4542A',

  R50: '#FEE5E5',
  R100: '#FBC7C7',
  R200: '#F79999',
  R300: '#F26A6A',
  R400: '#E03131',
  R500: '#C92828',
  R600: '#A31F1F',
  R700: '#7F1919',

  M1: '#E69873',
  M2: '#FFDB7D',
  M3: '#81C2FC',
  M4: '#BFACEE',

  White: '#FFFFFF',
  Black: '#000000',
} as const;

export const TEXT_TOKENS = {
  accent: COLORS.P400,
  accent_secondary: COLORS.P200,
  accent_tertiary: COLORS.P100,

  primary: COLORS.D500,
  secondary: COLORS.D600,

  placeholder: COLORS.D400,
  disabled: COLORS.D400,

  lighter: COLORS.D300,
  destructive: COLORS.R400,
  success: COLORS.G300,
  warning: COLORS.O400,

  white: COLORS.White,
} as const;
