import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  FixedBottomContainer,
  RowContainer,
} from '@/admin/components/common/Containers';
import { HistoryCard } from '@/admin/components/common/HistoryCard';
import { PaymentActionModal } from '@/admin/components/pages/ProjectAllocations/PaymentActionModal';
import { PaymentCommentAndReceipts } from '@/admin/components/pages/ProjectAllocations/PaymentDetailsModal/PaymentCommentAndReciepts';
import {
  getDisplayTextForPaymentModes,
  getStatusVariantByPaymentStatus,
} from '@/admin/components/pages/ProjectAllocations/utils';
import { usePathParams } from '@/admin/hooks/usePathParams';
import { Button } from '@/atoms/Button';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Copy } from '@/atoms/Copy';
import { Icon, IconNames } from '@/atoms/Icon';
import Separator from '@/atoms/Separator';
import { Text } from '@/atoms/Text/Text';
import Modal from '@/booking/components/common/modal/ModalTS';
import SpinLoader from '@/booking/components/common/SpinLoader';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import type { INote, IPaymentOrder } from '@/booking/interfaces';
import {
  IPaymentChange,
  useGetPaymentAuditLogsQuery,
} from '@/booking/services/paymentsAPISlice';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { DateUtils } from '@/utils/DateUtils';
import { capitalizeFirstLetter, toIndianRupee } from '@/utils/utils';

import styles from './styles.module.css';

interface IPaymentDetailsModalProps {
  title: string;
  paymentOrder: IPaymentOrder;
  onPaymentUpdate?: () => void;
  onClose: () => void;
  isPaymentReasonShown?: boolean;
}

export const PaymentDetailsModal = (props: IPaymentDetailsModalProps) => {
  const {
    paymentOrder,
    title,
    onClose,
    onPaymentUpdate,
    isPaymentReasonShown = false,
  } = props;

  const [isPaymentActionModal, setIsPaymentActionModal] = useState(false);

  const history = useHistory();
  const { projectId } = usePathParams();

  const { data: paymentAuditLogs = [], isLoading } =
    useGetPaymentAuditLogsQuery({
      paymentId: paymentOrder.id,
    });

  const auditLogsFilteredByUpdatedPaymentStatus = useMemo(() => {
    const filteredData = paymentAuditLogs
      .filter((log) => {
        const hasStatusField = log.changes.some(
          (change) => change.field === 'status'
        );
        return hasStatusField;
      })
      .reverse();

    if (paymentAuditLogs.length) {
      return [...filteredData, paymentAuditLogs[0]];
    }

    return filteredData;
  }, [paymentAuditLogs]);

  const getIconName = (paymentStatus: PAYMENT_ORDER_STATUS) => {
    let iconName: IconNames = 'texture';
    switch (paymentStatus) {
      case PAYMENT_ORDER_STATUS.INITIATED:
        iconName = 'rotate_right';
        break;
      case PAYMENT_ORDER_STATUS.PROCESSING:
        iconName = 'check';
        break;
      default:
        iconName = 'texture';
        break;
    }
    return iconName;
  };

  const getNewValueForUpdatedPaymentFields = (
    changes: IPaymentChange[],
    updatedField: string
  ) => {
    const updatedValue = changes.find(
      (paymentChange) => paymentChange.field === updatedField
    )?.newValue;

    return updatedValue;
  };

  const handleRedirectToTeleCaller = () => {
    history.push(`/telecaller-addLead/${projectId}`);
  };

  if (isPaymentActionModal) {
    return (
      <PaymentActionModal
        paymentOrder={paymentOrder}
        paymentActionType='update'
        onPaymentUpdate={onPaymentUpdate}
        onClose={() => setIsPaymentActionModal(false)}
      />
    );
  }

  return (
    <Modal onOutsideClick={onClose} additionalClasses={styles.modalContainer}>
      <FlexDeprecated
        flexRow
        justifyContent='space-between'
        fullFlexWidth={false}
        alignItems='start'>
        <FlexDeprecated flexColumn spacing={0.5} fullFlexWidth={false}>
          <Text text={title} size='xl' weight='bold' color='color-primary-32' />
          <Text
            text='Explore all payments for the booking along with attached  EOI'
            size='sm'
            color='color-primary-text-grey-45'
          />
        </FlexDeprecated>
        <Icon name='close' propStyles={styles.closeIcon} onClick={onClose} />
      </FlexDeprecated>
      <Separator lineStyle='solid' separatorColor='color-primary-90' />
      <HistoryCard
        endIcon={
          isPaymentReasonShown
            ? undefined
            : {
                name: 'edit',
                action: () => setIsPaymentActionModal(true),
              }
        }
        historyDetails={{
          iconName: 'payments_outlined',
          prefixInfo:
            paymentOrder.createdAt &&
            DateUtils.format(
              paymentOrder.createdAt,
              DateUtils.FormatTo.MONTH_DATE
            ),
          suffixInfo: getDisplayTextForPaymentModes(paymentOrder.mode),
          highlightedInfo: `${toIndianRupee(paymentOrder.amount)}`,
          status: paymentOrder.status.toLowerCase(),
          statusSuffix: (
            <FlexDeprecated
              spacing={1}
              alignItems='center'
              justifyContent='center'
              fullFlexWidth={false}>
              <Text
                text='TxID :'
                size='xs'
                weight='light'
                color='color-primary-64'
              />
              <Copy>
                <Text
                  text={paymentOrder.transactionId}
                  size='xs'
                  weight='light'
                  color='color-primary-64'
                />
              </Copy>
            </FlexDeprecated>
          ),
          statusVariant: getStatusVariantByPaymentStatus(paymentOrder.status),
          statusIndicatorFontSize: 'xs',
        }}>
        <PaymentCommentAndReceipts
          paymentNotes={paymentOrder.notes || []}
          receiptUrls={paymentOrder.receiptsUrl || []}
          paymentFailureReason={paymentOrder.reasonOfFailure}
        />
      </HistoryCard>

      <Separator
        textPositionFromLeft={10}
        separatorText='Confirmation History'
        lineStyle='dashed'
        separatorColor='color-primary-64'
        marginFromTopAndBottom={4}
      />

      <ConditionalRendering showIf={isLoading}>
        <SpinLoader />
      </ConditionalRendering>

      <ConditionalRendering
        showIf={!auditLogsFilteredByUpdatedPaymentStatus.length}>
        <Text text='No confirmation history' color='color-cp-primary-83' />
      </ConditionalRendering>

      <FlexDeprecated flexColumn spacing={2} alignItems='normal'>
        {auditLogsFilteredByUpdatedPaymentStatus.map((auditLogs) => (
          <div key={auditLogs.id}>
            <HistoryCard
              endIcon={{
                name: 'call',
                action: handleRedirectToTeleCaller,
              }}
              historyDetails={{
                iconName: getIconName(
                  (getNewValueForUpdatedPaymentFields(
                    auditLogs.changes,
                    'status'
                  ) as PAYMENT_ORDER_STATUS) || PAYMENT_ORDER_STATUS.INITIATED
                ),
                prefixInfo:
                  auditLogs.timestamp &&
                  DateUtils.format(
                    auditLogs.timestamp,
                    DateUtils.FormatTo.MONTH_DATE
                  ),
                suffixInfo: auditLogs.user
                  ? `by ${auditLogs.user.name} (${capitalizeFirstLetter(
                      auditLogs.user.role.toLowerCase()
                    )})`
                  : '',
                highlightedInfo: capitalizeFirstLetter(
                  (
                    getNewValueForUpdatedPaymentFields(
                      auditLogs.changes,
                      'status'
                    ) as string
                  )?.toLowerCase() ||
                    PAYMENT_ORDER_STATUS.INITIATED.toLowerCase()
                ),
                status: '',
                statusSuffix:
                  auditLogs.timestamp &&
                  `Last updated on ${DateUtils.format(
                    auditLogs.timestamp,
                    DateUtils.FormatTo.DAY_DATE_LONG_DATE
                  )}`,
                statusVariant: 'color--cold-indicator',
                statusIndicatorFontSize: 'xs',
              }}>
              <PaymentCommentAndReceipts
                paymentNotes={
                  (getNewValueForUpdatedPaymentFields(
                    auditLogs.changes,
                    'notes'
                  ) as INote[]) || []
                }
                receiptUrls={
                  (getNewValueForUpdatedPaymentFields(
                    auditLogs.changes,
                    'receiptUrl'
                  ) as string[]) || []
                }
              />
            </HistoryCard>
          </div>
        ))}
      </FlexDeprecated>

      <FixedBottomContainer>
        {/* //TODO: remove RowContainer as it can be handled by FlexContainer
        itself so try to update FlexContainer */}
        <RowContainer>
          <Button accent='primary' onClick={onClose}>
            Return to Payment History
          </Button>
        </RowContainer>
      </FixedBottomContainer>
    </Modal>
  );
};
