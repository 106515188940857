import { useDispatch } from 'react-redux';

import { IBreadCrumbBtn } from '@/admin/components/common/AddApplicant/AddApplicantFooter';
import {
  AddAllocationStates,
  setAddAllocationState,
} from '@/admin/components/pages/AddAllocation/addAllocationSlice';
import {
  PROJECT_EOI_STATE,
  setProjectEoiState,
} from '@/admin/components/pages/ProjectEois/projectEoiSlice';
import { Button } from '@/atoms/Button';
import { Icon } from '@/atoms/Icon';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

export const AllocationBreadCrumbs = (props: IAllocationBreadCrumbsProps) => {
  const { activeIndex } = props;

  const dispatch = useDispatch();

  const handleBreadCrumbClick = (
    allocationState: AddAllocationStates,
    projectEoiState: PROJECT_EOI_STATE
  ) => {
    dispatch(setAddAllocationState(allocationState));
    dispatch(setProjectEoiState(projectEoiState));
  };

  const breadCrumbsBtns: IBreadCrumbBtn[] = [
    {
      iconName: 'confirmation_number',
      primaryAccentIndex: activeIndex,
      onClick: () =>
        handleBreadCrumbClick(
          AddAllocationStates.BOOKING_DETAILS,
          PROJECT_EOI_STATE.EOI_DETAILS
        ),
    },
    {
      iconName: 'person_outline',
      primaryAccentIndex: activeIndex,
      onClick: () =>
        handleBreadCrumbClick(
          AddAllocationStates.ADD_UPDATE_APPLICANT,
          PROJECT_EOI_STATE.ADD_OR_UPDATE_APPLICANT
        ),
    },
    {
      iconName: 'payments',
      primaryAccentIndex: activeIndex,
      onClick: () =>
        handleBreadCrumbClick(
          AddAllocationStates.PAYMENT,
          PROJECT_EOI_STATE.PAYMENT
        ),
    },
    {
      iconName: 'call',
      primaryAccentIndex: activeIndex,
      onClick: () =>
        handleBreadCrumbClick(
          AddAllocationStates.FEEDBACK,
          PROJECT_EOI_STATE.FOLLOWUPS
        ),
    },
  ];

  return (
    <FlexDeprecated spacing={2} alignItems='center' fullFlexWidth={false}>
      {breadCrumbsBtns.map((button, index: number) => (
        <FlexDeprecated
          fullFlexWidth={false}
          flexBasis={20}
          key={button.iconName}>
          <Button
            onClick={button.onClick}
            accent={
              index === button.primaryAccentIndex ? 'primary' : 'bordered'
            }
            disabledWithAccent={index === button.primaryAccentIndex}>
            <Icon
              name={button.iconName}
              disabled={button.disabled}
              key={index}
            />
          </Button>
        </FlexDeprecated>
      ))}
    </FlexDeprecated>
  );
};

interface IAllocationBreadCrumbsProps {
  activeIndex: number;
}
