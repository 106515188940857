import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/atoms/Button';
import { Icon } from '@/atoms/Icon';
import DropDownInput, {
  DropDownOptionType,
} from '@/booking/components/common/inputs/DropDownInput';
import FileUploadNew from '@/booking/components/common/inputs/FileUploadNew';
import TextInput from '@/booking/components/common/inputs/TextInput';
import {
  DateExpiryCountDownState,
  IDateExpiryCountDown,
} from '@/booking/hooks/useDateExpiryCountdown';
import useToast from '@/hooks/useToast';
import type { IProject } from '@/interfaces';
import { randomId, toTwoDigits } from '@/utils/utils';

import styles from './styles.module.css';

export const paymentModeOptions: DropDownOptionType<string>[] = [
  { value: 'CASH_CHALLAN', displayText: 'Cash Challan' },
  { value: 'CHEQUE', displayText: 'Cheque' },
  { value: 'NETBANKING', displayText: 'Netbanking' },
  { value: 'UPI', displayText: 'UPI' },
  { value: 'CREDIT_CARD', displayText: 'Credit Card' },
  { value: 'DEBIT_CARD', displayText: 'Debit Card' },
];

export interface IPaymentFormData {
  mode: string;
  transactionId: string;
  amount: number;
  slips: string[];
}

// Validation
const schema = yup.object().shape({
  paymentMode: yup.string().required(),
  amount: yup.number().required(),
  transactionId: yup.string().required(),
});

interface IPaymentFormProps {
  project: IProject;
  showFooterBtnLoading?: boolean;
  dateExpiryCountDown?: IDateExpiryCountDown; // TODO: Takeout PaymentForm footer button out of this component
  onBackButtonClick: () => void;
  onSubmit: (paymentFormData: IPaymentFormData) => void;
}

const PaymentForm = (props: IPaymentFormProps) => {
  const {
    project,
    showFooterBtnLoading = false,
    onBackButtonClick,
    onSubmit,
    dateExpiryCountDown,
  } = props;

  const [fileUploadingStatus, setFileUploadingStatus] = useState<{
    [fileId: string]: boolean;
  }>({});
  const [paymentSlips, setPaymentSlips] = useState<string[]>([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [addToast] = useToast();

  const {
    register,
    handleSubmit,
    unregister,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const dateExpiryCountDownStr =
    dateExpiryCountDown &&
    dateExpiryCountDown.state !== DateExpiryCountDownState.INVALID
      ? `| ${toTwoDigits(dateExpiryCountDown?.minutes)}:${toTwoDigits(
          dateExpiryCountDown.seconds
        )}`
      : '';

  useEffect(() => {
    const isUploading = Object.values(fileUploadingStatus).some(
      (status) => status
    );
    setIsUploaded(isUploading);
  }, [fileUploadingStatus]);

  const handlePaymentRequest = async (data: yup.InferType<typeof schema>) => {
    if (!paymentSlips.length) {
      addToast({
        type: 'ERROR',
        primaryMessage: 'Upload payment receipt',
        timeout: 1000,
      });
      return;
    }

    onSubmit({
      mode: data.paymentMode,
      transactionId: data.transactionId,
      amount: data.amount,
      slips: paymentSlips,
    });
  };

  const toggleFileUploadStatus = (
    fileId: string,
    isUploadInProgress: boolean
  ) => {
    setFileUploadingStatus((prevState) => {
      return {
        ...prevState,
        [fileId]: isUploadInProgress,
      };
    });
  };

  const handlAddPaymentSlips = (imageUrls: string[]) => {
    const urls = [...paymentSlips, ...imageUrls];
    setPaymentSlips(urls);
  };

  const handleRemovePaymentSlips = (imageUrl: string) => {
    const removedImage = paymentSlips.filter((image) => image !== imageUrl);
    setPaymentSlips(removedImage);
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(handlePaymentRequest)}>
        <DropDownInput
          lable='Payment Mode *'
          options={paymentModeOptions}
          name='paymentMode'
          register={register}
          unregister={unregister}
          setValue={setValue}
          errorMessage={errors.paymentMode && 'TransactionId is required'}
        />
        <TextInput
          label='Payment Id *'
          placeHolder='Add Transaction Id or Cheque No.'
          name='transactionId'
          register={register}
          unregister={unregister}
          setValue={setValue}
          errorMessage={errors.transactionId && 'TransactionId is required'}
        />
        <TextInput
          label='Payment Amount *'
          placeHolder='Add Payment Amount'
          name='amount'
          type='number'
          register={register}
          unregister={unregister}
          setValue={setValue}
          errorMessage={errors.amount && 'Payment Amount is required'}
        />
        <FileUploadNew
          placeholder='Add here'
          label='Upload your payment slip here *'
          s3DirPath={`projects/${project.id}/paymentSlips/${randomId()}`}
          name='paymentSlips'
          register={register}
          unregister={unregister}
          setValue={setValue}
          onChangeFileUploadStatus={toggleFileUploadStatus}
          errorMessage={errors.paymentSlips && 'Please upload payment slips'}
          onMultipleFileUpload={handlAddPaymentSlips}
          appType='RELATA'
          multiple
          showDisplayInputs
          displayInputs={paymentSlips}
          onRemove={handleRemovePaymentSlips}
          isFilePreviewEnabled
        />
        <div className={styles.btnWrapper}>
          <Button loading={showFooterBtnLoading} onClick={onBackButtonClick}>
            <Icon name='keyboard_arrow_left' />
          </Button>
          <Button
            type='submit'
            loading={showFooterBtnLoading}
            disabled={isUploaded}
            accent='primary'>
            {`Confirm Payment ${dateExpiryCountDownStr}`}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PaymentForm;
