import { IAddressDetails } from '@/booking/components/common/SendOtp';
import type {
  ICpLeadReq,
  ICpUser,
  ICpUserDashboardStats,
  ICpUserReq,
  ICpUserUpdateReq,
} from '@/cp/interfaces';
import type { ICpLead, IResponseBase, TObjectId } from '@/interfaces';
import { cpAPISlice } from './index';

export const cpUserAPISlice = cpAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getCpUserByMobile: build.mutation({
      query: (addressDetails: IAddressDetails) => ({
        url: `api/users/cp/${addressDetails.addressType.toLocaleLowerCase()}/${
          addressDetails.address
        }`,
        method: 'GET',
      }),
    }),
    getCpUsersByAdminUserId: build.query<ICpUser[], any>({
      query: (adminUserId: TObjectId) => `api/users/cp/${adminUserId}`,
      transformResponse: (response: ITransformCpUsersResponse) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),

    addCpUser: build.mutation({
      query: (cpUser: ICpUserReq) => ({
        url: 'api/users/cp',
        method: 'POST',
        body: cpUser,
      }),
    }),
    updateCpUser: build.mutation({
      query: ({ id, ...cpUser }: ICpUserUpdateReq) => ({
        url: `api/users/cp/${id}`,
        method: 'PUT',
        body: cpUser,
      }),
    }),

    addCpLead: build.mutation({
      query: ({
        cpUserId,
        cpLead,
      }: {
        cpUserId: TObjectId;
        cpLead: ICpLeadReq;
      }) => ({
        url: `api/users/cp/cp-lead/${cpUserId}`,
        method: 'POST',
        body: cpLead,
      }),
    }),
    qrcodeCountAnalytics: build.mutation({
      query: ({
        cpUserId,
        cpLeadId,
        projectId,
        eventName,
      }: {
        cpUserId: ICpUser;
        cpLeadId: TObjectId;
        projectId: TObjectId;
        eventName: TObjectId;
      }) => ({
        url: `api/analytics/qrCode/`,
        method: 'POST',
        body: {
          cpUserId,
          cpLeadId,
          projectId,
          eventName,
        },
      }),
    }),
    updateCpLead: build.mutation({
      query: ({
        cpUserId,
        cpLead,
        cpLeadId,
      }: {
        cpUserId: TObjectId;
        cpLeadId: TObjectId;
        cpLead: ICpLead;
      }) => ({
        url: `api/users/cp/cp-lead/${cpUserId}/${cpLeadId}`,
        method: 'PUT',
        body: cpLead,
      }),
    }),
    getCpLeadById: build.query({
      query: ({ cpLeadId }: { cpLeadId: TObjectId }) =>
        `api/users/cp/cp-lead/${cpLeadId}`,
      transformResponse: (response: ITransformCpLeadResponse) => {
        return response.data;
      },
    }),

    getCpUserApprovalStatus: build.query({
      query: ({ cpUserId }: { cpUserId: string }) =>
        `api/users/cp/approval-status/${cpUserId}`,
      keepUnusedDataFor: 1,
    }),

    collateralViewCpAppAnalytics: build.mutation({
      query: ({
        projectId,
        cpUserId,
        collateralId,
      }: {
        projectId: TObjectId;
        cpUserId: TObjectId;
        collateralId: TObjectId;
      }) => ({
        url: `api/analytics/raCollaterals/preview/`,
        method: 'POST',
        body: {
          projectId,
          cpUserId,
          collateralId,
        },
      }),
    }),
    collateralBitlyAnalytics: build.mutation({
      query: ({
        projectId,
        collateralSharedLink,
        deviceInfo,
      }: {
        projectId: TObjectId;
        collateralSharedLink: TObjectId;
        deviceInfo: TObjectId;
      }) => ({
        url: `api/analytics/raCollaterals/preview/sharedCollateral/`,
        method: 'POST',
        body: {
          projectId,
          collateralSharedLink,
          deviceInfo,
        },
      }),
    }),

    getCpUserDashboardStatsByCpUserId: build.query<ICpUserDashboardStats, any>({
      query: (cpUserId: TObjectId) => `api/users/cp/reports/${cpUserId}`,
      transformResponse: (response: ITransformCpUserDashboardStatsResponse) => {
        return response.data;
      },
    }),
  }),

  overrideExisting: false,
});

interface ITransformCpUserDashboardStatsResponse extends IResponseBase {
  data: ICpUserDashboardStats;
}

interface ITransformCpLeadResponse extends IResponseBase {
  data: ICpLead;
}

export interface ITransformCpUsersResponse extends IResponseBase {
  data: ICpUser[];
}

export const {
  useGetCpUserByMobileMutation,
  useGetCpUsersByAdminUserIdQuery,

  useAddCpUserMutation,
  useUpdateCpUserMutation,

  useAddCpLeadMutation,
  useQrcodeCountAnalyticsMutation,
  useUpdateCpLeadMutation,
  useGetCpLeadByIdQuery,
  useLazyGetCpLeadByIdQuery,

  useGetCpUserApprovalStatusQuery,

  useCollateralViewCpAppAnalyticsMutation,
  useCollateralBitlyAnalyticsMutation,

  useGetCpUserDashboardStatsByCpUserIdQuery,
} = cpUserAPISlice;
