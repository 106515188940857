// import { IAssignedCpPreviewReq } from '../components/pages/forms/SiteVisit/DetailsForm/AssingedCpInfo';
import { ILeadDetails } from '@/admin/components/pages/CMDashboard/pageBodies/MainPageBody/LeadDetails';
// import { TObjectId } from '@/interfaces';
// import { ISiteVisit } from '@/cp/interfaces';
// import { ILeadDetails } from '../components/pages/ClosingManagerDashboard/LeadDetails';
import {
  IAssignedCpPreviewPostAPIReq,
  ISiteVisitPostAPIReq,
} from '@/admin/components/pages/forms/SiteVisit2/interface';
import { IClosingManager } from '@/admin/components/pages/forms/SiteVisit2/siteVisit2Slice';
import { ICompany, ILqr } from '@/admin/interfaces';
import { adminAPISlice } from '@/admin/services/index';
// import { ISiteVisitDetails } from '@/admin/components/pages/forms/SiteVisit';
import type { ICpUser, IProjectLead, ISiteVisit } from '@/cp/interfaces';
import type { ICpLead, IResponseBase, IUser, TObjectId } from '@/interfaces';

export const siteVisitAPI = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getSiteVisitsByCpLeadId: build.query<ISiteVisit[], TObjectId>({
      query: (cpLeadId: TObjectId) => `api/siteVisits/${cpLeadId}`,
      transformResponse: (response: ITransformSiteVisitsResponse) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),
    addDirectSiteVisit: build.mutation({
      query: (siteVisitDetails: ISiteVisitPostAPIReq) => ({
        url: 'api/siteVisits/direct',
        method: 'POST',
        body: siteVisitDetails,
      }),
    }),
    addChannelPartnerSiteVisit: build.mutation({
      query: (siteVisitDetails: ISiteVisitPostAPIReq) => ({
        url: 'api/siteVisits',
        method: 'POST',
        body: siteVisitDetails,
      }),
    }),
    qrcodeScanAnalytics: build.mutation({
      query: ({
        cpUserId,
        cpLeadId,
        projectId,
        eventName,
      }: {
        cpUserId: TObjectId;
        cpLeadId: TObjectId;
        projectId: TObjectId;
        eventName: TObjectId;
      }) => ({
        url: `api/analytics/qrCode/`,
        method: 'POST',
        body: {
          cpLeadId,
          cpUserId,
          projectId,
          eventName,
        },
      }),
    }),
    getAssignedCpPreview: build.mutation({
      query: (leadDetails: IAssignedCpPreviewPostAPIReq) => ({
        url: 'api/siteVisits/cpAssignmentPreview',
        method: 'POST',
        body: leadDetails,
      }),
    }),
    createLeadQR: build.mutation({
      query: (leadIdAndProjectIDReq: ILeadIdAndProjectIDReq) => ({
        url: 'api/lqrCode',
        method: 'POST',
        body: leadIdAndProjectIDReq,
      }),
    }),
    getLqrByLeadIdAndProjectId: build.query<ILqr, ILeadIdAndProjectIDReq>({
      query: (leadIdAndProjectIDReq) => ({
        url: 'api/lqrCode',
        method: 'GET',
        params: leadIdAndProjectIDReq,
      }),
      transformResponse: (response: ITransformLqrCodeResponse) => response.data,
    }),
    getLeadDetailsDuringSiteVisit: build.query<
      ILeadDetails,
      ILeadIdAndProjectIDReq
    >({
      query: (leadIdAndProjectIDReq) => ({
        url: `api/siteVisits/leadDetailsDuringSiteVisit/${leadIdAndProjectIDReq.projectId}/${leadIdAndProjectIDReq.leadId}`,
        method: 'GET',
      }),

      transformResponse: (response: ITransformLeadDetailResponse) => {
        return response.data;
      },
    }),
    getDetailsUsingLQR: build.query<
      IDetails,
      { lqr: string; projectId: string }
    >({
      query: ({ lqr, projectId }) => ({
        url: 'api/siteVisits/byLqrCode',
        method: 'GET',
        params: {
          lqrCode: lqr,
          projectId,
        },
      }),
      transformResponse: (response: ITransformResponse<IDetails>) => {
        return response.data;
      },
    }),
    getDetailsUsingMobile: build.query<
      IDetails,
      { mobile: string; projectId: string }
    >({
      query: ({ mobile, projectId }) => ({
        url: 'api/siteVisits/byMobile',
        method: 'GET',
        params: {
          mobile,
          projectId,
        },
      }),
      transformResponse: (response: ITransformResponse<IDetails>) => {
        return response.data;
      },
    }),
    getDetailsUsingMobileSync: build.mutation<
      IDetails,
      { mobile: string; projectId: string }
    >({
      query: ({ mobile, projectId }) => ({
        url: 'api/siteVisits/byMobile',
        method: 'GET',
        params: {
          mobile,
          projectId,
        },
      }),
      transformResponse: (response: ITransformResponse<IDetails>) => {
        return response.data;
      },
    }),

    addPartner: build.mutation<
      { data: { cpLead: ICpLead; cpUser: ICpUser } },
      IAddPartnerReq
    >({
      query: (body) => {
        return {
          url: 'api/siteVisits/addPartner',
          method: 'POST',
          body: body,
        };
      },
    }),
    addLeadCmMapping: build.mutation<
      { data: ICmLeadMappingResponse },
      { lead: TObjectId; project: TObjectId; closingManager: TObjectId }
    >({
      query: (body) => {
        return {
          url: 'api/users/leadCmMapping',
          method: 'POST',
          body: body,
        };
      },
    }),

    getAllSiteVisitsByLeadId: build.query<
      ISiteVisit[],
      { projectId: TObjectId; leadId: TObjectId }
    >({
      query: ({ projectId, leadId }) => `api/siteVisits/${projectId}/${leadId}`,
      transformResponse: (response: ITransformResponse<ISiteVisit[]>) => {
        return response.data;
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetSiteVisitsByCpLeadIdQuery,
  useAddDirectSiteVisitMutation,
  useAddChannelPartnerSiteVisitMutation,
  useQrcodeScanAnalyticsMutation,
  useGetAssignedCpPreviewMutation,

  useLazyGetLqrByLeadIdAndProjectIdQuery,
  useCreateLeadQRMutation,
  useGetLeadDetailsDuringSiteVisitQuery,
  useLazyGetDetailsUsingMobileQuery,
  useGetDetailsUsingMobileQuery,
  useGetDetailsUsingMobileSyncMutation,
  useLazyGetDetailsUsingLQRQuery,
  useGetDetailsUsingLQRQuery,
  useLazyGetLeadDetailsDuringSiteVisitQuery,
  useAddPartnerMutation,

  useAddLeadCmMappingMutation,

  useGetAllSiteVisitsByLeadIdQuery,
} = siteVisitAPI;

interface ITransformSiteVisitsResponse extends IResponseBase {
  data: ISiteVisit[];
}

interface ITransformLeadDetailResponse extends IResponseBase {
  data: ILeadDetails;
}

interface ITransformLqrCodeResponse extends IResponseBase {
  data: ILqr;
}
export interface ITransformResponse<T> extends IResponseBase {
  data: T;
}

export interface IDetails {
  closingManager: IClosingManager;
  recentSiteVisit: {
    cpLeads: ICpLead[];
    preferenceAreasTemp: string[];
  };
  user: IProjectLead;
  lqrCodePin: string;
  engagedPartners: IEngagedPartner[];
  cpAssignmentResult: ICpAssignmentResult;
}

export interface IAddPartnerReq {
  leadId: TObjectId;
  projectId: TObjectId;
  mobile: string;
  name: string;
  email: string;
}
interface ICmLeadMappingResponse {
  lead: TObjectId;
  project: TObjectId;
  assignedOn: Date;
  closingManager: TObjectId;
  id: TObjectId;
}

interface ILeadIdAndProjectIDReq {
  leadId: TObjectId;
  projectId: TObjectId;
}

export interface IEngagedPartner {
  user: IUser;
  company?: ICompany;
}

export interface ICpAssignmentResult {
  winnerCanBeModified: boolean;
  conflicted: boolean;
  assignmentReason: string;
  winnerCp: {
    id: TObjectId;
    sm: {
      userId: TObjectId;
      name: string;
    };
  };
  loserCps: {
    id: TObjectId;
    sm: {
      userId: TObjectId;
      name: string;
    };
  }[];
}
