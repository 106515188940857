import {
  IAllocationDetails,
  IGetAllocationCountReq,
  IGetAllocationReq,
} from '@/admin/components/pages/ProjectAllocations/AllocationsList';
import { TAB_NAME } from '@/admin/components/pages/ProjectBookingPayments';
import { IBookingPaymentRow } from '@/admin/components/pages/ProjectBookingPayments/PaymentList';
import { getStartAndEndDateForLapsedAndPendingTab } from '@/admin/components/pages/ProjectEoiPayments/PaymentList';
import { EOI_TAB_NAME } from '@/admin/components/pages/ProjectEois';
import { IDeleteUserFromProjectRequest } from '@/admin/components/pages/ProjectExecutives/RemoveProjectExecutiveCell';
import { IDemoRoom } from '@/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/AddDemoRoomModal';
import {
  IProjectEvent,
  IProjectEventReq,
} from '@/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList/modals/ProjectEventsModal';
import type { IAddEoi, IEOI } from '@/admin/interfaces';
import { adminAPISlice } from '@/admin/services/index';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import type {
  IProject,
  IProjectExecutive,
  IResponseBase,
  TObjectId,
} from '@/interfaces';
import type {
  ITransformCountResponse,
  ITransformProjectResponse,
  ITransformProjectsResponse,
} from '@/interfaces/services';

const PROJECT_SELECTION_API_CACHE_TIME = 300;

export const projectsAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.mutation({
      query: ({
        pageNumber,
        pageCount,
        searchName = '',
      }: {
        pageNumber: number;
        pageCount: number;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects?searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}`,
        method: 'GET',
      }),
    }),
    getProjectsCount: build.query({
      query: () => 'api/projects/count',
      transformResponse: (response: ITransformCountResponse) => {
        return response.data;
      },
    }),
    getProjectsByProjectId: build.query({
      query: (projectId: string) => `api/projects/${projectId}`,
      transformResponse: (response: ITransformProjectResponse) => {
        return response.data;
      },
      providesTags: (_, __, projectId) => [{ type: 'Project', id: projectId }],
    }),
    getProjectsByUserId: build.query<IProject[], string | undefined>({
      query: (userId: string) => `api/projects/users/${userId}`,
      transformResponse: (response: ITransformProjectsResponse) => {
        return response.data;
      },
    }),
    getProjectSelectionByUserId: build.query<IProject[], string | undefined>({
      query: (userId: string) => `api/projects/users/${userId}/selection`,
      transformResponse: (response: ITransformProjectsResponse) => {
        return response.data;
      },
      keepUnusedDataFor: PROJECT_SELECTION_API_CACHE_TIME,
    }),
    addProject: build.mutation({
      query: (project: IProject) => ({
        url: 'api/projects/',
        method: 'POST',
        body: project,
      }),

      invalidatesTags: [{ type: 'Projects', id: 'LIST' }],

      transformResponse: (response: ITransformProjectResponse) => {
        return response.data;
      },
    }),
    updateProject: build.mutation({
      query: ({ projectId, ...fieldsToUpdate }) => ({
        url: `api/projects/${projectId}`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
      // invalidatesTags: [{ type: 'Projects', id: 'LIST' }]
    }),
    updateProjectDetails: build.mutation({
      query: ({ projectId, ...fieldsToUpdate }) => ({
        url: `api/projects/${projectId}/details`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
      invalidatesTags: ['Project'],
    }),
    clearUnitInventory: build.mutation({
      query: ({ projectId }: { projectId: TObjectId }) => ({
        url: `api/units/deleteUnits?projectId=${projectId}`,
        method: 'DELETE',
      }),
    }),

    addDemoRoom: build.mutation({
      query: ({ projectId, demoRoom }) => ({
        url: `api/projects/${projectId}/demo-rooms`,
        method: 'POST',
        body: demoRoom,
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    getDemoRoom: build.query<IDemoRoom, IDemoRoomQuery>({
      query: ({ projectId, executiveId }) =>
        `api/projects/${projectId}/executives/${executiveId}/demo-rooms`,
      transformResponse: (response: IDemoRoomQueryResponse) => {
        return response.data;
      },
    }),
    updateDemoRoom: build.mutation({
      query: ({ projectId, demoRoomId, demoRoom }) => ({
        url: `api/projects/${projectId}/demo-rooms/${demoRoomId}`,
        method: 'PUT',
        body: demoRoom,
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    deleteDemoRoom: build.mutation({
      query: ({ projectId, demoRoomId }) => ({
        url: `api/projects/${projectId}/demo-rooms/${demoRoomId}`,
        method: 'DELETE',
      }),

      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    addProjectExecutive: build.mutation({
      query: ({
        projectId,
        executiveId,
        vrUrl,
        teamLeadId,
      }: IProjectExecutive) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom`,
        method: 'POST',
        body: { executiveId, vrUrl, teamLeadId },
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    updateProjectExecutive: build.mutation({
      query: ({
        projectId,
        executiveId,
        vrUrl,
        teamLeadId,
      }: IProjectExecutive) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom`,
        method: 'PUT',
        body: { executiveId, vrUrl, teamLeadId },
      }),
    }),

    deleteExecutiveFromProject: build.mutation<
      { data: IProject },
      IDeleteUserFromProjectRequest
    >({
      query: ({ projectId, userId }) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: 'Projects-Users', id: projectId },
      ],
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    deleteGreFromProject: build.mutation<
      { data: IProject },
      IDeleteUserFromProjectRequest
    >({
      query: ({ projectId, userId }) => ({
        url: `api/projects/${projectId}/gre/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: 'Projects-Users', id: projectId },
      ],
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    deletePreSaleFromProject: build.mutation<
      { data: IProject },
      IDeleteUserFromProjectRequest
    >({
      query: ({ projectId, userId }) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: 'Projects-Users', id: projectId },
      ],
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
    deleteFinanceExecutiveFromProject: build.mutation<
      { data: IProject },
      IDeleteUserFromProjectRequest
    >({
      query: ({ projectId, userId }) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: 'Projects-Users', id: projectId },
      ],
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    getProjectEventsByProjectId: build.query<IProjectEvent[], string>({
      query: (projectId: string) => `api/projects/events/projects/${projectId}`,
      transformResponse: (response: ITransformProjectEventsResponse) => {
        return response.data;
      },
    }),
    addProjectEvent: build.mutation({
      query: (data: IProjectEventReq) => ({
        url: 'api/projects/events/',
        method: 'POST',
        body: data,
      }),
    }),
    updateProjectEvent: build.mutation({
      query: ({ projectEventId, ...fieldsToUpdate }) => ({
        url: `api/projects/events/${projectEventId}`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
    }),

    addProjectEoi: build.mutation({
      query: (data: IAddEoi) => ({
        url: 'api/projects/eoi/add',
        method: 'POST',
        body: data,
      }),
    }),

    updateProjectEoi: build.mutation({
      query: (data: IEOI | { lead: TObjectId; executive: TObjectId }) => ({
        url: 'api/projects/eoi/update',
        method: 'PUT',
        body: data,
      }),
    }),

    updateEoiStatusByEoiId: build.mutation({
      query: ({ eoiId, isBookingEnabled, requestPayload }) => ({
        url: `api/projects/eoi/${eoiId}/${isBookingEnabled}`,
        method: 'PUT',
        body: requestPayload,
      }),
    }),

    getProjectEoisCount: build.query<
      // FIXME: add type
      // FIXME: ts table
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      TGetEoisCountReq
    >({
      queryFn: async (eoiCountReq, _queryApi, _extraOptions, fetchWithBQ) => {
        const results = await Promise.all(
          eoiCountReq.map((req) => {
            const {
              projectId,
              executiveId,
              isBookingEnabled,
              filterPayload,
              status,
            } = req;
            return fetchWithBQ({
              url: `api/projects/eoi/project/${projectId}/count`,
              method: 'GET',
              params: {
                executiveId,
                status,
                isBookingEnabled,
                ...filterPayload,
              },
            });
          })
        );

        if (results.some((result) => result.error)) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Some Error Occured',
            },
          };
        }
        return {
          data: eoiCountReq
            .map((req, index) => ({
              [req.tabName]: results[index].data,
            }))
            .reduce(
              (obj, item) => ({
                ...obj,
                ...item,
              }),
              {}
            ),
        };
      },
    }),

    getProjectEoisByProjectId: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        status,
        executive,
        isBookingEnabled = '',
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId?: TObjectId;
        pageNumber: number;
        pageCount: number;
        status: string;
        executive?: TObjectId;
        isBookingEnabled?: string;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/eoi/project/${projectId}?executive=${executive}&status=${status}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}&isBookingEnabled=${isBookingEnabled}`,
        method: 'GET',
      }),
    }),

    getProjectEoiPaymentsByProjectId: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        statuses,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId?: TObjectId;
        pageNumber: number;
        pageCount: number;
        statuses: PAYMENT_ORDER_STATUS[];
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/payment/eoiPaymentOrders/${projectId}?${statuses
          .map((status) => `statuses=${status}`)
          .join(
            '&'
          )}&pageCount=${pageCount}&pageNumber=${pageNumber}&${searchField}=${searchName}&startDate=${startDate}&endDate=${endDate}`,

        method: 'GET',
      }),
    }),
    getProjectEoiPaymentsCount: build.query<
      // FIXME: ts table
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      IGetPaymentsCountReq
    >({
      queryFn: async (
        eoiPaymentsReq,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const results = await Promise.all(
          eoiPaymentsReq.map((req) => {
            const {
              projectId,
              statuses,
              tabName,
              filterPayload,
              paymentLapsedTimeInMinutes,
            } = req;
            return fetchWithBQ({
              url: `api/payment/eoiPaymentOrders/${projectId}/count`,
              method: 'GET',
              params: {
                statuses,
                tabName,
                ...filterPayload,
                startDate:
                  getStartAndEndDateForLapsedAndPendingTab(
                    tabName,
                    filterPayload.startDate,
                    filterPayload.endDate,
                    paymentLapsedTimeInMinutes
                  ).startDate || '',
                endDate:
                  getStartAndEndDateForLapsedAndPendingTab(
                    tabName,
                    filterPayload.startDate,
                    filterPayload.endDate,
                    paymentLapsedTimeInMinutes
                  ).endDate || '',
              },
            });
          })
        );
        if (results.some((result) => result.error)) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Some Error Occured',
            },
          };
        }

        return {
          data: eoiPaymentsReq
            .map((req, index) => ({
              [req.tabName]: results[index].data,
            }))
            .reduce(
              (obj, item) => ({
                ...obj,
                ...item,
              }),
              {}
            ),
        };
      },
    }),

    getProjectBookingPaymentsByProjectId: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        statuses,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId?: TObjectId;
        pageNumber: number;
        pageCount: number;
        statuses: PAYMENT_ORDER_STATUS[];
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/payment/unitCartPaymentOrders/${projectId}`,
        method: 'GET',
        params: {
          pageCount,
          pageNumber,
          statuses,
          [searchField]: searchName,
          startDate,
          endDate,
        },
      }),
      transformResponse: (response: ITransformPaymentOrdersResponse) => {
        return response.data.paymentOrders;
      },
    }),
    getProjectBookingPaymentsCount: build.query<
      // FIXME: ts table
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      IGetPaymentsCountReq
    >({
      queryFn: async (
        bookingPaymentsReq,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const results = await Promise.all(
          bookingPaymentsReq.map((req) => {
            const {
              projectId,
              statuses,
              tabName,
              filterPayload,
              paymentLapsedTimeInMinutes,
            } = req;
            return fetchWithBQ({
              url: `api/payment/unitCartPaymentOrders/${projectId}/count`,
              method: 'GET',
              params: {
                statuses,
                tabName,
                ...filterPayload,
                startDate:
                  getStartAndEndDateForLapsedAndPendingTab(
                    tabName,
                    filterPayload.startDate,
                    filterPayload.endDate,
                    paymentLapsedTimeInMinutes
                  ).startDate || '',
                endDate:
                  getStartAndEndDateForLapsedAndPendingTab(
                    tabName,
                    filterPayload.startDate,
                    filterPayload.endDate,
                    paymentLapsedTimeInMinutes
                  ).endDate || '',
              },
            });
          })
        );
        if (results.some((result) => result.error)) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Some Error Occured',
            },
          };
        }

        return {
          data: bookingPaymentsReq
            .map((req, index) => ({
              [req.tabName]: results[index].data,
            }))
            .reduce(
              (obj, item) => ({
                ...obj,
                ...item,
              }),
              {}
            ),
        };
      },
    }),

    getProjectAllocationsByProjectId: build.mutation({
      query: (getAllocationReq: IGetAllocationReq) => ({
        url: `api/units/unitCart/allocations`,
        method: 'GET',
        params: {
          ...getAllocationReq,
          [getAllocationReq.searchField]: getAllocationReq.searchName,
        },
      }),
      transformResponse: (response: ITransformUnitCartsResponse) => {
        return response.data.unitCarts;
      },
    }),

    getProjectAllocationsCountByProjectId: build.query<
      // FIXME: add type
      // FIXME: ts table
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      IGetAllocationCountReq
    >({
      queryFn: async (
        allocationCountReq,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const results = await Promise.all(
          allocationCountReq.map((req) => {
            const { filterPayload, ...rest } = req;
            return fetchWithBQ({
              url: `api/units/unitCart/allocations/count`,
              method: 'GET',
              params: {
                ...filterPayload,
                ...rest,
              },
            });
          })
        );

        if (results.some((result) => result.error)) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Some Error Occured',
            },
          };
        }
        //FIXME: cleanup
        return {
          data: allocationCountReq
            .map((req, index) => ({
              [req.tabName]: results[index].data,
            }))
            .reduce(
              (obj, item) => ({
                ...obj,
                ...item,
              }),
              {}
            ),
        };
      },
    }),

    getAllocationByUnitCartId: build.mutation({
      query: (unitCartId: TObjectId) => ({
        url: `api/units/unitCart/allocation/${unitCartId}`,
        method: 'GET',
      }),
      transformResponse: (response: ITransformUnitCartResponse) => {
        return response.data;
      },
    }),

    updateProjectUnitStatus: build.mutation({
      query: ({ unitId, visibilityStatus, requestPayload }) => ({
        url: `api/units/${unitId}/status/${visibilityStatus}`,
        method: 'PUT',
        body: requestPayload,
      }),
    }),

    expireUnitCartByUnitCartId: build.mutation({
      query: (unitCartId: TObjectId) => ({
        url: `api/units/release-cron/${unitCartId}`,
        method: 'POST',
      }),
    }),
  }),

  overrideExisting: false,
});

interface ITransformProjectEventsResponse extends IResponseBase {
  data: IProjectEvent[];
}

export interface ITransformProjectCountResponse extends IResponseBase {
  data: {
    totalCount: number;
  };
}

interface ITransformPaymentOrdersResponse extends IResponseBase {
  data: { paymentOrders: IBookingPaymentRow[] };
}

export interface ITransformUnitCartsResponse extends IResponseBase {
  data: { unitCarts: IAllocationDetails[] };
}

interface ITransformUnitCartResponse extends IResponseBase {
  data: IAllocationDetails;
}

export const {
  useGetProjectsMutation,
  useGetProjectsCountQuery,
  useGetProjectsByProjectIdQuery,
  useGetProjectsByUserIdQuery,
  useGetProjectSelectionByUserIdQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useUpdateProjectDetailsMutation,
  useClearUnitInventoryMutation,

  useAddDemoRoomMutation,
  useGetDemoRoomQuery,
  useUpdateDemoRoomMutation,
  useDeleteDemoRoomMutation,

  useAddProjectExecutiveMutation,
  useUpdateProjectExecutiveMutation,
  useDeleteExecutiveFromProjectMutation,
  useDeleteGreFromProjectMutation,
  useDeletePreSaleFromProjectMutation,
  useDeleteFinanceExecutiveFromProjectMutation,

  useGetProjectEventsByProjectIdQuery,
  useAddProjectEventMutation,
  useUpdateProjectEventMutation,

  useAddProjectEoiMutation,
  useUpdateEoiStatusByEoiIdMutation,

  useGetProjectEoisCountQuery,

  useGetProjectEoisByProjectIdMutation,
  useGetProjectEoiPaymentsByProjectIdMutation,
  useGetProjectEoiPaymentsCountQuery,

  useGetProjectBookingPaymentsByProjectIdMutation,
  useGetProjectBookingPaymentsCountQuery,

  useGetProjectAllocationsByProjectIdMutation,
  useGetProjectAllocationsCountByProjectIdQuery,
  useLazyGetProjectAllocationsCountByProjectIdQuery,
  useGetAllocationByUnitCartIdMutation,

  useUpdateProjectUnitStatusMutation,
  useUpdateProjectEoiMutation,

  useExpireUnitCartByUnitCartIdMutation,
} = projectsAPISlice;

interface IDemoRoomQuery {
  projectId: TObjectId;
  executiveId: TObjectId;
}

interface IDemoRoomQueryResponse extends IResponseBase {
  data: IDemoRoom;
}

export interface ITransformEoisResponse extends IResponseBase {
  data: { eois: IEOI[] };
}

type TGetEoisCountReq = {
  projectId: TObjectId;
  executiveId: TObjectId;
  status: string;
  isBookingEnabled?: boolean;
  tabName: EOI_TAB_NAME;
  filterPayload?: Record<string, string>;
}[];
export type IGetPaymentsCountReq = {
  projectId: TObjectId;
  statuses: PAYMENT_ORDER_STATUS[];
  filterPayload: Record<string, string>;
  tabName: TAB_NAME;
  paymentLapsedTimeInMinutes?: number;
}[];
