import { pickAppropriateStyle, updateStyle } from '@/atoms/common/responsive';

import { BorderRadiusSize, CommonLayoutProps, Responsive } from './layout';

export const generateStyles = (
  layoutProps: CommonLayoutProps,
  responsiveConstant: keyof Responsive<unknown>
) => {
  /**
   * Padding
   */
  const { p, py, px, pt, pr, pb, pl } = layoutProps;

  const paddingStyles = getMarginAndPaddingStyles(
    'padding',
    p,
    py,
    px,
    pt,
    pr,
    pb,
    pl,
    responsiveConstant
  );

  /**
   * Margin
   */
  const { m, my, mx, mt, mr, mb, ml } = layoutProps;
  const marginStyles = getMarginAndPaddingStyles(
    'margin',
    m,
    my,
    mx,
    mt,
    mr,
    mb,
    ml,
    responsiveConstant
  );

  const { position, inset, top, right, bottom, left, width, height } =
    layoutProps;

  const positionStyles = getPositionStyles(
    position,
    inset,
    top,
    right,
    bottom,
    left,
    width,
    height,
    responsiveConstant
  );

  const { backgroundColor, color } = layoutProps;

  const colorStyles = getColorStyles(
    backgroundColor,
    color,
    responsiveConstant
  );

  const { borderWidth, borderStyle, borderRadius, borderColor } = layoutProps;

  const borderStyles = getBorderStyles(
    borderWidth,
    borderStyle,
    borderRadius,
    borderColor,
    responsiveConstant
  );

  return {
    ...paddingStyles,
    ...marginStyles,
    ...colorStyles,
    ...borderStyles,
    ...positionStyles,
  };
};

const getMarginAndPaddingStyles = (
  type: 'padding' | 'margin',
  s: CommonLayoutProps['p'] = 0,
  sy: CommonLayoutProps['py'] = 0,
  sx: CommonLayoutProps['px'] = 0,
  st: CommonLayoutProps['pt'] = 0,
  sr: CommonLayoutProps['pr'] = 0,
  sb: CommonLayoutProps['pb'] = 0,
  sl: CommonLayoutProps['pl'] = 0,
  responsiveConstant: keyof Responsive<unknown>
) => {
  if (s) {
    return { ...updateStyle(type, s, responsiveConstant, '', 'rem') };
  }

  if (sx || sy) {
    return {
      ...updateStyle(
        type === 'padding' ? 'padding-top' : 'margin-top',
        sy,
        responsiveConstant,
        '',
        'rem'
      ),
      ...updateStyle(
        type === 'padding' ? 'padding-bottom' : 'margin-bottom',
        sy,
        responsiveConstant,
        '',
        'rem'
      ),
      ...updateStyle(
        type === 'padding' ? 'padding-left' : 'margin-left',
        sx,
        responsiveConstant,
        '',
        'rem'
      ),
      ...updateStyle(
        type === 'padding' ? 'padding-right' : 'margin-right',
        sx,
        responsiveConstant,
        '',
        'rem'
      ),
    };
  }

  if (st || sr || sb || sl) {
    return {
      ...updateStyle(
        type === 'padding' ? 'padding-top' : 'margin-top',
        st,
        responsiveConstant,
        '',
        'rem'
      ),
      ...updateStyle(
        type === 'padding' ? 'padding-right' : 'margin-right',
        sr,
        responsiveConstant,
        '',
        'rem'
      ),
      ...updateStyle(
        type === 'padding' ? 'padding-bottom' : 'margin-bottom',
        sb,
        responsiveConstant,
        '',
        'rem'
      ),
      ...updateStyle(
        type === 'padding' ? 'padding-left' : 'margin-left',
        sl,
        responsiveConstant,
        '',
        'rem'
      ),
    };
  }
};

const getBorderStyles = (
  borderWidth: CommonLayoutProps['borderWidth'],
  borderStyle: CommonLayoutProps['borderStyle'] = 'solid',
  borderRadius: CommonLayoutProps['borderRadius'],
  borderColor: CommonLayoutProps['borderColor'] = 'color-accent',
  responsiveConstant: keyof Responsive<unknown>
) => {
  // const styles: Record<string, string> = {};

  let styles = {};

  if (borderWidth) {
    styles = {
      ...updateStyle(
        'border-color',
        borderColor,
        responsiveConstant,
        'var(--',
        ')'
      ),
      ...updateStyle('border-style', borderStyle, responsiveConstant),
      ...updateStyle('border-width', borderWidth, responsiveConstant, '', 'px'),
    };
  }

  if (borderRadius) {
    const borderRadiusResponsive = pickAppropriateStyle<BorderRadiusSize>(
      borderRadius,
      responsiveConstant
    );
    if (borderRadiusResponsive) {
      styles = {
        ...styles,
        'border-radius': borderRadiusSize[borderRadiusResponsive],
      };
    }
  }

  return styles;
};

function getPositionStyles(
  position: CommonLayoutProps['position'],
  inset: CommonLayoutProps['inset'],
  top: CommonLayoutProps['top'],
  right: CommonLayoutProps['right'],
  bottom: CommonLayoutProps['bottom'],
  left: CommonLayoutProps['left'],
  width: CommonLayoutProps['width'],
  height: CommonLayoutProps['height'],
  responsiveConstant: keyof Responsive<unknown>
) {
  return {
    ...updateStyle('position', position, responsiveConstant),
    ...updateStyle('inset', inset, responsiveConstant),
    ...updateStyle('top', top, responsiveConstant),
    ...updateStyle('right', right, responsiveConstant),
    ...updateStyle('bottom', bottom, responsiveConstant),
    ...updateStyle('left', left, responsiveConstant),
    ...updateStyle('width', width, responsiveConstant),
    ...updateStyle('height', height, responsiveConstant),
  };
}

const getColorStyles = (
  backgroundColor: CommonLayoutProps['backgroundColor'],
  color: CommonLayoutProps['color'],
  responsiveConstant: keyof Responsive<unknown>
) => {
  return {
    ...updateStyle('color', color, responsiveConstant, 'var(--', ')'),
    ...updateStyle(
      'background-color',
      backgroundColor,
      responsiveConstant,
      'var(--',
      ')'
    ),
  };
};

const borderRadiusSize: Record<BorderRadiusSize, string> = {
  xxs: '0.5px',
  xs: '1px',
  sm: '2px',
  md: '4px',
  lg: '8px',
  xl: '12px',
  full: '50px',
};
