import { ICellRendererParams } from 'ag-grid-community';
import { useState } from 'react';

import { Icon } from '@/components/atoms/Icon';

import styles from './styles.module.css';

const ExpandRowCell = (props: ICellRendererParams) => {
  const { node } = props;

  const [active, setActive] = useState(false);

  const handleToggleExpandRowCell = () => {
    if (node.isExpandable()) {
      node.setExpanded(!node.expanded);
      setActive(!active);
    }
  };

  return (
    <div
      className={`${styles.customCellContainer} ${
        active ? styles.expanded : ''
      }`}
      onClick={handleToggleExpandRowCell}>
      <Icon
        propStyles={active ? styles.iconExpanded : ''}
        name={active ? 'arrow_drop_up' : 'arrow_drop_down'}
      />
    </div>
  );
};

export default ExpandRowCell;
