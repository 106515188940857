import { ITableMain } from '@/molecules/Table/Main';
import { getAuthTokenLocal } from '@/utils/localStorage';

export async function fetchRowsCount<TRowData, TFetchData, TCountData>(
  filters: Record<string, string>,
  apiConfig: ITableMain<TRowData, TFetchData, TCountData>['apiConfig']
) {
  const url = new URL(`${apiConfig.baseApi}/count`);
  const qparams = {
    ...apiConfig.getQParams(filters),
  } as Record<string, string>;

  url.search = new URLSearchParams(qparams).toString();
  //FIXME:  Error handling, retry option
  const resp = await fetch(url, {
    headers: {
      'x-auth-token': getAuthTokenLocal(),
    },
  });
  const respJson: TCountData = await resp.json();
  return apiConfig.selectCount(respJson);
}
