import * as Popover from '@radix-ui/react-popover';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useFormContext } from 'react-hook-form';
import * as zod from 'zod';

import { Button } from '@/atoms/Button';
import { Clickable } from '@/atoms/Clickable';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Container } from '@/atoms/Container';
import { Form } from '@/atoms/Form';
import { Text } from '@/atoms/Text/Text';
import { Icon } from '@/components/atoms/Icon';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { useGetFilter } from '@/molecules/Filter/hooks/FilterContext';
import { DateUtils } from '@/utils/DateUtils';

import { DateInput } from './DateInput';
import { FilterCheckbox } from './FilterCheckbox';
import { FilterTrigger } from './FilterTrigger';
import { Input } from './Input';

export const Filter = (props: IFilterProps) => {
  const { filterConfig, onFilterSelect } = props;
  const [filterState, setFilterState] = useGetFilter();
  const [state, setState] = useState<IFilterState>(filterState);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);

  useEffect(() => {
    if (!state || !Object.keys(state).length) {
      setIsFiltersVisible(true);
    }
  }, [state]);

  const isTextFilterValid = filterConfig.some(
    (filter) => filter.value === state?.text?.value
  );
  const isDateFilterValid = filterConfig.some(
    (filter) => filter.value === state?.date?.value
  );

  return (
    <Popover.Root
      open={isPopoverOpen}
      onOpenChange={(open) => {
        setIsPopoverOpen(open);
      }}>
      <FilterTrigger
        state={state}
        setState={setState}
        setFilterState={setFilterState}
        isTextFilterValid={isTextFilterValid}
        isDateFilterValid={isDateFilterValid}
      />
      <Popover.Portal>
        <Popover.Content
          style={{
            backgroundColor: 'white',
            zIndex: 10,
            boxShadow: '0 0 0.5rem hsla(0, 0%, 0%, 0.1)',
            width: '500px',
            maxHeight: '600px',
            overflowY: 'scroll',
          }}>
          <Form
            schema={zod
              .object({
                search: zod.string().trim().optional(),
                'filter-date': zod
                  .object({
                    from: zod.date(),
                    to: zod
                      .date()
                      .optional()
                      .refine((to) => !!to, {
                        message: 'Please select end date',
                      }),
                  })
                  .optional(),
                'filter-checkbox': zod.array(zod.string()).min(1, {
                  message: 'Please select at least 1 filter',
                }),
              })
              .refine(
                (data) => {
                  const map = data['filter-checkbox'].map((val) => {
                    const getFilterObj = filterConfig.find(
                      (filter) => filter.value === val
                    );
                    if (getFilterObj?.fieldType === 'text')
                      return !!data.search;

                    if (getFilterObj?.fieldType === 'date') {
                      return !!(
                        data['filter-date']?.from && data['filter-date'].to
                      );
                    }
                  });

                  return map.every((val) => val);
                },
                {
                  message: 'Please add valid filter values',
                  path: ['filter-value'],
                }
              )}
            defaultValues={{
              search: state?.text?.filter,
              // FIXME: ts table
              // @ts-expect-error fix types
              'filter-date': state?.date?.filter?.from
                ? {
                    // FIXME: ts table
                    // @ts-expect-error fix types
                    from: parseISO(state?.date?.filter?.from),
                    // FIXME: ts table
                    // @ts-expect-error fix types
                    to: parseISO(state?.date?.filter?.to),
                  }
                : undefined,
              'filter-checkbox':
                Object.values(state || {}).map((val) => val?.value) || [],
            }}
            onSubmit={(data) => {
              const newState = structuredClone(state);
              if (newState?.text) {
                newState.text.filter = data.search;
              }
              if (newState?.date) {
                newState.date.filter = {
                  // FIXME: ts table
                  // @ts-expect-error fix types
                  from: DateUtils.format(data['filter-date'].from),
                  // FIXME: ts table
                  // @ts-expect-error fix types
                  to: DateUtils.format(data['filter-date'].to),
                };
              }
              setState(newState);
              setFilterState(newState);
              onFilterSelect?.(newState);
              setIsPopoverOpen(false);
            }}>
            <FlexDeprecated
              px={4}
              py={3}
              backgroundColor='color-primary-100'
              UNSAFE_STYLES={{
                boxShadow: '0 0 0.5rem hsla(0, 0%, 0%, 0.1)',
                overflow: 'hidden',
              }}
              flexColumn
              spacing={1}>
              <ConditionalRendering showIf={state?.text || state?.date}>
                <Text text='All Selected Filters' color='color-primary-64' />
              </ConditionalRendering>
              <Input
                state={state}
                filterConfig={filterConfig}
                setState={setState}
                setFilterState={setFilterState}
                onFilterSelect={onFilterSelect}
                isFilterValid={isTextFilterValid}
                setIsFiltersVisible={setIsFiltersVisible}
              />
              <DateInput
                state={state}
                filterConfig={filterConfig}
                setState={setState}
                setFilterState={setFilterState}
                onFilterSelect={onFilterSelect}
                isFilterValid={isDateFilterValid}
                setIsFiltersVisible={setIsFiltersVisible}
              />
              <FilterCheckbox
                // FIXME: @siddhant explain why key is added
                key={
                  state
                    ? Object.values(state)
                        .map((state) => state?.value)
                        .join(',')
                    : 'key'
                }
                state={state}
                isFiltersVisible={isFiltersVisible}
                filterConfig={filterConfig}
                setState={setState}
                setIsFiltersVisible={setIsFiltersVisible}
              />
              <CTA
                isFiltersVisible={isFiltersVisible}
                setIsFiltersVisible={setIsFiltersVisible}
              />
              <Popover.Arrow className='PopoverArrow' />
            </FlexDeprecated>
          </Form>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

type IFilterProps = {
  filterConfig: IFilterConfig;
  onFilterSelect?: (filterState: IFilterState) => void;
};

// FIXME: string -> 'text' | `date`
export type IFilterState = Record<string, TextFilterConfig | null> | null;

export type IFilterConfig = Array<TextFilterConfig>;

export type TextFilterConfig = {
  label: string;
  value: string;
  renameKeyBeforeSendingNetworkRequest?: string;
  filter?: string | DateRange;
  fieldType: string;
  type?: 'equals' | 'contains' | 'in' | 'notIn' | 'lessThan' | 'greaterThan';
};

export type IIsFilterValidProps = {
  isDateFilterValid: boolean;
  isTextFilterValid: boolean;
};

export type IInputProps = {
  state: IFilterState;
  setState: React.Dispatch<React.SetStateAction<IFilterState>>;
  setFilterState: (filterState: IFilterState) => void;
  onFilterSelect?: (filterState: IFilterState) => void;
  setIsFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterValid: boolean;
  filterConfig: IFilterConfig;
};
function CTA(props: {
  isFiltersVisible: boolean;
  setIsFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { isFiltersVisible, setIsFiltersVisible } = props;
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <FlexDeprecated justifyContent='end'>
        <FlexDeprecated
          flexRow={false}
          alignItems='center'
          justifyContent='end'>
          <ConditionalRendering showIf={!isFiltersVisible}>
            <Clickable onClick={() => setIsFiltersVisible(true)}>
              <FlexDeprecated
                backgroundColor='color-primary-white-87'
                alignItems='center'
                justifyContent='center'
                py={1.4}
                px={3}
                borderRadius='md'
                spacing={0}>
                <Icon name='add' size='sm' />
                <Text text='Add filter' />
              </FlexDeprecated>
            </Clickable>
          </ConditionalRendering>
          <Container>
            <Button type='submit' accent='primary'>
              Apply Filter
            </Button>
          </Container>
        </FlexDeprecated>
        {errors && (
          <Text
            text={
              Object.values(errors)?.[0]?.message ||
              Object.values(errors)?.[0]?.to?.message
            }
            color='color-status-error'
            size='xs'
          />
        )}
      </FlexDeprecated>
    </>
  );
}
