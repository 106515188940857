import { MouseEventHandler } from 'react';

import styles from './styles.module.css';

export const Clickable = (props: IClickableProps) => {
  const { children, onClick, fullWidth = false, disabled = false } = props;

  return (
    <button
      className={`${disabled ? styles.clickableDisabled : styles.clickable} ${fullWidth ? styles.fullWidth : ''}`}
      onClick={onClick}
      disabled={disabled}>
      {children}
    </button>
  );
};

interface IClickableProps {
  children: JSX.Element;
  onClick: MouseEventHandler<HTMLButtonElement>;
  fullWidth?: boolean;
  disabled?: boolean;
}
