import { IQrCodeResponse } from '@/admin/components/pages/AdminDashboard/AdminExecutiveList/Body/UserListGrid/QrCodeCell';
import type { ILQRCode } from '@/admin/interfaces/index';
import { adminAPISlice } from '@/admin/services';
import type { IResponseBase, IUser, TObjectId } from '@/interfaces';

export const qrCodeAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getExecutiveQrCode: build.query({
      query: ({
        executiveId,
        projectId,
      }: {
        executiveId: TObjectId;
        projectId: TObjectId;
      }) => `api/qrCode/smProject/${executiveId}/${projectId}`,
      transformResponse: (response: ITransformQrCodeResponse) => {
        return response.data;
      },
    }),
    getLqrCode: build.query({
      query: (pin: string) => `api/lqrCode?pin=${pin}`,
      transformResponse: (response: ITransformLqrCodeResponse) => {
        return response.data;
      },
    }),

    getLeadByPin: build.mutation({
      query: (pin: string) => `api/lqrCode/${pin}`,
      transformResponse: (response: { data: IUser }) => {
        return response.data;
      },
    }),
  }),
  overrideExisting: false,
});

interface ITransformLqrCodeResponse extends IResponseBase {
  data: ILQRCode;
}

interface ITransformQrCodeResponse extends IResponseBase {
  data: IQrCodeResponse;
}

export const {
  useGetExecutiveQrCodeQuery,
  useGetLqrCodeQuery,
  useGetLeadByPinMutation,
} = qrCodeAPISlice;
