import { TAB_NAME } from '@/admin/components/pages/ProjectBookingPayments';

export const hidePaymentConfirmationColumn = (tabName: TAB_NAME) =>
  !(
    tabName === TAB_NAME.Pending ||
    tabName === TAB_NAME.Lapsed ||
    tabName === TAB_NAME.Failed
  );

export const hidePaymentProcessColumn = (tabName: TAB_NAME) =>
  !(tabName === TAB_NAME.Completed || tabName === TAB_NAME.Failed);

export const hidePaymentRejectColumn = (tabName: TAB_NAME) =>
  !(
    tabName === TAB_NAME.Completed ||
    tabName === TAB_NAME.Pending ||
    tabName === TAB_NAME.Lapsed
  );
