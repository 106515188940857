import { IMinMaxRange } from '@/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { UserRole } from '@/constants/userRoles';

export const MOBILE_LENGTH = 10;
export const OTP_LENGTH = 6;
export const PINCODE_LENGTH = 6;
export const LQR_PIN_LENGTH = 5;
export const AAdhAAR_NUMBER_LENGTH = 12;
export const PAN_NUMBER_LENGTH = 10;

export const userAllowedToDelete: UserRole[] = [
  UserRole.EXECUTIVE,
  UserRole.GRE,
  UserRole.PRE_SALES,
  UserRole.FINANCE,
];

export const DEFAULT_PROPERTY_CONFIGS = [
  '1 BHK',
  '2 BHK',
  '2 BHK Duplex',
  '3 BHK',
  '3 BHK Duplex',
  '4 BHK +',
];

export const ADDLEAD_PROPERTY_CONFIGS = [
  { displayText: '1', value: '1 BHK' },
  { displayText: '2', value: '2 BHK' },
  { displayText: '3', value: '3 BHK' },
  { displayText: '4', value: '4 BHK' },
  { displayText: '5+', value: '5+ BHK' },
  { displayText: 'Duplex', value: 'Duplex' },
  { displayText: 'Bunglow', value: 'Bunglow' },
  { displayText: 'Others', value: 'Others' },
];

export const ONE_CRORE = 10000000;
export const ONE_LAKH = 100000;

export const DEFAULT_PROPERTY_PRICE_RANGE: IMinMaxRange[] = [
  {
    min: 1000000,
    max: 5000000,
  },
  {
    min: 5000001,
    max: 10000000,
  },
  {
    min: 10000001,
    max: 50000000,
  },
  {
    min: 50000001,
    max: 100000000,
  },
];

//AG-grid Default column definition
export const defaultColDef = {
  sortable: false,
  filter: false,
  resizable: false,
  suppressMenu: true,
  editable: false,
  flex: 1,
};

export const DEFAULT_CITIES = [
  'Mumbai',
  'Pune',
  'Delhi',
  'Chennai',
  'Banglore',
  'Hyderabad',
  'Guwahati',
  'Jodhpur',
];

export const SITE_VISIT_CITIES = [
  'Mumbai',
  'Pune',
  'Delhi',
  'Chennai',
  'Banglore',
  'Hyderabad',
  'Guwahati',
  'Jodhpur',
  'Chhatrapati Sambhajinagar',
  'Navi Mumbai',
  'Solapur',
  'Bhiwandi-Nizampur',
  'Jalgaon',
  'Amravati',
  'Nanded-Waghala',
  'Kolhapur',
  'Sangli-Miraj-Kupwad',
  'Malegaon',
  'Akola',
  'Latur',
  'Dhule',
  'Ahmednagar',
  'Chandrapur',
  'Parbhani',
  'Ichalkaranji',
  'Jalna',
  'Ambarnath',
  'Bhusawal',
  'Beed',
  'Gondia',
  'Satara',
  'Barshi',
  'Yavatmal',
  'Achalpur',
  'Dharashiv',
  'Nandurbar',
  'Wardha',
  'Udgir',
  'Hinganghat',
].sort();

export const DEFAULT_RESIDENTIAL_CONFIGS = ['Residential', 'Commercial'];

export const LOCATION_MUMBAI = [
  'Aarey Milk Colony',
  'Abdul Rehman Street',
  'Adarsh Nagar',
  'Agiary Lane',
  'Agripada',
  'Airoli',
  'Akurli road',
  'Ali dada estate',
  'Aloysius Soares Road',
  'Altamount Road',
  'Ambarnath',
  'Ambedkar Road',
  'Ambernath',
  'Ambivali',
  'Amboli',
  'Ambujwadi',
  'Amrut Nagar',
  'Anand Nagar',
  'Anand Park',
  'Anant Wadi',
  'Andheri',
  'Antop Hill',
  'Anushakti Nagar',
  'Apta',
  'Aram Nagar',
  'Arthur Road Junction',
  'Asalfa',
  'Asangaon',
  'Ashok Van',
  'Atgaon',
  'Atur Park',
  'Avdhut Nagar',
  'Azad Nagar',
  'B Block BKC',
  'Babhai',
  'Bachan tabela',
  'Badam Wadi',
  'Badlapur',
  'Bail Bazar',
  'Bairam Naupada',
  'Balaji Colony',
  'Balasaheb Thackeray Flyover',
  'Ballard Estate',
  'Bamandongri',
  'Bandra',
  'Bandra Fort',
  'Bandra Kurla Complex',
  'Bandra Reclamation',
  'Bandra Terminus',
  'Bandra Worli Sea Link',
  'Bandstand Promenade',
  'Bangur Nagar',
  'Basant Park',
  'Behram Baug',
  'Best Nagar',
  'Beverley',
  'Bhakti Park',
  'Bhandup',
  'Bharam Nagar',
  'Bharat Nagar',
  'Bhayandar',
  'Bhayandar Creek Bridge',
  'Bhayandar Flyover',
  'Bhayandar Khadi',
  'Bhayandar railway Station',
  'Bhayandar Subway',
  'Bhivpuri Road',
  'Bhiwandi',
  'Bhuleshwar',
  'BJ Road',
  'Boisar',
  'Bombay Colony',
  'Borivali',
  'Borivali (West)',
  'Borla village',
  'BPCL',
  'BPT Colony',
  'BR Nagar',
  'Breach Candy',
  'BSNL Colony',
  'Byculla',
  'C.G.S Colony',
  'Carmichael Road',
  'Carter Road',
  'Cavel',
  'CBD Belapur',
  'Central Avenue',
  'Chaitanya Nagar',
  'Chakala',
  'Champa Wadi',
  'Chandanwadi Road',
  'Chandivali',
  'Chapel Avenue',
  'Charbi Gali',
  'Charkop',
  'Charni Road',
  'Chedda Nagar',
  'Cheeta Camp',
  'Chembur',
  'Chembur Camp',
  'Chembur Causeway',
  'Chikhloli',
  'Chikuwadi',
  'Chimbai Road',
  'Chinchpokli',
  'Chira Bazaar',
  'Chor Bazaar',
  'Christian Gaon',
  'Chunabhatti',
  'Churchgate',
  'Clive Road',
  'Colaba',
  'Collector Colony',
  'Cotton Exchange',
  'Cotton Green',
  'Court',
  'CS Complex',
  'Cuffe Parade',
  'Cumbala Hill',
  'Currey Road',
  'D.N. Nagar',
  "D'Monte Park Road",
  'Dadar',
  'Dadasaheb Parulekar Marg',
  'Dadiseth Agiary Lane',
  'Dagdi Chawl',
  'Dahanu',
  'Dahanu Road',
  'Dahanukar Wadi',
  'Dahisar',
  'Damu nagar',
  'Dativali',
  'Dattani',
  'Dava Bazaar',
  'Davri Nagar',
  'Deonar',
  'Dhanji Street',
  'Dharavi',
  'Dhirubhai Parekh Marg',
  'Dhobitalao',
  'Diamond',
  'Diamond Garden',
  'Dighe',
  'Dindoshi',
  'Diva',
  'Dnyaneshwar Marg',
  'Dockyard Road',
  'Dolavli',
  'Dombivali',
  'Dongri',
  'DR. Ambedkar Road',
  'Dr. Atmaram Merchant Road',
  'Dr. M.B. Velkar Street',
  'Dr. Viegas Street',
  'Dronagiri',
  'Eksar Colony',
  'Ekta Colony',
  'Evershine Nagar',
  'Fanas Wadi',
  'Fofal Wadi',
  'Fort',
  'Four Bungalows',
  'Gaiwadi Lane',
  'Galaxy',
  'Galaxy Apartment',
  'Gandhi Nagar',
  'Ganpat Patil Marg',
  'Garodia Nagar',
  'Gaushala',
  'Gautam Nagar',
  'Gavde Nagar',
  'Gavhan',
  'Gazdhar Bandh',
  'Gcc Club',
  'Ghansoli',
  'Ghatkopar',
  'Ghatla village',
  'Ghodapdeo',
  'Ghodbunder',
  'Girgaon',
  'Godrej Creek',
  'Godrej Hillside Colony',
  'Godrej Station Colony',
  'Gokuldham',
  'Golf Club Road',
  'Gorai',
  'Goregaon',
  'Govandi',
  'Gowalia Tank',
  'Grant Road',
  'Gulal Wadi',
  'Gupha Tekdi',
  'Guru Nanak Road',
  'Guru Tegh Bahadur Nagar',
  'Haines Road',
  'Halav pool',
  'Hamrapur',
  'Hanuman Nagar',
  'Harbour Suburbs',
  'Hill Road',
  'Hindu colony',
  'Hindustan Naka',
  'Hiranandani',
  'Hiranandani Gardens',
  'Hollvard Road',
  'HP nagar',
  'I.C. Colony',
  'Indian Institute of Technology Bombay campus',
  'Indian Oil Nagar',
  'Indiraji Nagar',
  'Indralok Phase 1',
  'Indralok Phase 2',
  'Indralok Phase 3',
  'Irla',
  'Jagannath Shankarseth (JSS) Road',
  'Jagdusha Nagar',
  'Jagruti Nagar',
  'Jamil Nagar',
  'Janata Colony',
  'Jankalyan Nagar',
  'Janupada',
  'Jawahar Nagar',
  'Jawhar',
  'Jayprakash Nagar',
  'JB Nagar',
  'Jesal park',
  'Jijamata Colony',
  'Jite',
  'Jivali Pada',
  'Jogeshwari',
  'Juchandra',
  'Juhu',
  'Juhu Koliwada',
  'Juinagar',
  'Julianwadi',
  'JVLR',
  'Kadam Nagar',
  'Kaju Pada',
  'Kajuwadi',
  'Kala Ghoda',
  'Kala Nagar',
  'Kalamboli',
  'Kalbadevi',
  'Kalbadevi Road',
  'Kalina',
  'Kalwa',
  'Kalya',
  'Kalyan',
  'Kaman Road',
  'Kamathipura',
  'Kamela',
  'Kanakia',
  'Kandivali',
  'Kandivali west',
  'Kanjurmarg',
  'Kannamwar Nagar',
  'Kapadia nagar',
  'Karjat',
  'Kasaiwada',
  'Kasara',
  'Kashimira',
  'Kasu',
  'Kavi Nirav Lane',
  'Kazi Syed Street',
  'KC Marg',
  'Kelavli',
  'Kelve Road',
  'Kemps Corner',
  'Ketkipada',
  'Kevni Pada',
  'Khadavli',
  'Khadi',
  'Khan Estate',
  'Khandeshwar',
  'Khar',
  'Khar Danda',
  'Kharbao',
  'Khardi',
  'Kharghar',
  'Kharkopar',
  'Kherwadi',
  'Khodad Circle',
  'Khopoli',
  'Khotachiwadi',
  'Kika Street',
  "King's Circle",
  'Kirol',
  'Kohinoor City',
  'Kolbhat Lane',
  'Koliwada',
  'Kolshet Road',
  'Konkani pada',
  'Koombarwara',
  'Kopar',
  'Kopar Khairane',
  'Kora Kendra',
  'Kranti Nagar',
  'Krishna Colony',
  'Kurla',
  'L.I.C. Colony aka Jeevan Bhima Nagar',
  'Lal Taki',
  'Lalbaug',
  "Land's End",
  'Laxmi Nagar',
  'LIG',
  'Liliya Nagar',
  'Lohar Chawl',
  'Lokhandwala',
  'Lokhandwala township',
  'Lower Parel',
  'Lowjee',
  'M I G Colony',
  'M.J. Market',
  'Machi market',
  'Magathane',
  'Mahalaxmi',
  'Maharashtra Nagar',
  'Mahavir Nagar',
  'Mahim',
  'Mahul',
  'Maitri Park',
  'Majipada',
  'Makad wali chawal',
  'Malabar Hill',
  'Malad',
  'Malharrao Wadi',
  'Malvani',
  'Mandala',
  'Mandapeshwar Caves',
  'Mangaladevi Marg',
  'Mankhur',
  'Manpada',
  'Mansarovar',
  'Manuel Gonsalves Road',
  'Maratha Nagar',
  'Maravali',
  'Marine Drive, Mumbai',
  'Marine Lines',
  'Marol',
  'Masjid',
  'Matunga',
  'Medtiya Nagar',
  'Mhada Colony 19',
  'Mhatre Wadi Tadwe Wadi',
  'MIG',
  'Mira gaon',
  'Mira Road',
  'Mira-Bhayandar',
  'Mirza Street',
  'Model Town',
  'Mokhada',
  'Momin Nagar',
  'Morarji Nagar',
  'Morga Pada',
  'Motilal Nagar',
  'Mount Carmel Road',
  'Mount Mary Road',
  'Mubarak complex',
  'Mulund',
  'Mulund[edit]',
  'Mumbai Central',
  'Mumbra',
  'Murbad',
  'Mysore Colony',
  'Nagdevi Cross Street',
  'Nagothane',
  'Nagpada',
  'Nahur',
  'Naigaon',
  'Naigaon West',
  'Nalasopara',
  'Nalasopara West',
  'Namesingh Chawl',
  'Nana Chowk',
  'Nancy Colony',
  'Narayan Dhuru Street',
  'Narendra Complex',
  'Nariman Point',
  'National Library Road',
  'Natwar Nagar',
  'Naupada',
  'Navade Road',
  'Navagaon',
  'Navy Nagar',
  'Naya Nagar',
  'Nehru Nagar',
  'Neral',
  'Nerul',
  'NESCO colony',
  'New Agripada',
  'New Tilak Nagar',
  'Nhava Sheva',
  'Nidi',
  'Nilaje',
  'Nirmal Nagar',
  'NL Complex',
  'Northern heights',
  'Oberoi Splendor',
  'Old Satta Gully',
  'Old Town',
  'Oshiwara',
  'P L Lokhande Marg',
  'Paach Rasta',
  'Palasdari',
  'Palghar',
  'Palghar District',
  'Pali Hill',
  'Pali Mala Road',
  'Pali Naka',
  'Pandhurang wadi',
  'Pandurang Ashram Marg',
  'Panjarpol',
  'Panjrapole Lane',
  'Pant Nagar',
  'Panvel',
  'Parel',
  'Park Site',
  'Parsi Colony',
  'Passpoli',
  'Patel Estate',
  'Pathanwadi',
  'Patilwadi',
  'Patkar Blocks',
  'Patlipada',
  'Patliputra Nagar',
  'Pen',
  'Penkarpada',
  'Pereira Road',
  'Perry Cross Road',
  'Pestom Sagar',
  'Pipe road',
  'Pipeline Road',
  'Pleasant Park',
  'Poisar',
  'Pokhran rd no 1 and 2',
  'Poonam Nagar',
  'Pophalwadi',
  'Postal Colony',
  'Pothohar Nagar',
  'Powai',
  'Prabhadevi',
  'Prabhat colony',
  'Prabhat Nagar',
  'Pratap Nagar',
  'Prathmesh Complex',
  'Pratiksha Nagar',
  'Premkumar Sharma Road',
  'Punjabwadi',
  'Queens Park',
  'Quresh Nagar',
  'Rabale',
  'Rajawadi',
  'Rajiv Gandhi Nagar',
  'Ram Mandir',
  'Ram Wadi',
  'Ramakrishna Chemburkar Marg',
  'Ramdev Park',
  'Rani Sati Marg',
  'Ranjanpada',
  'Ranwar',
  'Rasayani',
  'Rawalpada',
  'RCF',
  'Reay Road',
  'Rizvi Complex',
  'Roha',
  'Rup Nagar',
  'Sadguru Colony',
  'Sagar Sangam',
  'Sahakar Nagar',
  'Sahar',
  'Sahyadri Nagar',
  'Sainik Nagar',
  'Saint Andrew Road',
  'Saint Cyril Road',
  'Saint Josephs Road',
  'Saint Martin Road',
  'Saint Paul Road',
  'Saki Naka',
  'Samarth Nagar',
  'Samta Nagar',
  'Sandhurst Road',
  'Sangam Nagar',
  'Sanjay Nagar',
  'Sanpada',
  'Sant Dnyaneshwar Nagar',
  'Santacruz',
  'Santacruz West',
  'Santosh Nagar',
  'Saphale',
  'Sarvoday Nagar',
  'Seawoods',
  'Seetawadi',
  'Seven Bungalows',
  'Sewri',
  'SG Joshi Marg',
  'Shahad',
  'Shahapur',
  'Shaikh Memon Street',
  'Shakti Nagar',
  'Shankar Wadi',
  'Shanti Nagar',
  'Shastri Nagar',
  'Sheetal Nagar',
  'Shell Colony',
  'Shelu',
  'Shimpoli',
  'Shivaji Nagar',
  'Shivaji Talav',
  'Shree Nagar',
  'Shrikant Palekar Marg',
  'Shyam Nagar',
  'Siddharth Colony',
  'Sindhi Society',
  'Sion',
  'Sion Trombay Road | VN Purao Marg',
  'Sitaram Poddar Marg',
  'Somani Gram',
  'Somnath lane',
  'Somtane',
  'Sonapur',
  'South Mumbai',
  'Squatters Colony',
  'Srishti',
  "St Anthony's Road",
  'St Francis of Assisi Nagar',
  'St Gregorious Path',
  "St Sebastian's Marouli village",
  'St. Sebastian Road',
  'Subhash Nagar',
  'Sukurwadi',
  'Suman Nagar',
  'Sunder Nagar',
  'Surya Nagar',
  'Swadeshi Market',
  'Swastik Park',
  'Tagore Nagar',
  'Takiya wad',
  'Talasari',
  'Taloja',
  'Tambakata',
  'Tardeo',
  'Targhar',
  'Tashilanagar',
  'Tata Blocks',
  'Tatya Gharpure Marg',
  'Taxi Stand',
  'Thakkar Bappa Colony',
  'Thakur complex',
  'Thakur village',
  'Thakurdwar Road',
  'Thakurli',
  'Thane',
  'Thane Mulund Toll plaza',
  'Thane West',
  'Thansit',
  'Tikujini Wadi',
  'Tilak Nagar',
  'Titwala',
  'Trombay',
  'Trombay koliwada',
  'Tulshet Pada',
  'Turbhe',
  'Ulhasnagar',
  'Ulwe',
  'Umbermali',
  'Umerwadi',
  'Umroli',
  'Union Park',
  'Uran City',
  'Utkarsh Nagar',
  'Uttan',
  'Vada',
  'Vahatuk Nagar',
  'Vaidya Nagar',
  'Vaishali Nagar',
  'Vaitarna',
  'Vakola',
  'Vakola Pipeline',
  'Valmiki Nagar',
  'Vangani',
  'Vangaon',
  'Vasai',
  'Vasai Road',
  'Vasai West',
  'Vashi',
  'Vashinaka',
  'Vasind',
  'Vastu',
  'Vazira Naka',
  'Veermata Jijabai Technological Institute Five Gardens Institute of Chemical Technology',
  'Versova',
  'Vidyavihar',
  'Vikhroli',
  'Vikramgad',
  'Vile Parle',
  'Vinay Park',
  'Vinobha bhave',
  'Virar',
  'Virar West',
  'Vishnu Nagar',
  'Vithal Wadi',
  'Vithalwadi',
  'Vithoba Lane',
  'Wadala',
  'Wadavali village',
  'Walkeshwar',
  'WEH Western Express Highway',
  'Worli',
  'Xavier Street',
  'Yadav Nagar',
  'Yashwant Nagar',
  'Yogi Nagar',
  'Zaveri Baazar',
].sort();

export const LOCATION_PUNE = [
  'Ambegaon',
  'Aundh',
  'Baner',
  'Bavdhan',
  'Balewadi',
  'Shivajinagar',
  'Bibvewadi',
  'Bhugaon',
  'Bhukum',
  'Dhankawadi',
  'Dhanori',
  'Dhayari',
  'Erandwane',
  'Fursungi',
  'Ghorpadi',
  'Hadapsar',
  'Hingne Khurd',
  'Karve Nagar',
  'Kalas',
  'Katraj',
  'Khadki',
  'Kharadi',
  'Kondhwa',
  'Koregaon Park',
  'Kothrud',
  'Lohagaon',
  'Manjri',
  'Markal',
  'Mohammed Wadi',
  'Mundhwa',
  'Nanded',
  'Parvati',
  'Panmala',
  'Pashan',
  'Pirangut',
  'Shivane',
  'Sus',
  'Undri',
  'Vishrantwadi',
  'Vitthalwadi',
  'Vadgaon Khurd',
  'Vadgaon Budruk',
  'Vadgaon Sheri',
  'Wagholi',
  'Wanwadi',
  'Warje',
  'Yerwada',
  'Akurdi',
  'Bhosari',
  'Chakan',
  'Charholi Budruk',
  'Chikhli',
  'Chimbali',
  'Chinchwad',
  'Dapodi',
  'Dehu Road',
  'Dighi',
  'Dudulgaon',
  'Hinjawadi',
  'Kalewadi',
  'Kasarwadi',
  'Maan',
  'Moshi',
  'Phugewadi',
  'Pimple Gurav',
  'Pimple Nilakh',
  'Pimple Saudagar',
  'Pimpri',
  'Ravet',
  'Rahatani',
  'Sangvi',
  'Talawade',
  'Tathawade',
  'Thergaon',
  'Wakad',
  'Ambi',
  'Parandwadi',
  'Somatne',
  'Talegaon',
  'Urse',
  'Vadgaon Maval',
  'Varale',
  'Pune Cantonment',
  'Khadki',
  'Dehu Road',
  'Kasba Peth',
  'Guruwar Peth',
  'Somwar Peth',
  'Mangalwar Peth',
  'Shukrawar Peth',
  'Raviwar Peth',
  'Shaniwar Peth',
  'Bhavani Peth',
  'Ghorpade',
  'Budhwar Peth',
  'Ganesh Peth',
  'Sadashiv Peth',
  'Narayan Peth',
  'Rasta Peth',
  'Nana Peth',
  'Mahatma',
  'Navi Sadashiv Peth',
  'Pune Ring Road',
  'Mumbai–Pune Road',
  'Pune–Satara Road',
  'Dehu Road–Katraj Bypass',
  'Pune–Solapur Road',
  'Katraj–Manterwadi Bypass',
  'Pune–Ahmednagar Road',
  'Mundhwa Bypass',
  'Pune–Nashik Road',
  'Pune–Saswad Road',
  'Pune–Paud Road',
  'Shivaji Road',
  'Bajirao Road',
  'Tilak Road',
  'Lal Bahadur Shastri Road',
  'Laxmi Road',
  'Kumthekar Road',
  'Jangali Maharaj Road',
  'Gopal Krishna Gokhale Road',
  'FC Road',
  'Shankarsheth Road',
  'Sinhgad Road',
  'Bhandarkar Road',
  'Prabhat Road',
  'University Road',
  'Ganeshkhind Road',
  'M.G. Road and East Street',
  'Senapati Bapat Road',
  'Law College Road',
  'Aundh–Ravet Road',
  'Pashan–Sus Road',
  'NIBM',
].sort();

export const LOCATION_HYDERABAD = [
  'Begumpet',
  'SR Nagar',
  'Prakash Nagar',
  'Punjagutta',
  'Balkampet',
  'Ameerpet',
  'Sanathnagar',
  'Bharat Nagar',
  'Erragadda',
  'Borabanda',
  'Moti Nagar',
  'Nehru Nagar',
  'Khairtabad',
  'Somajiguda',
  'Raj Bhavan Road',
  'Lakdikapool',
  'Saifabad',
  'A.C. Guards',
  'Masab Tank',
  'Chintal Basti',
  'Musheerabad',
  'Chikkadpally',
  'Himayatnagar',
  'Ashok Nagar',
  'Domalguda',
  'Hyderguda',
  'Ramnagar',
  'Azamabad',
  'Adikmet',
  'Nallakunta',
  'Shanker Mutt',
  'RTC X Roads',
  'Gandhi Nagar',
  'Bagh Lingampally',
  'Vidyanagar',
  'nayaranguda',
  'Amberpet',
  'Tilaknagar',
  'Golnaka',
  'Barkatpura',
  'Shivam Road',
  'Jamia Osmania',
  'Kachiguda',
  'Badichowdi',
  'Nampally',
  'Abids',
  'Aghapura',
  'Koti',
  'Bank Street',
  'Boggulkunta',
  'Secunderabad',
  'Chilkalguda',
  'Kavadiguda',
  'MG Road',
  'Madannapet',
  'Minister Road',
  'Mylargadda',
  'Namalagundu',
  'Padmarao Nagar',
  'Pan bazar',
  'Parsigutta',
  'Patny',
  'Rani Gunj',
  'RP Road',
  'Sindhi Colony',
  'Sitaphalmandi',
  'Tarnaka',
  'Warsiguda',
  'Addagutta',
  'Tukaramgate',
  'Secunderabad Cantonment',
  'Bowenpally',
  'Karkhana',
  'Marredpally',
  'Sikh Village',
  'Trimulgherry',
  'Vikrampuri',
  'Gachibowli',
  'Nanakramguda',
  'HITEC City',
  'Madhapur',
  'Kondapur',
  'Kothaguda',
  'Jubilee Hills',
  'Banjara Hills',
  'Film Nagar',
  'Yousufguda',
  'Srinagar colony',
  'Serilingampally',
  'Chanda Nagar',
  'Allwyn Colony',
  'Hafeezpet',
  'Madinaguda',
  'Miyapur',
  'Kukatpally',
  'Allwyn Colony',
  'Bachupally',
  'KPHB',
  'Nizampet',
  'Pragathi Nagar',
  'Moosapet',
  'Mallampet',
  'Patancheru',
  'BHEL Township',
  'RC Puram',
  'Ameenpur',
  'Beeramguda',
  'Kistareddypet',
  'IDA Bollaram',
  'Medical Devices Park, Hyderabad',
  'Afzal Gunj',
  'Aliabad',
  'Alijah Kotla',
  'Asif Nagar',
  'Azampura',
  'Barkas',
  'Bazarghat',
  'Begum Bazaar',
  'Chaderghat',
  'Chanchalguda',
  'Chandrayan Gutta',
  'Chatta Bazaar',
  'Dabirpura',
  'Dar-ul-Shifa',
  'Dhoolpet',
  'Edi Bazar',
  'Falaknuma',
  'Karwan',
  'Malakpet',
  'Moghalpura',
  'Jahanuma',
  'Laad Bazaar',
  'Lal Darwaza',
  'Madina, Hyderabad',
  'Maharajgunj',
  'Mehboob ki Mehendi',
  'Mir Alam Tank',
  'Mozamjahi Market',
  'Nawab Saheb Kunta',
  'Nayapul',
  'Noorkhan Bazar',
  'Pisal Banda',
  'Purana pul',
  'Putlibowli',
  'Rein Bazar',
  'Shahran Market',
  'Shah Ali Banda',
  'Sultan Bazar',
  'Udden Gadda',
  'Uppuguda',
  'Yakutpura',
  'Balanagar',
  'Fateh Nagar',
  'Ferozguda',
  'Old Bowenpally',
  'Hasmathpet',
  'Quthbullapur',
  'Jeedimetla',
  'Jagadgirigutta',
  'Suraram',
  'Pet Basheerabad',
  'Kompally',
  'Medchal',
  'Kandlakoya',
  'Alwal',
  'Lothkunta',
  'Old Alwal',
  'Macha Bollaram',
  'Venkatapuram',
  'Shamirpet',
  'Malkajgiri',
  'Anandbagh',
  'Ammuguda',
  'Gautham Nagar',
  'Kakatiya Nagar',
  'Vinayak Nagar',
  'Moula-Ali',
  'Neredmet',
  'Old Neredmet',
  'Safilguda',
  'Sainikpuri',
  'Yapral',
  'Kapra',
  'A. S. Rao Nagar',
  "ECIL 'X' Roads",
  'Kamala Nagar',
  'Kushaiguda',
  'Cherlapally',
  'Keesara',
  'Nagaram',
  'Dammaiguda',
  'Jawaharnagar',
  'Rampally',
  'Cheriyal',
  'Uppal',
  'Habsiguda',
  'Ramanthapur',
  'Boduppal',
  'Nagole',
  'Nacharam',
  'Mallapur',
  'Ghatkesar',
  'Peerzadiguda',
  'Chengicherla',
  'Pocharam',
  'Narapally',
  'Medipally',
  'Dilsukhnagar',
  'Kothapet',
  'Gaddiannaram',
  'Moosarambagh',
  'L. B. Nagar',
  'Bairamalguda',
  'Chintalakunta',
  'Vanasthalipuram',
  'Hastinapuram',
  'Saroornagar',
  'Badangpet',
  'Balapur',
].sort();

export const LOCATION_DELHI = [
  'Adarsh Nagar',
  'AIIMS',
  'Akshardham',
  'Anand Vihar',
  'Arjan Garh',
  'Arthala',
  'Ashok Nagar',
  'Ashok Park Main',
  'Ashram Chowk',
  'Azadpur',
  'Babarpur',
  'Badarpur Border',
  'Badkhal Mor',
  'Bahadurgarh City',
  'Bali Nagar',
  'Barakhamba Road',
  'Bata Chowk',
  'Ber Sarai',
  'Bhajanpura',
  'Bhikaji Cama Place',
  'Botanical Garden',
  'Brigadier Hoshiyar Singh†',
  'Central Secretariat',
  'Chanakyapuri',
  'Chandni Chowk',
  'Chawri Bazar',
  'Chhatarpur',
  'Chirag',
  'Chittaranjan Park',
  'Civil Lines',
  'Connaught Place',
  'Dabri Mor - Janakpuri South',
  'Dabri, New Delhi',
  'Dariba Kalan',
  'Daryaganj',
  'Dashrath Puri',
  'Dayal Pur',
  'Defence Colony',
  'Delhi Aerocity',
  'Delhi Cantonment',
  'Delhi Gate',
  'Derawal Nagar',
  'Dhansa Bus Stand†',
  'Dhaula Kuan',
  'Dilli Haat - INA',
  'Dilshad Garden',
  'Durgabai Deshmukh South Campus',
  'Dwarka',
  'Dwarka Mor',
  'Dwarka Sector 10',
  'Dwarka Sector 11',
  'Dwarka Sector 12',
  'Dwarka Sector 13',
  'Dwarka Sector 14',
  'Dwarka Sector 21',
  'Dwarka Sector 8',
  'Dwarka Sector 9',
  'Dwarka Sub City',
  'East Azad Nagar',
  'East Vinod Nagar',
  'East Vinod Nagar - Mayur Vihar-II',
  'Escorts Mujesar',
  'ESI - Basaidarapur',
  'Fateh Nagar',
  'Geetanjali Enclave',
  'Ghevra',
  'Ghitorni',
  'Gokulpuri',
  'Gole Market',
  'Golf Course',
  'Golf Links, New Delhi',
  'Govindpuri',
  'Greater Kailash',
  'Green Park',
  'GTB Nagar',
  'Gulabi Bagh',
  'Gulmohar Park',
  'Guru Dronacharya',
  'Haiderpur Badli Mor',
  'Harkesh Nagar Okhla',
  'Hauz Khas',
  'Hindon River',
  'HUDA City Centre†',
  'IFFCO Chowk',
  'IIT Delhi',
  'INA Colony',
  'Inder Puri',
  'Inderlok',
  'Inderpuri',
  'Indira Gandhi International Airport',
  'Indraprastha',
  'IP Extension',
  'ITO',
  'Jaffrabad',
  'Jahangirpuri',
  'Jama Masjid',
  'Jamia Millia Islamia',
  'Janakpuri',
  'Janakpuri East',
  'Janakpuri West',
  'Jangpura',
  'Janpath',
  'Jasola Apollo',
  'Jasola Vihar Shaheen Bagh',
  'Jawaharlal Nehru Stadium',
  'Jhandewalan',
  'Jhilmil',
  'Johri Enclave',
  'Jor Bagh',
  'Kailash Colony',
  'Kalindi Kunj',
  'Kalkaji',
  'Kalkaji Mandir',
  'Kamla Nagar',
  'Kanhaiya Nagar',
  'Karawal Nagar',
  'Karkarduma',
  'Karkarduma Court',
  'Karol Bagh',
  'Kashmere Gate',
  'Kaushambi',
  'Keshav Puram',
  'Khan Market',
  'Khanpur',
  'Kirti Nagar',
  'Kohat Enclave',
  'Krishna Nagar',
  'Lajpat Nagar',
  'Lal Qila',
  'Laxmi Nagar',
  'Laxmibai Nagar',
  'Lodhi Colony',
  'Lok Kalyan Marg',
  'Lutyens',
  'Madipur',
  'Maharaja Surajmal Stadium',
  'Maharani Bagh',
  'Mahipalpur',
  'Majlis Park†',
  'Majnu-ka-tilla',
  'Major Mohit Sharma Rajendra Nagar',
  'Malviya Nagar',
  'Mandawali - West Vinod Nagar',
  'Mandi House',
  'Mansarovar Park',
  'Maujpur - Babarpur',
  'Mayapuri',
  'Mayur Vihar',
  'Mayur Vihar Extension',
  'Mayur Vihar Pocket I',
  'Meera Bagh',
  'Mehrauli',
  'Mewla Maharajpur',
  'MG Road',
  'Model Town',
  'Model Town',
  'Mohan Estate',
  'Mohan Nagar',
  'Moolchand',
  'Moti Bagh',
  'Moti Nagar',
  'Mukherjee Nagar',
  'Mundka',
  'Mundka Industrial Area',
  'Munirka',
  'Najafgarh',
  'Nand Nagri',
  'Nangli',
  'Nangloi',
  'Nangloi Jat',
  'Nangloi Railway Station',
  'Naraina',
  'Naraina Vihar',
  'Narela',
  'Naveen Shahdara',
  'Nawada',
  'Neelam Chowk Ajronda',
  'Nehru Enclave',
  'Nehru Place',
  'Netaji Nagar',
  'Netaji Subhash Place',
  'New Ashok Nagar',
  'New Delhi',
  'New Friends Colony',
  'New Moti Bagh',
  'NHPC Chowk',
  'Nirman Vihar',
  'Nizamuddin East',
  'Nizamuddin West',
  'Noida City Centre',
  'Noida Electronic City†',
  'Noida Sector 15',
  'Noida Sector 16',
  'Noida Sector 18',
  'Noida Sector 34',
  'Noida Sector 52',
  'Noida Sector 59',
  'Noida Sector 61',
  'Noida Sector 62',
  'Okhla',
  'Okhla Bird Sanctuary',
  'Okhla NSIC',
  'Okhla Vihar',
  'Old Delhi',
  'Old Faridabad',
  'Paharganj',
  'Palam',
  'Pamposh Enclave',
  'Panchsheel Park',
  'Pandav Nagar',
  'Pandit Shree Ram Sharma',
  'Paschim Vihar East',
  'Paschim Vihar West',
  'Patel Chowk',
  'Patel Nagar',
  'Patel Nagar',
  'Peeragarhi',
  'Pitampura',
  'Pragati Maidan',
  'Pratap Nagar',
  'Preet Vihar',
  'Pul Bangash',
  'Pul Pehladpur',
  'Punjabi Bagh',
  'Punjabi Bagh West',
  'Qutab Minar',
  'R. K. Puram',
  'Raisina Hill',
  'Raj Bagh',
  'Raja Nahar Singh†',
  'Rajdhani Park',
  'Rajender Nagar',
  'Rajendra Place',
  'Rajiv Chowk',
  'Rajouri Garden',
  'Rama Krishna Puram',
  'Ramakrishna Ashram Marg',
  'Ramesh Nagar',
  'Rithala',
  'Rohini East',
  'Rohini Sector 18, 19',
  'Rohini West',
  'Sadar Bazaar',
  'Sadar Bazaar Cantonment',
  'Sadar Bazaar, Delhi',
  'Safdarjung Enclave',
  'Sagar Pur',
  'Sainik Farm',
  'Saket',
  'Samaypur Badli',
  'Sant Surdas (Sihi)',
  'Sarai',
  'Sarai Kale Khan',
  'Sarai Kale Khan - Nizamuddin',
  'Sarai Rohilla',
  'Sarita Vihar',
  'Sarojini Nagar',
  'Sarvodaya Enclave',
  'Satguru Ram Singh Marg',
  'Sector 28',
  'Seelampur',
  'Shadipur',
  'Shadipur Depot',
  'Shahdara',
  'Shaheed Nagar',
  'Shaheed Sthal',
  'Shaheen Bagh',
  'Shahpur Jat',
  'Shakti Nagar',
  'Shakurpur',
  'Shalimar Bagh',
  'Shankar Vihar',
  'Shastri Nagar',
  'Shastri Park',
  'Shiv Ram Park',
  'Shiv Vihar',
  'Shivaji Park',
  'Shivaji Place',
  'Shivaji Stadium',
  'Shreshtha Vihar',
  'Shyam Park',
  'Sikanderpur',
  'Sir M. Vishweshwaraiah Moti Bagh',
  'Siri Fort',
  'South Extension',
  'South Patel Nagar',
  'Sriniwaspuri',
  'Subhash Nagar',
  'Sukhdev Vihar',
  'Sultanpur',
  'Supreme Court',
  'Tagore Garden',
  'Terminal 1-IGI Airport',
  'Tihar Village',
  'Tikri Border',
  'Tikri Kalan',
  'Tilak Nagar',
  'Timarpur',
  'Tis Hazari',
  'Trilokpuri Sanjay Lake',
  'Tughlakabad Station',
  'Tughlaqabad',
  'Udyog Bhawan',
  'Udyog Nagar',
  'Urdu Bazaar',
  'Uttam Nagar',
  'Uttam Nagar East',
  'Uttam Nagar West',
  'Vaishali',
  'Vasant Kunj',
  'Vasant Vihar',
  'Vasundhara Enclave',
  'Vidhan Sabha',
  'Vikas Nagar',
  'Vikaspuri',
  'Vinobapuri',
  'Vishwa Vidyalaya',
  'Vivek Vihar',
  'Wazirabad',
  'West Patel Nagar',
  'Yamuna Vihar',
  'Yashobhoomi Dwarka Sector - 25',
].sort();

export const PROJECT_TYPE = {
  PLOTS: 'PLOTS',
  TOWERS: 'TOWERS',
};

export const PAGE_COUNT = 10;

export const LOADING_TEXT = 'Please wait while we are loading the screen...';

export const DEFAULT_STATE = 'Maharastra';

export const ALLOWED_CITIES_LOCATIONS = [
  'Mumbai',
  'Pune',
  'Hyderabad',
  'Delhi',
] as const;
export type TAllowedCitiesLocations = (typeof ALLOWED_CITIES_LOCATIONS)[number];

export const locationsMap: Record<TAllowedCitiesLocations, string[]> = {
  Hyderabad: LOCATION_HYDERABAD,
  Mumbai: LOCATION_MUMBAI,
  Pune: LOCATION_PUNE,
  Delhi: LOCATION_DELHI,
};
