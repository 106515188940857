import { Responsive, StylePropType } from '@/atoms/common/layout/layout';
import { breakpoints } from '@/hooks/useResponsive';

export function pickAppropriateStyle<T>(
  styles: StylePropType<T>,
  responsiveConstant: keyof Responsive<unknown>
): T | undefined {
  if (!(typeof styles === 'object')) {
    return styles;
  }

  if (Object.keys(styles as Responsive<T>).length === 0 || styles === null) {
    throw new Error('Invalid styles object');
  }

  let lastValue = (styles as Responsive<T>)['initial'];
  const responsiveValues: Record<keyof Responsive<unknown>, T | undefined> = {
    initial: undefined,
    sm: undefined,
    md: undefined,
    lg: undefined,
    xl: undefined,
  };

  (Object.keys(breakpoints) as Array<keyof typeof breakpoints>).forEach(
    (key) => {
      if ((styles as Responsive<T>)[key]) {
        lastValue = (styles as Responsive<T>)[key];
      }
      responsiveValues[key] = lastValue;
    }
  );

  return responsiveValues[responsiveConstant];
}
export const updateStyle = (
  prop: string,
  value: unknown,
  responsiveConstant: keyof Responsive<unknown>,
  prefix: string = '',
  suffix: string = ''
) => {
  const valueResponsive = pickAppropriateStyle<unknown>(
    value,
    responsiveConstant
  );

  if (!valueResponsive) {
    return {};
  }

  return {
    [prop]: `${prefix}${valueResponsive}${suffix}`,
  };
};
