import { adminAPISlice } from '@/admin/services/index';
import type { TObjectId, IResponseBase, IUser } from '@/interfaces';
import type { INote, IPaymentOrder } from '@/booking/interfaces/index'; // TODO: use absolute path
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';

export const paymentsAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    createPaymentOrder: build.mutation({
      query: ({ unitCartId, amount }) => ({
        url: `api/payment/order?unitCartId=${unitCartId}${
          amount ? `&amount=${amount}` : ''
        }`,
        method: 'POST',
      }),
      transformResponse: (response: ITransformOrderResponse) => {
        return response.data;
      },
    }),
    createOrUpdatePaymentOrder: build.mutation({
      query: (order: IPaymentOrder) => ({
        url: `api/payment/createOrUpdateOrder`,
        method: 'POST',
        body: order,
      }),
      transformResponse: (response: ITransformPaymentOrderResponse) => {
        return response;
      },
    }),
    getNextPayableAmountForUnitCart: build.query<{ amount: number }, any>({
      query: (unitCartId: TObjectId) => ({
        url: `api/payment/nextPayableAmount?unitCartId=${unitCartId}`,
        method: 'GET',
      }),
      transformResponse: (response: ITransformPayableAmountResponse) => {
        return response.data;
      },
    }),
    successfulPaymentOrder: build.mutation({
      query: ({
        paymentOrderId,
        transactionId,
        unitCartId,
        mode,
        amountPaid,
      }: {
        paymentOrderId: string;
        transactionId: string;
        unitCartId: TObjectId;
        mode: string;
        amountPaid?: number;
      }) => ({
        url: `api/payment/successfulPayment`,
        method: 'PUT',
        body: {
          paymentOrderId,
          transactionId,
          unitCartId,
          mode,
          amountPaid,
        },
      }),
    }),

    getPaymentAuditLogs: build.query<IPaymentAuditLogs[], any>({
      query: ({ paymentId }: { paymentId: TObjectId }) =>
        `api/payment/audit-logs/${paymentId}`,
      transformResponse: (response: ITransformPaymentAuditLogsResponse) => {
        return response.data;
      },
    }),
  }),

  overrideExisting: false,
});

interface ITransformPaymentAuditLogsResponse extends IResponseBase {
  data: IPaymentAuditLogs[];
}

interface ITransformPaymentOrderResponse extends IResponseBase {
  data: IPaymentOrder;
}

interface ITransformOrderResponse extends IResponseBase {
  data: any; // TODO: Remove any
}

interface ITransformPayableAmountResponse extends IResponseBase {
  data: {
    amount: number;
  };
}

export const {
  useCreatePaymentOrderMutation,
  useCreateOrUpdatePaymentOrderMutation,
  useLazyGetNextPayableAmountForUnitCartQuery,
  useSuccessfulPaymentOrderMutation,

  useGetPaymentAuditLogsQuery,
  useLazyGetPaymentAuditLogsQuery,
} = paymentsAPISlice;

export interface IPaymentChange {
  field: string;
  oldValue: string;
  newValue: string | string[] | INote[] | PAYMENT_ORDER_STATUS;
}

export interface IPaymentAuditLogs {
  id: TObjectId;
  changes: IPaymentChange[];
  user?: IUser;
  document: TObjectId;
  timestamp: Date;
  operation: string;
}
