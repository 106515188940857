type ResultType = Array<string | JSX.Element>;

const escapeRegExp = (stringToGoIntoTheRegex: string) => {
  return stringToGoIntoTheRegex.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
};

const highlight = (input: string, regexes: RegExp[]): ResultType => {
  if (!regexes.length) {
    if (!/\S/.test(input)) return [];
    return [input];
  }
  const splitText = input.split(regexes[0]);
  const replacements = input.match(regexes[0]);
  const result: ResultType = [];
  const len = splitText.length - 1;
  if (replacements)
    for (let i = 0; i < len; i++) {
      result.push(...highlight(splitText[i], regexes.slice(1)));
      result.push(<strong key={i}>{replacements[i]}</strong>);
    }
  result.push(...highlight(splitText[len], regexes.slice(1)));
  return result;
};

export const multipleStringHighlights = (
  inputData: string,
  keywords: string[]
): React.ReactNode => {
  const regexes = keywords
    .filter((word) => word !== '')
    .filter((word) => !/^\+/.test(word))
    .map((word) => escapeRegExp(word))
    .map((word) => new RegExp(`\\b${word}\\b`, 'ig'));
  return highlight(inputData, regexes);
};
