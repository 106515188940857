import { Container } from '@/atoms/Container';
import { INoRowsExistProps } from '@/molecules/Table/Main/NoRowsExist';

interface IAgGridTableWrapperProps {
  children: React.ReactNode;
  rowAvailabilityStatus: INoRowsExistProps['rowAvailabilityStatus'];
}

const AgGridTableWrapper = (props: IAgGridTableWrapperProps) => {
  const { children, rowAvailabilityStatus } = props;

  let noRowsHeight;

  if (
    rowAvailabilityStatus === 'NO_ROWS' ||
    rowAvailabilityStatus === 'NO_ROWS_FILTER'
  ) {
    noRowsHeight = '70vh';
  }

  return (
    <div className='ag-theme-alpine'>
      <Container
        mt={1}
        width='100%'
        height={
          noRowsHeight ?? {
            sm: '40vh',
            md: '55vh',
            lg: '70vh',
          }
        }>
        {children}
      </Container>
    </div>
  );
};

export default AgGridTableWrapper;
