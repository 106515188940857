import { useMemo } from 'react';

import { ConditionalRendering } from '@/atoms/ConditionalRendering';

import styles from './styles.module.css';

interface IAgGridCellTextProps {
  cellText?: string | number;
  subText?: string | number;
  disabled?: boolean;
  copyToClipboard?: boolean;
  textType?: 'regular' | 'small';
  textTransform?: 'regular' | 'capitalize';
}

const AgGridCellText = (props: IAgGridCellTextProps) => {
  const {
    cellText,
    copyToClipboard = false,
    disabled = false,
    textType = 'regular',
    textTransform = 'regular',
    subText,
  } = props;

  const gridTextValue = useMemo(() => {
    if (cellText === undefined || cellText === null) {
      return 'Not Available';
    }
    return cellText;
  }, [cellText]);

  const handleCopyClick = () => {
    if (cellText && copyToClipboard) {
      navigator.clipboard.writeText(`${gridTextValue}`);
    }
  };

  return (
    <div className={`${styles.textWrapper} ${disabled && styles.disable}`}>
      <p
        title={cellText && copyToClipboard ? 'Click to copy' : ''}
        className={`${styles.cellText} ${
          copyToClipboard && styles.activeText
        } ${styles[textType]} ${styles[textTransform]} ${gridTextValue === 'Not Available' && styles.notAvailable}`}
        onClick={handleCopyClick}>
        {gridTextValue}
      </p>
      <ConditionalRendering showIf={subText}>
        <p className={styles.subText}>{subText}</p>
      </ConditionalRendering>
    </div>
  );
};

export default AgGridCellText;
