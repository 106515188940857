import { Viewer, Worker } from '@react-pdf-viewer/core';
import { useEffect, useState } from 'react';

import { Button } from '@/atoms/Button';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Icon } from '@/atoms/Icon';
import Image from '@/booking/components/common/ImageTS';
import Modal from '@/booking/components/common/modal/ModalTS';
import { DateUtils } from '@/utils/DateUtils';

import styles from './styles.module.css';

export interface IDocsViewerCellProps {
  docUrls: string[];
  leadName: string;
  createdAt: Date;
}

const DocsViewerCell = (props: IDocsViewerCellProps) => {
  const { docUrls, leadName, createdAt } = props;

  const [showViewer, setShowViewer] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    setActiveIndex(0);
  }, [showViewer]);

  if (docUrls.length === 0) {
    return (
      <Button
        accent='secondary'
        title='No file Attached'
        disabled
        propStyles={styles.attachBtn}>
        <Icon propStyles={styles.attachIcon} name='attach_file' />
      </Button>
    );
  }

  const openModal = () => {
    setShowViewer(true);
  };
  const closeModal = () => {
    setShowViewer(false);
  };

  const getFileExtension = (url: string) => {
    return url.split('.').pop()?.toLowerCase() || '';
  };

  const shortenString = (inputString: string) => {
    const maxLength = 15;
    if (inputString.length <= maxLength) {
      return inputString;
    } else {
      return inputString.slice(0, maxLength) + '...';
    }
  };

  const getFileType = (url: string) => {
    const parts = url.split('.');
    return parts[parts.length - 1];
  };

  const fileExtension = getFileType(docUrls[activeIndex]);

  const handleRotate = () => {
    const newRotation = (rotation + 90) % 360;
    setRotation(newRotation);
  };

  const rotationStyles = {
    transform: `rotate(${rotation}deg)`,
  };

  const renderFile = (url: string) => {
    const extension = getFileExtension(url);

    switch (extension) {
      case 'pdf':
        return (
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js'>
            <Viewer fileUrl={url} />
          </Worker>
        );
      case 'jpg':
      case 'jpeg':
      case 'png':
        return <Image src={url} alt={`Image ${activeIndex}`} />;
      default:
        return (
          <>
            <p className={styles.errorFileFormat}>File format not supported</p>
          </>
        );
    }
  };

  return (
    <>
      <ConditionalRendering showIf={showViewer}>
        <Modal
          onOutsideClick={closeModal}
          additionalClasses={styles.modelWrapper}>
          <div className={styles.headingWrap}>
            <p className={styles.fileName}>
              <Icon
                propStyles={styles.fileNameIcon}
                name={fileExtension === 'pdf' ? 'insert_drive_file' : 'image'}
              />
              {`Payment ${DateUtils.format(
                createdAt,
                DateUtils.FormatTo.DAY_DATE_LONG_DATE
              )}, of ${leadName} | ${
                docUrls[activeIndex] &&
                shortenString(docUrls[activeIndex].split('/').pop() || '')
              }`}
            </p>
            <Icon
              name='close'
              propStyles={styles.closeIcon}
              color='color-primary-0'
              onClick={closeModal}
            />
          </div>
          <div style={rotationStyles} className={styles.contentWrapper}>
            {renderFile(docUrls[activeIndex])}
          </div>

          <div className={styles.docButtons}>
            {docUrls.map((url, index) => (
              <p
                key={index}
                className={`${styles.docBtn} ${
                  activeIndex === index ? styles.active : ''
                }`}
                onClick={() => setActiveIndex(index)}>
                <span className={styles.leftContainer}>
                  <Icon
                    name={url.endsWith('.pdf') ? 'insert_drive_file' : 'image'}
                    propStyles={styles.fileIcon}
                  />
                  {url && shortenString(url.split('/').pop() || '')}
                </span>

                <ConditionalRendering showIf={activeIndex === index}>
                  <span className={styles.endIcon}>
                    <Icon name='done' propStyles={styles.fileNameIcon} />
                  </span>
                </ConditionalRendering>
              </p>
            ))}
            <Button accent='primary' onClick={handleRotate}>
              Rotate
              <Icon name='rotate_right' />
            </Button>
          </div>
        </Modal>
      </ConditionalRendering>
      <Button
        accent='bordered'
        onClick={openModal}
        propStyles={styles.attachBtn}>
        <Icon propStyles={styles.attachIcon} name='attach_file' />
      </Button>
    </>
  );
};

export default DocsViewerCell;
