import { zodResolver } from '@hookform/resolvers/zod';
import { createElement, ReactNode } from 'react';
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  useForm,
} from 'react-hook-form';
import * as zod from 'zod';

export const Form = (props: IFormProps) => {
  const { id, children, defaultValues, schema, onSubmit } = props;

  const methods = useForm<zod.ZodType<typeof schema>>({
    defaultValues,
    resolver: schema && zodResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, register } = methods;

  return (
    <FormProvider {...methods}>
      <form id={id} onSubmit={onSubmit && handleSubmit(onSubmit)}>
        {Array.isArray(children)
          ? children.map((child) => {
              return child.props.name
                ? createElement(child.type, {
                    ...{
                      ...child.props,
                      register,
                      key: child.props.name,
                    },
                  })
                : child;
            })
          : children}
      </form>
    </FormProvider>
  );
};

interface IFormProps {
  id?: string;
  children: ReactNode;
  defaultValues?: DeepPartial<FieldValues>;
  schema?: zod.ZodSchema;
  //FIXME: fix type here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit?: (data: any) => void;
}
