import {
  Checkbox as AriakitCheckbox,
  CheckboxProps,
  VisuallyHidden,
} from '@ariakit/react';
import type { ReactNode } from 'react';
import { forwardRef, useState } from 'react';

import { Container } from '@/atoms/Container';
import { IReactBaseProps } from '@/interfaces/react';

export const Item = forwardRef<HTMLInputElement, ICheckboxProps>(function Item(
  props: ICheckboxProps,
  ref
) {
  const { variant, ...rest } = props;
  const [checked, setChecked] = useState(props.defaultChecked ?? false);
  const [focusVisible, setFocusVisible] = useState(false);
  return (
    <label
      style={{
        cursor: 'pointer',
        userSelect: 'none',
      }}
      data-checked={checked}
      data-focus-visible={focusVisible || undefined}>
      <VisuallyHidden>
        <AriakitCheckbox
          {...rest}
          ref={ref}
          clickOnEnter
          onFocusVisible={() => setFocusVisible(true)}
          onBlur={() => setFocusVisible(false)}
          onChange={(event) => {
            setChecked(event.target.checked);
            props.onChange?.(event);
          }}
        />
      </VisuallyHidden>
      {variant === 'label' && (
        <CheckboxLabelVariant disabled={props.disabled} checked={!!checked}>
          {props.content}
        </CheckboxLabelVariant>
      )}
    </label>
  );
});

const CheckboxLabelVariant = (props: ICheckboxLabelVariantProps) => {
  const { checked, children, disabled } = props;
  return (
    <Container
      px={2.5}
      py={1.5}
      borderWidth={2}
      borderColor={checked ? 'color-primary-32' : 'color-transparent'}
      // {...(props.checked ? { borderColor: 'color--cold-indicator' } : {})}
      borderRadius='full'
      color={
        checked
          ? 'color-primary-32'
          : disabled
            ? 'color-primary-64'
            : 'color-primary-text-grey-45'
      }
      backgroundColor={'color-primary-97'}
      {...props}
      // FIXME: @siddhant improve styling - also animate border radius
      UNSAFE_STYLES={{
        cursor: disabled ? 'default' : 'pointer',
      }}>
      {children}
    </Container>
  );
};

// TODO;
// const CheckboxIconVariant = (props: ICheckboxIconVariantProps) => {
//   const {checked, children} = props;
//   return (
//     {children}
//   )
// }

// const Checkbo

type ICheckboxProps = CheckboxProps & {
  value: string;
  variant: 'label' | 'icon';
  content: ReactNode;
};

type ICheckboxLabelVariantProps = IReactBaseProps & {
  checked: boolean;
  disabled?: boolean;
};

// type ICheckboxIconVariantProps = {
//   checked: boolean;
// } & IReactBaseProps;
