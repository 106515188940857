import { TABLE_CONFIG } from '@/molecules/Table/config';
import { getAuthTokenLocal } from '@/utils/localStorage';

import { ITableMain } from '.';

// FIXME: define type
export async function fetchRows<TRowData, TFetchData, TCountData>(
  startRow: number,
  filters: Record<string, string>,
  apiConfig: ITableMain<TRowData, TFetchData, TCountData>['apiConfig']
) {
  const url = new URL(apiConfig.baseApi);
  const qparams = {
    pageCount: `${TABLE_CONFIG.PAGINATION_PAGE_SIZE}`,
    pageNumber: (startRow / 10 + 1).toString(),
    // FIXME: filters can be added here directly. no need to send them in getQParams
    ...apiConfig.getQParams(filters),
  };
  url.search = new URLSearchParams(qparams).toString();
  //FIXME:  Error handling, retry option
  const resp = await fetch(url, {
    headers: {
      'x-auth-token': getAuthTokenLocal(),
    },
  });

  const respJson: TFetchData = await resp.json();
  return apiConfig.selectData(respJson);
}
