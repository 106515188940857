import { useDispatch } from 'react-redux';

import { IToast } from '@/booking/components/common/toast/Toast';
import { addToast } from '@/slices/toasts';

const useToast = () => {
  const dispatch = useDispatch();

  const addToastHook = (props: IToast) => {
    const { type, primaryMessage, secondaryMessage, timeout, delay } = props;
    dispatch(
      addToast({
        type,
        primaryMessage,
        secondaryMessage,
        timeout,
        delay,
      })
    );
  };

  return [addToastHook];
};

export default useToast;
