import type { IUser } from '@/interfaces';

const authTokenLocalKey = 'AUTH-TOKEN';
const telecallerUser = 'TELECALLER-USER';

export const setAuthTokenLocal = (token: string) => {
  localStorage.setItem(authTokenLocalKey, token);
};

export const getAuthTokenLocal = (): string => {
  const token = localStorage.getItem(authTokenLocalKey) as string;
  return token;
};

export const removeAuthTokenLocal = () => {
  localStorage.removeItem(authTokenLocalKey);
};

export const setTeleCallerUserLocal = (user: IUser) => {
  localStorage.setItem(telecallerUser, JSON.stringify(user));
};

export const getTeleCallerUserLocal = (): IUser => {
  const token = localStorage.getItem(telecallerUser) as string;
  return JSON.parse(token);
};

export const removeTeleCallerUserLocal = () => {
  localStorage.removeItem(telecallerUser);
};
