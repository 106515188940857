import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { IApplicant } from '@/booking/interfaces';
import { baseUrl } from '@/constants/urls';
import type { IUnitCart } from '@/cp/interfaces';
import type { IResponseBase, TObjectId } from '@/interfaces';
import { getAuthTokenLocal } from '@/utils/localStorage';

export const unitCartAPISlice = createApi({
  reducerPath: 'unitCartsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      headers.set('x-auth-token', getAuthTokenLocal());
      return headers;
    },
  }),
  tagTypes: ['UnitCarts'],
  endpoints: (build) => ({
    getUnitCartList: build.query({
      query: ({ userId, projectId }) =>
        `api/units/unitCart/userId?userId=${userId}&projectId=${projectId}`,
      transformResponse: (response: ITransformUnitCartsResponse) => {
        return response.data;
      },

      // Provides a list of `UnitCarts` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `UnitCarts` element was added.

      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'UnitCarts', id })),
      //         { type: 'UnitCarts', id: 'LIST' },
      //       ]
      //     : [{ type: 'UnitCarts', id: 'LIST' }],
      keepUnusedDataFor: 0,
    }),

    getUnitCart: build.query({
      query: ({ unit_id, user_id }) => {
        return `api/units/unitCart?user=${user_id}&unit=${unit_id}`;
      },
      transformResponse: (response: ITransformUnitCartResponse) => {
        return response.data;
      },
      keepUnusedDataFor: 0,
    }),

    getUnitCartByUnitAndUser: build.mutation({
      query: ({ unit_id, user_id }) => {
        return {
          url: `api/units/unitCart?user=${user_id}&unit=${unit_id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ITransformUnitCartResponse) => {
        return response.data;
      },
    }),

    getUnitCartById: build.mutation({
      query: ({ unitCartId }) => {
        return {
          url: `api/units/unitCart/ById?unitCartId=${unitCartId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ITransformUnitCartResponse) => {
        return response.data;
      },
    }),

    postUnitCart: build.mutation({
      query: (body = {}) => {
        return {
          url: 'api/units/unitCart',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: [{ type: 'UnitCarts', id: 'LIST' }],
    }),

    updateUnitCart: build.mutation<{ data: IUnitCart }, IUpdateUnitCartQuery>({
      query: ({ id, ...body }) => {
        return {
          url: `api/units/unitCart?id=${id}`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'UnitCarts', id }],
    }),

    deleteUnitCart: build.mutation({
      query: (id) => {
        return {
          url: `delete_unit_cart?unit_cart_id=${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => {
        return [{ type: 'UnitCarts', id }];
      },
    }),
  }),
});

interface ITransformUnitCartResponse extends IResponseBase {
  data: IUnitCart;
}

interface ITransformUnitCartsResponse extends IResponseBase {
  data: IUnitCart[];
}

interface IUpdateUnitCartQuery {
  id?: TObjectId;
  selectedCostSheetId?: TObjectId;
  selectedSchemeId?: TObjectId;
  eoi?: TObjectId;
  unit?: TObjectId;
  user?: TObjectId;
  paymentOrders?: (string | undefined)[];
  progress?: number;
  financingDetails?: {
    accountName: string;
    accountNumber: string;
    ifscCode: string;
    city: string;
    bankBranchDetails: string;
    sanctionLetterDocUrl: string;
  };
  applicants?: IApplicant[];
  count?: {
    incrementReserveTimeExpiry: number;
  };
  expiredOn?: Date;
}

export const {
  // TODO: Create getUnitCartById
  useGetUnitCartQuery,
  useLazyGetUnitCartQuery,

  useGetUnitCartByUnitAndUserMutation,

  useGetUnitCartByIdMutation,

  useGetUnitCartListQuery,
  useLazyGetUnitCartListQuery,

  usePostUnitCartMutation,

  useUpdateUnitCartMutation,

  useDeleteUnitCartMutation,
} = unitCartAPISlice;
