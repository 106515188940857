import { createSlice } from '@reduxjs/toolkit';

interface AppState {
  adScreenVisibility: boolean;
}

export const cpAdScreenSlice = createSlice({
  name: 'cpAdScreenSlice',
  initialState: {
    adScreenVisibility: true,
  },
  reducers: {
    setAdScreenVisibility: (state, action) => {
      state.adScreenVisibility = action.payload;
    },
  },
});

export const { setAdScreenVisibility } = cpAdScreenSlice.actions;
export const getAdScreenVisibility = (state: { cpAdScreenSlice: AppState }) =>
  state.cpAdScreenSlice.adScreenVisibility;
