import { ColDef } from 'ag-grid-community';

export const DEFAULT_COLUMN_DEF: ColDef = {
  sortable: false,
  resizable: false,
  suppressMenu: true,
  editable: false,
  flex: 1,
};

/**
 * Not for Now but maybe in future
 * For ultimate goal - make this config extensible
 * all these configurations should be allowed from parent.
 * if (rowModelType is clientSide) -> then fetch should work accordingly
 *
 * pagination/infinite-scroll should also work based on props
 */
export const TABLE_CONFIG = {
  DEFAULT_START_ROW: 0,
  PAGINATION_PAGE_SIZE: 10,
  CACHE_BLOCK_SIZE: 10,
  MAX_BLOCKS_IN_CACHE: 2,
  ROW_MODEL_TYPE: 'serverSide',
  SERVER_SIDE_STORETYPE: 'partial',
} as const;

export const NO_NEXT_ROW = -1 as const;
