import { createSlice } from '@reduxjs/toolkit';
import type { IUnit } from 'src/interfaces/index'; // TODO: use absolute  path

import type { IEOI } from '@/admin/interfaces';

interface IState {
  unitList: IUnit[];
  eoiList: IEOI[];
}

const initialState: IState = {
  unitList: [],
  eoiList: [],
};

const sliceName = 'projectAdminSlice';

export interface IProjectAdminState {
  [sliceName]: IState;
}

export const projectAdminSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setUnitList: (state, action) => {
      state.unitList = action.payload;
    },
    setEoiList: (state, action) => {
      state.eoiList = action.payload;
    },
  },
});

export const { setUnitList, setEoiList } = projectAdminSlice.actions;
export const getUnitList = (state: IProjectAdminState) =>
  state.projectAdminSlice.unitList;
export const getEoiList = (state: IProjectAdminState) =>
  state.projectAdminSlice.eoiList;
