import StatusIndicator from '@/admin/components/common/StatusIndicator';
import type { Colors } from '@/atoms/atoms';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Icon } from '@/atoms/Icon';
import Separator from '@/atoms/Separator';
import { Text } from '@/atoms/Text/Text';
import Modal from '@/booking/components/common/modal/ModalTS';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

import styles from './styles.module.css';

interface IModalWrapperProps {
  children: React.ReactNode;
  modalTitle: string;
  subtitle: string;
  containerWidth?: 'half' | 'quarter' | 'fullWidth';
  //? Can we remove fullWidth and handle it with mobileModal boolean for mobile
  status?: {
    label: string;
    variant: Colors;
  };
  onClose: () => void;
  mobileModal?: boolean;
}

export const ModalWrapper = (props: IModalWrapperProps) => {
  const {
    children,
    modalTitle,
    subtitle,
    onClose,
    containerWidth = 'half',
    status = {
      label: '',
      variant: 'color-primary-64',
    },
    mobileModal = false,
  } = props;

  return (
    <Modal
      additionalClasses={`${styles.modelWrap} ${styles[containerWidth]}`}
      onOutsideClick={onClose}>
      <FlexDeprecated
        flexRow
        justifyContent='space-between'
        fullFlexWidth={false}
        alignItems='start'>
        <FlexDeprecated
          flexColumn
          spacing={0.5}
          fullFlexWidth={false}
          flexBasis={85}>
          <Text
            text={modalTitle}
            size={mobileModal ? 'lg' : 'xl'}
            weight='bold'
            color='color-primary-32'
          />
          <FlexDeprecated
            flexRow
            alignItems='center'
            spacing={0.5}
            fullFlexWidth={false}>
            <ConditionalRendering showIf={status?.label}>
              <>
                <StatusIndicator
                  variant={status.variant}
                  label={status.label}
                  fontSize='sm'
                />
                <Separator
                  separatorColor='color-primary-text-grey-45'
                  separatorHeight={2}
                  orientation='vertical'
                />
              </>
            </ConditionalRendering>
            <Text
              text={subtitle}
              size={mobileModal ? 'xs' : 'sm'}
              color='color-primary-text-grey-45'
            />
          </FlexDeprecated>
        </FlexDeprecated>
        <Icon name='close' size={mobileModal ? 'sm' : 'md'} onClick={onClose} />
      </FlexDeprecated>

      <Separator lineStyle='solid' separatorColor='color-primary-90' />

      <div className={styles.contentWrapper}>{children}</div>
      <span className={styles.fixedBottom} />
    </Modal>
  );
};
