import { useFormContext } from 'react-hook-form';

import { Clickable } from '@/atoms/Clickable';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { FormTextInput } from '@/atoms/Inputs';
import { Text } from '@/atoms/Text/Text';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

import { IInputProps } from './index';

export const Input = (props: IInputProps) => {
  const { state, setState, onFilterSelect, setFilterState, filterConfig } =
    props;

  const { setValue, watch } = useFormContext();

  function z() {
    const newState = structuredClone(state);
    delete newState?.text;
    setState(newState);
    setFilterState(newState);
    onFilterSelect?.(newState);

    const filter = watch('filter-checkbox');
    const removeVal = filter.filter(
      (val: string) => val !== state?.text?.value
    );
    setValue('filter-checkbox', removeVal, {
      shouldValidate: true,
    });
  }

  const filter = watch('filter-checkbox');

  const isTextFilterPresent = !!state?.text?.filter;
  const isPresent = filter?.some((val: string) =>
    filterConfig.find((item) => item.value === val && item.fieldType === 'text')
  );

  return (
    <FlexDeprecated
      UNSAFE_STYLES={{
        visibility: isTextFilterPresent
          ? 'visible'
          : isPresent
            ? 'visible'
            : 'hidden',
        height: isTextFilterPresent ? 'auto' : isPresent ? 'auto' : '0px',
      }}
      width='100%'>
      <FlexDeprecated spacing={1} flexRow={false}>
        <Text
          text={state?.text?.label || ''}
          color='color-primary-text-grey-45'
          weight='bold'
          size='md'
        />
        <Clickable onClick={z}>
          <Text size='sm' color='color-primary-64' text='Remove' />
        </Clickable>
      </FlexDeprecated>
      <ConditionalRendering showIf={isTextFilterPresent && !isPresent}>
        <Text
          color='color-status-error'
          text='Invalid Filter . Please remove and apply new filter'
        />
      </ConditionalRendering>
      <FormTextInput
        disabled={isTextFilterPresent && !isPresent}
        name='search'
        type='text'
        placeHolder={`Type to search in ${state?.text?.label || ''} Field`}
      />
    </FlexDeprecated>
  );
};
