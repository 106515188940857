import { useRef } from 'react';

import Image from '@/apps/booking/components/common/ImageTS';
import {
  AvatarProps,
  AvatarWithIconProps,
  AvatarWithImageProps,
  AvatarWithInitialProps,
} from '@/components/atoms/Avatar/avatarInterfaces';
import { Clickable } from '@/components/atoms/Clickable';
import { ConditionalRendering } from '@/components/atoms/ConditionalRendering';
import { Container } from '@/components/atoms/Container';
import { Icon } from '@/components/atoms/Icon';
import { Text } from '@/components/atoms/Text/Text';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { fallbackAvatarUrl } from '@/constants/urls';
import { useResponsive } from '@/hooks/useResponsive';
import { getCapitalizeInitial } from '@/utils/utils';

import { avatarColorList } from './const';

export const Avatar = (props: AvatarProps) => {
  const typedAvatarProps = props as AvatarWithInitialProps &
    AvatarWithImageProps &
    AvatarWithIconProps;

  const { desktopAndBelow } = useResponsive();

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * avatarColorList.length);
    return avatarColorList[randomIndex];
  };
  const randomColorRef = useRef(getRandomColor());

  const {
    avatarInitial,
    onClick,
    backgroundColor,
    avatarType,
    avatarImageUrl,
    iconName,
    size = desktopAndBelow ? 'sm' : 'md',
  } = typedAvatarProps;

  const sizeToValueMapping = {
    xs: 3,
    sm: 4,
    md: 6,
    lg: 8,
    xl: 10,
  };

  const getAvatarContainerSize = `${sizeToValueMapping[size]}rem`;

  const avatarBg =
    backgroundColor ??
    (!avatarImageUrl ? randomColorRef.current : 'color-primary-100');

  return (
    <Clickable onClick={onClick}>
      <Container
        borderRadius='full'
        backgroundColor={avatarBg}
        width={getAvatarContainerSize}
        height={getAvatarContainerSize}>
        <FlexDeprecated
          spacing={0}
          height='100%'
          alignItems='center'
          justifyContent='center'>
          <ConditionalRendering showIf={avatarType === 'image'}>
            <Image
              src={avatarImageUrl}
              alt='User Profile>'
              fallbackSrc={fallbackAvatarUrl}
              width='100%'
              borderRadius='full'
            />
          </ConditionalRendering>

          <ConditionalRendering showIf={avatarType === 'icon'}>
            <Icon name={iconName} color='color-primary-100' size={size} />
          </ConditionalRendering>

          <ConditionalRendering showIf={avatarType === 'initial'}>
            <Text
              color='color-primary-100'
              weight='bold'
              size={size}
              text={getCapitalizeInitial(avatarInitial)}
            />
          </ConditionalRendering>
        </FlexDeprecated>
      </Container>
    </Clickable>
  );
};
