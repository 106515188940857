import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Icon, IconNames } from '@/atoms/Icon';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

import styles from './styles.module.css';

interface IPrimaryTitleWithDescriptionProps {
  title: string;
  description?: string;
  endIconName?: IconNames;
  type?: 'primary' | 'secondary' | 'tertiary';
  onEndIconClick?: () => void;
}

const PrimaryTitleWithDescription = (
  props: IPrimaryTitleWithDescriptionProps
) => {
  const {
    title,
    description,
    endIconName,
    onEndIconClick,
    type = 'primary',
  } = props;

  return (
    <FlexDeprecated
      justifyContent='space-between'
      alignItems='center'
      fullFlexWidth={false}>
      <div className={`${styles.wrapper} ${styles[type]}`}>
        <p>
          <span className={styles.title}>{title}</span>
          {endIconName && (
            <Icon
              name={endIconName}
              onClick={onEndIconClick}
              propStyles={styles.endIcon}
            />
          )}
        </p>
        <ConditionalRendering showIf={description}>
          <p className={styles.description}>{description}</p>
        </ConditionalRendering>
      </div>
      <div id='clientFilter' />
    </FlexDeprecated>
  );
};

export default PrimaryTitleWithDescription;
