import { GridApi, ServerSideTransaction } from 'ag-grid-community';
import { Ref, useImperativeHandle } from 'react';

import { useGetRefetchCount } from '@/molecules/Table/hooks/RefetchCountContext';
import { IGridHandle } from '@/molecules/Table/Main';

export const useUpdateTableDataUsingRef = <TRowData>(
  // FIXME: @siddhant P1 (is null || undefined good practice ?)
  gridApi: GridApi | null | undefined,
  ref?: Ref<IGridHandle<TRowData>>
) => {
  const refetchCount = useGetRefetchCount();

  useImperativeHandle(
    ref,
    () => {
      return {
        refresh(purge = false) {
          if (!gridApi) {
            throw new Error('gridApi is not defined');
          }
          refetchCount();
          gridApi.refreshServerSide({ purge });
          // setTimeout(() => gridApi.refreshServerSide({ purge }), 0);
        },
        getRowData(rowId: string) {
          if (!gridApi) {
            throw new Error('gridApi is not defined');
          }
          const rowNode = gridApi.getRowNode(rowId);
          if (!rowNode) {
            throw new Error('rowNode is not defined');
          }
          return rowNode.data;
        },
        updateRow(rowId: string, data: TRowData) {
          if (!gridApi) {
            throw new Error('gridApi is not defined');
          }
          gridApi.forEachNode((node) => {
            if (node.data.id === rowId) {
              node.updateData(data);
            }
          });
        },
        deleteRow(rowId: string) {
          if (!gridApi) {
            throw new Error('gridApi is not defined');
          }
          const rowNode = gridApi.getRowNode(rowId);

          const transaction = { remove: [rowNode?.data] };
          gridApi.applyServerSideTransaction(transaction);
          refetchCount();
        },
        addRow(data: TRowData) {
          if (!gridApi) {
            throw new Error('gridApi is not defined');
          }
          const transaction: ServerSideTransaction = {
            add: [data],
            addIndex: 0,
          };
          gridApi.applyServerSideTransaction(transaction);
          refetchCount();
        },
      };
    },
    [gridApi]
  );
};
