import { ICellRendererParams } from 'ag-grid-community';
import { useRef } from 'react';

import AgGridCellText from '@/admin/components/common/AgGridCellText';
import CellModal, {
  ICellModalProps,
} from '@/admin/components/common/CellModal';
import DocsViewerCell, {
  IDocsViewerCellProps,
} from '@/admin/components/common/DocsViewerCell';
import { DEFAULT_NULL_VALUE } from '@/admin/components/common/ExpandedDetailSection';
import PaymentConfirmationCell from '@/admin/components/common/PaymentConfirmationCell';
import PaymentProcessCell from '@/admin/components/common/PaymentProcessCell';
import PaymentRejectionCell from '@/admin/components/common/PaymentRejectionCell';
import {
  IPaymentMoreActionMenuProps,
  PaymentsMoreActionMenu,
} from '@/admin/components/common/PaymentsMoreActionMenu';
import ExpandRowCell from '@/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import { displayEoiStatus } from '@/admin/components/pages/AddAllocation/Lead';
import {
  eoiPaymentsSearchFieldOptions,
  TAB_NAME,
} from '@/admin/components/pages/ProjectEoiPayments';
import {
  hidePaymentConfirmationColumn,
  hidePaymentProcessColumn,
  hidePaymentRejectColumn,
} from '@/admin/components/pages/utils/hidePaymentListColumn';
import { useGetProject } from '@/admin/hooks/useGetProject';
import { ITransformProjectCountResponse } from '@/admin/services/projectsAPISlice';
import { Avatar } from '@/atoms/Avatar';
import { getAvatarColorFromString } from '@/atoms/Avatar/utils/getAvatarColorFromString';
import { Copy } from '@/atoms/Copy';
import { Text } from '@/atoms/Text/Text';
import { NameCard } from '@/booking/components/common/admin/NameCard';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import type { IPaymentOrder } from '@/booking/interfaces';
import { EOI_STATUS } from '@/constants/status';
import { baseUrl } from '@/constants/urls';
import type { IResponseBase, TObjectId } from '@/interfaces';
import { Table } from '@/molecules/Table';
import { IGridHandle, ITableMain } from '@/molecules/Table/Main';
import { DateUtils } from '@/utils/DateUtils';
import { capitalizeFirstAndLowercaseRest, toIndianRupee } from '@/utils/utils';

const PaymentList = (props: IPaymentListProps) => {
  const { tabName, statuses } = props;

  const hidePaymentConfirmation = hidePaymentConfirmationColumn(tabName);
  const hidePaymentProcess = hidePaymentProcessColumn(tabName);
  const hidePaymentReject = hidePaymentRejectColumn(tabName);

  const { project, isFetching } = useGetProject();
  const ref = useRef<IGridHandle<IPaymentRow>>(null);

  const apiConfig: ITableMain<
    IPaymentRow,
    ITransformEoiPaymentsResponse,
    ITransformProjectCountResponse
  >['apiConfig'] = {
    getQParams: (filterModel) => {
      const paymentLapsedTimeInMinutes = project?.payment?.eoi.durations.lapsed;
      return {
        statuses,
        tabName,
        startDate:
          getStartAndEndDateForLapsedAndPendingTab(
            tabName,
            filterModel.startDate,
            filterModel.endDate,
            paymentLapsedTimeInMinutes
          ).startDate || '',
        endDate:
          getStartAndEndDateForLapsedAndPendingTab(
            tabName,
            filterModel.startDate,
            filterModel.endDate,
            paymentLapsedTimeInMinutes
          ).endDate || '',
        [filterModel.searchField]: filterModel.searchName,
      };
    },
    baseApi: `${baseUrl}/api/payment/eoiPaymentOrders/${project?.id}`,
    selectCount: (data) => {
      return data.data.totalCount;
    },
    selectData: (data) => {
      return data.data.paymentOrders;
    },
  };

  const getEoiStatus = (params: ICellRendererParams) => {
    if (!params.data.eoiStatus) return DEFAULT_NULL_VALUE;
    return displayEoiStatus[params.data.eoiStatus as EOI_STATUS];
  };

  const getDetails = (params: ICellRendererParams<IPaymentRow>) => {
    const { data } = params;
    if (!data) {
      return { details: [] };
    }
    const details = [
      {
        label: 'Email',
        value: data.extendedDetails.email,
      },
      {
        label: 'Executive',
        value: data.extendedDetails.executive,
      },
      {
        label: 'Type',
        value: data.extendedDetails.eoiType,
      },
      {
        label: 'Category',
        value: data.extendedDetails.eoiCategory,
      },
      {
        label: 'EOI Amount',
        value: toIndianRupee(data.extendedDetails.eoiAmount),
      },
      {
        label: 'Amount Received',
        value: toIndianRupee(data.extendedDetails.totalAmountPaid),
      },
      {
        label: 'Amount Due',
        value: toIndianRupee(data.extendedDetails.amountDue),
      },
    ];
    return { details };
  };

  const handleUpdatePaymentRow = (paymentId: TObjectId) => {
    ref.current?.deleteRow(paymentId);
  };

  if (!project || isFetching) {
    return null;
  }

  return (
    <>
      <Table.Main<
        IPaymentRow,
        ITransformEoiPaymentsResponse,
        ITransformProjectCountResponse
      >
        ref={ref}
        apiConfig={apiConfig}
        filterConfig={eoiPaymentsSearchFieldOptions}
        detailCellRendererParams={getDetails}>
        <Table.Column
          suppressMovable
          flex={0.8}
          cellClass='ag-grid-column-custom-cell'
          cellRenderer={ExpandRowCell}
          cellRendererParams={(params: ICellRendererParams) => params}
        />
        <Table.Column
          suppressMovable
          headerName='Lead Details'
          headerTooltip='Lead Details'
          field='leadName'
          filter='agTextColumnFilter'
          flex={2.5}
          cellClass='ag-cell-no-text-overflow ag-cell-wrap-text relata-ag-cell-word-break'
          cellRenderer={(params: ICellRendererParams) => {
            const avatarColor = getAvatarColorFromString(params.data.leadName);
            return (
              <NameCard
                disableCopyToClipboard
                title={params.data.leadName}
                subtitle={`${
                  params.data.leadLqrPins.join(',') || DEFAULT_NULL_VALUE
                } | +91 ***** ***${params.data.leadMobile.substring(8)}`}
                avatar={
                  <Avatar
                    avatarInitial={params.data.leadName}
                    avatarType='initial'
                    backgroundColor={avatarColor}
                  />
                }
              />
            );
          }}
        />
        <Table.Column
          suppressMovable
          headerName='EOI Status'
          headerTooltip='EOI Status'
          field='eoiStatus'
          filter='agTextColumnFilter'
          flex={1}
          cellRenderer={(params: ICellRendererParams) => (
            <AgGridCellText
              cellText={capitalizeFirstAndLowercaseRest(getEoiStatus(params))}
            />
          )}
        />
        <Table.Column
          suppressMovable
          headerName='Mode'
          headerTooltip='Mode'
          field='mode'
          flex={1.5}
          cellClass='ag-cell-no-text-overflow ag-cell-wrap-text relata-ag-cell-word-break'
          cellRenderer={(params: ICellRendererParams) => (
            <AgGridCellText cellText={params.data.mode} />
          )}
        />
        <Table.Column
          suppressMovable
          headerName='Amount Paid'
          headerTooltip='Amount Paid'
          flex={1.5}
          cellClass='ag-cell-no-text-overflow ag-cell-wrap-text relata-ag-cell-word-break'
          cellRenderer={(params: ICellRendererParams) => (
            <AgGridCellText cellText={params.data.amount} />
          )}
        />
        <Table.Column
          suppressMovable
          flex={1.2}
          cellClass='ag-cell-no-text-overflow ag-cell-wrap-text relata-ag-cell-word-break'
          headerName='Paid On'
          headerTooltip='Paid On'
          cellRenderer={(params: ICellRendererParams) => (
            <AgGridCellText
              cellText={
                params.data.paidOn &&
                DateUtils.format(
                  params.data.paidOn,
                  DateUtils.FormatTo.LONG_DATE
                )
              }
            />
          )}
        />
        <Table.Column
          suppressMovable
          flex={1.5}
          headerName='Transaction Id'
          headerTooltip='Transaction Id'
          field='transactionId'
          filter='agTextColumnFilter'
          cellClass='relata-ag-container-full-width'
          cellRenderer={(params: ICellRendererParams) => (
            <Copy fullWidth>
              <Text
                text={params.data.transactionId}
                color='color-primary-text-grey-45'
                weight='semibold'
                ellipsis
              />
            </Copy>
          )}
        />

        <Table.Column
          suppressMovable
          hide={hidePaymentConfirmation}
          flex={1.2}
          headerName='Confirm'
          cellClass='relata-ag-grid-cell-justify-center'
          cellRenderer={CellModal}
          cellRendererParams={(
            params: ICellRendererParams
          ): ICellModalProps => ({
            children: (
              <PaymentConfirmationCell
                paymentOrder={params.data}
                project={project}
                onPaymentUpdate={() => handleUpdatePaymentRow(params.data.id)}
              />
            ),
            buttonText: 'Confirm',
            buttonBeforeIcon: 'done',
            modalTitle: 'Add Payment to Completed Tab',
            modalSubTitle:
              'Clicking on Submit button moves record to Completed tab. For more details, attach supporting document/screenshot.',
            buttonAccent: 'bordered',
            containerWidth: 'quarter',
          })}
        />

        <Table.Column
          suppressMovable
          hide={hidePaymentProcess}
          flex={1.2}
          headerName='Process'
          cellClass='relata-ag-grid-cell-justify-center'
          cellRenderer={CellModal}
          cellRendererParams={(
            params: ICellRendererParams
          ): ICellModalProps => ({
            children: (
              <PaymentProcessCell
                paymentOrder={params.data}
                project={project}
                onPaymentUpdate={() => handleUpdatePaymentRow(params.data.id)}
              />
            ),
            buttonText: 'Process',
            buttonBeforeIcon: 'access_time',
            modalTitle: 'Add Payment to Pending Tab',
            modalSubTitle:
              'Clicking on Submit button moves record to Pending tab. For more details, attach supporting document/screenshot.',
            buttonAccent: 'bordered',
            containerWidth: 'quarter',
          })}
        />

        <Table.Column
          suppressMovable
          hide={hidePaymentReject}
          flex={1.2}
          headerName='Reject'
          cellClass='relata-ag-grid-cell-justify-center'
          cellRenderer={CellModal}
          cellRendererParams={(
            params: ICellRendererParams
          ): ICellModalProps => ({
            children: (
              <PaymentRejectionCell
                paymentOrder={params.data}
                project={project}
                onPaymentUpdate={() => handleUpdatePaymentRow(params.data.id)}
              />
            ),
            buttonText: 'Reject',
            buttonBeforeIcon: 'block',
            modalTitle: 'Add Payment to Failed Tab',
            modalSubTitle:
              'Clicking on Submit button moves record to Failed tab. For more details, attach supporting document/screenshot..',
            buttonAccent: 'bordered',
            containerWidth: 'quarter',
          })}
        />

        <Table.Column
          suppressMovable
          headerName='Attachments'
          headerTooltip='Attachments'
          cellClass='relata-ag-grid-cell-justify-center'
          flex={1}
          cellRenderer={DocsViewerCell}
          cellRendererParams={(
            params: ICellRendererParams
          ): IDocsViewerCellProps => {
            return {
              docUrls: params.data.receiptsUrl,
              leadName: params.data.leadName,
              createdAt: params.data.paidOn,
            };
          }}
        />
        <Table.Column
          suppressMovable
          headerName='More'
          headerTooltip='More'
          flex={0.8}
          cellRenderer={PaymentsMoreActionMenu}
          cellRendererParams={(
            params: ICellRendererParams
          ): IPaymentMoreActionMenuProps => {
            return {
              paymentOrders: params.data.extendedDetails.paymentOrders,
              leadName: params.data.leadName,
            };
          }}
        />
        <Table.Column
          suppressMovable
          hide
          field='leadMobile'
          filter='agTextColumnFilter'
        />
        <Table.Column
          suppressMovable
          hide
          field='extendedDetails.email'
          filter='agTextColumnFilter'
        />
        <Table.Column
          suppressMovable
          hide
          field='extendedDetails.executive'
          filter='agTextColumnFilter'
        />
        <Table.Column
          suppressMovable
          hide
          field='extendedDetails.eoiCategory'
          filter='agTextColumnFilter'
        />
        <Table.Column
          suppressMovable
          hide
          field='extendedDetails.eoiType'
          filter='agTextColumnFilter'
        />
        <Table.Column
          suppressMovable
          hide
          field='status'
          filter='agTextColumnFilter'
        />
        <Table.Column
          suppressMovable
          hide
          field='leadLqrPins'
          filter='agTextColumnFilter'
        />
        <Table.Column
          suppressMovable
          hide
          field='paidOn'
          filter='agDateColumnFilter'
        />
        <Table.Column
          suppressMovable
          hide
          field='extendedDetails.paymentOrders.failureReason'
          filter='agTextColumnFilter'
        />
      </Table.Main>
    </>
  );
};

export default PaymentList;

export interface IPaymentRow {
  id: TObjectId;
  leadName: string;
  leadMobile: string;
  leadLqrPin: string | null;
  leadStatus: string;
  eoiStatus: string;
  amount: number;
  paidOn: string;
  mode: string;
  transactionId: string;
  receiptsUrl: string;
  paymentOrder: IPaymentOrder;
  extendedDetails: {
    email: string;
    executive: string;
    eoiType: string;
    eoiCategory: string;
    eoiAmount: number;
    totalAmountPaid: number;
    amountDue: number;
    paymentOrders: IPaymentOrder[];
  };
}

interface IPaymentListProps {
  tabName: TAB_NAME;
  statuses: PAYMENT_ORDER_STATUS[];
}

interface ITransformEoiPaymentsResponse extends IResponseBase {
  data: { paymentOrders: IPaymentRow[] };
}

export const getStartAndEndDateForLapsedAndPendingTab = (
  tabName: TAB_NAME,
  filteredStartDate: string,
  filteredEndDate: string,
  paymentLapsedTimeInMinutes?: number
) => {
  let startDate = filteredStartDate;
  let endDate = filteredEndDate;

  if (paymentLapsedTimeInMinutes) {
    const paymentsLapsedPoint = new Date();
    paymentsLapsedPoint.setMinutes(
      paymentsLapsedPoint.getMinutes() - paymentLapsedTimeInMinutes
    );

    if (tabName === TAB_NAME.Pending) {
      if (!startDate || paymentsLapsedPoint > new Date(startDate)) {
        startDate = paymentsLapsedPoint.toISOString();
        if (new Date(filteredStartDate) > new Date(startDate)) {
          startDate = filteredStartDate;
        }
      }
    }

    if (tabName === TAB_NAME.Lapsed) {
      if (!endDate || paymentsLapsedPoint < new Date(endDate)) {
        endDate = paymentsLapsedPoint.toISOString();
        if (new Date(filteredEndDate) < new Date(endDate)) {
          endDate = filteredEndDate;
        }
      }
    }
  }

  return {
    startDate,
    endDate,
  };
};
