import type { TObjectId, IProject, IResponseBase } from '@/interfaces';
import { cpAPISlice } from '@/cp/services';
import { ICpOnBoardingReq } from '@/cp/components/common/RegistrationConfirmation';
import type { ITransformProjectsResponse } from '@/interfaces/services';

export const cpProjectAPISlice = cpAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getClubRhodiumProjects: build.query<IProject[], any>({
      query: (clubRhodiumCompanyId: string) =>
        `api/projects/club-rhodium/${clubRhodiumCompanyId}`,
      transformResponse: (response: ITransformProjectsResponse) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),

    updateCollateralShareCountAPI: build.mutation({
      query: ({
        projectId,
        cpUserId,
        collateralId,
      }: {
        projectId: TObjectId;
        cpUserId: TObjectId;
        collateralId: TObjectId;
      }) => ({
        url: `api/analytics/raCollaterals/`,
        method: 'POST',
        body: { projectId, cpUserId, collateralId },
      }),
    }),

    updateLocationShareCountAPI: build.mutation({
      query: ({
        projectId,
        cpUserId,
      }: {
        projectId: TObjectId;
        cpUserId: TObjectId;
      }) => ({
        url: `api/analytics/raCollaterals/location/`,
        method: 'POST',
        body: { projectId, cpUserId },
      }),
    }),

    registerCpOnboarding: build.mutation({
      query: (payload: ICpOnBoardingReq) => ({
        url: 'api/analytics/cponboarding',
        method: 'POST',
        body: payload,
      }),
    }),

    getProjectExecutiveIsValid: build.query({
      query: ({
        projectId,
        executiveId,
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
      }) => `api/projects/${projectId}/executive-exists/${executiveId}`,
      transformResponse: (response: ITransformProjectExecutiveResponse) => {
        return response.data;
      },
    }),

    getCpLeadDetails: build.mutation({
      query: ({
        userId,
        projectId,
        mobile,
      }: {
        userId: TObjectId;
        projectId: TObjectId;
        mobile: string;
      }) => ({
        url: `api/users/cp/cp-lead/${userId}/${projectId}/${mobile}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

interface ITransformProjectExecutiveResponse extends IResponseBase {
  data: string;
}

export const {
  useGetClubRhodiumProjectsQuery,

  useUpdateCollateralShareCountAPIMutation,
  useUpdateLocationShareCountAPIMutation,

  useRegisterCpOnboardingMutation,

  useGetProjectExecutiveIsValidQuery,

  useGetCpLeadDetailsMutation,
} = cpProjectAPISlice;
