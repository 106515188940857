import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import {
  FixedBottomContainer,
  RowContainer,
} from '@/admin/components/common/Containers';
import { shouldBlock } from '@/admin/components/pages/CMDashboard/pageBodies/MainPageBody/BookingPaymentForm';
import { paymentModeOptions } from '@/admin/components/pages/CMDashboard/pageBodies/MainPageBody/components/PaymentForm';
import { useUpdateUnitCartMutation } from '@/api/unitCart';
import { Button } from '@/atoms/Button';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import DropDownInput, {
  DropDownOptionType,
} from '@/booking/components/common/inputs/DropDownInput';
import FileUploadNew from '@/booking/components/common/inputs/FileUploadNew';
import TextInput from '@/booking/components/common/inputs/TextInput';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import type { INote, IPaymentOrder } from '@/booking/interfaces';
import { useCreateOrUpdatePaymentOrderMutation } from '@/booking/services/paymentsAPISlice';
import { useBlockUnitByUnitCartIdMutation } from '@/booking/services/unitsAPISlice';
import { setUnitCart } from '@/booking/slices/selectedUnit';
import { SUCCESS } from '@/constants/status';
import useToast from '@/hooks/useToast';
import type { IErrorResponse, IProject, TObjectId } from '@/interfaces';
import { DateUtils } from '@/utils/DateUtils';
import { randomId } from '@/utils/utils';

import styles from './styles.module.css';

const UnitCartPaymentAddCell = (props: IUnitCartPaymentAddCellProps) => {
  const {
    unitCartId,
    unitId,
    userId,
    paymentOrders,
    project,
    onPaymentAdd,
    isBookingAmount,
    closeModal,
  } = props;

  const [showComment, setShowComment] = useState(false);
  const [mode, setMode] = useState<string>('');
  const [receiptsUrl, setReceiptsUrl] = useState<string[]>([]);
  const [notes, setNotes] = useState<INote[]>([]);

  const [createOrUpdatePaymentOrderAPI, { isLoading: isPaymentAdding }] =
    useCreateOrUpdatePaymentOrderMutation();
  const [updateUnitCartAPI, { isLoading: isUnitCartUpdating }] =
    useUpdateUnitCartMutation();
  const [blockUnitByUnitCartIdAPI] = useBlockUnitByUnitCartIdMutation();

  const [addToast] = useToast();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    unregister,
    setValue,
    formState: { errors, submitCount },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const isSubmitBtnLoading = isPaymentAdding || isUnitCartUpdating;
  const isReceiptUrlEmpty = !receiptsUrl?.length && submitCount > 0;

  const handleToggleComment = () => {
    setShowComment(!showComment);
  };

  const handleDropDownSelection = (value: DropDownOptionType<string>[]) => {
    setMode(value[0]?.value);
  };

  const handleAddPaymentSlips = (imageUrls: string[]) => {
    const urls = [...receiptsUrl, ...imageUrls];
    setReceiptsUrl(urls);
  };

  const handleRemovePaymentSlips = (imageUrl: string) => {
    const urls = receiptsUrl?.filter((image) => image !== imageUrl);
    setReceiptsUrl(urls);
  };

  const handleNoteChange = (value: string) => {
    const updatedNotes: INote[] = [{ name: 'comment', value: value }];
    setNotes(updatedNotes);
  };

  useEffect(() => {
    setValue('receiptsUrl', receiptsUrl);
  }, [receiptsUrl]);

  const handlePaymentConfirm = async (paymentData: IPaymentFormData) => {
    try {
      const { data: paymentOrder } = await createOrUpdatePaymentOrderAPI({
        unitCart: unitCartId,
        status: PAYMENT_ORDER_STATUS.INITIATED,
        transactionId: paymentData.transactionId,
        mode,
        amount: paymentData.amount,
        notes,
        settledAt: paymentData.settledAt,
        receiptsUrl,
      }).unwrap();

      const newPaymentOrders = [...paymentOrders, paymentOrder];
      const unitCartBody = {
        id: unitCartId,
        paymentOrders: newPaymentOrders.map((order) => order.id),
        unit: unitId,
        user: userId,
      };
      const { data: unitCartData } =
        await updateUnitCartAPI(unitCartBody).unwrap();

      if (shouldBlock(unitCartData, project)) {
        await blockUnitByUnitCartIdAPI(unitCartData.id).unwrap();
      }

      addToast({
        type: SUCCESS,
        primaryMessage: 'Payment added successfully',
        timeout: 1000,
      });

      dispatch(setUnitCart(unitCartData));
      closeModal?.();
      onPaymentAdd();
    } catch (error) {
      const errorMessage = (error as IErrorResponse)?.data?.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errorMessage,
        timeout: 1000,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handlePaymentConfirm)}>
      <div className={styles.contentWrapper}>
        <ConditionalRendering showIf={false}>
          <RowContainer>
            <TextInput
              type='text'
              defaultValue='10000'
              setValue={setValue}
              label='Due Token Amount'
              placeHolder=''
              readOnly
              register={register}
              unregister={unregister}
              name='grossDiscount'
              startIconName='currency_rupee'
            />
            <TextInput
              type='text'
              defaultValue='20000'
              setValue={setValue}
              label='Paid Token Amount'
              readOnly
              register={register}
              unregister={unregister}
              name='grossDiscount'
              startIconName='currency_rupee'
              placeHolder=''
            />
          </RowContainer>
        </ConditionalRendering>
        <RowContainer>
          <DropDownInput
            options={paymentModeOptions}
            lable='Payment Mode'
            onValueSelected={handleDropDownSelection}
          />
        </RowContainer>
        <RowContainer>
          <TextInput
            label='Date of Settlement'
            type='date'
            placeHolder='Enter Date'
            name='settledAt'
            register={register}
            setValue={setValue}
            unregister={unregister}
            errorMessage={errors.settledAt && 'Date of Settlement is required'}
          />
          <TextInput
            name='amount'
            label='Amount'
            type='number'
            placeHolder='Enter amount'
            startIconName='currency_rupee'
            register={register}
            setValue={setValue}
            unregister={unregister}
            errorMessage={errors.amount && 'Amount is required'}
          />
        </RowContainer>
        <RowContainer>
          <TextInput
            label='Transaction ID'
            type='text'
            placeHolder='Enter Transaction ID'
            name='transactionId'
            register={register}
            setValue={setValue}
            unregister={unregister}
            errorMessage={errors.transactionId && 'Transaction Id is required'}
          />
          <TextInput
            label='Date of Transaction'
            type='date'
            placeHolder='Enter Date'
            name='createdAt'
            defaultValue={DateUtils.format(
              DateUtils.currentDate(),
              DateUtils.FormatTo.YYYY_MM_DD
            )}
            readOnly
          />
        </RowContainer>

        <FileUploadNew
          placeholder='Upload Documents & Images'
          label='Upload Documents'
          s3DirPath={`projects/${project.id}/paymentSlips/${randomId()}`}
          name='receiptsUrl'
          appType='RELATA'
          multiple
          showDisplayInputs
          displayInputs={receiptsUrl}
          onMultipleFileUpload={handleAddPaymentSlips}
          onRemove={handleRemovePaymentSlips}
          isFilePreviewEnabled
          errorMessage={isReceiptUrlEmpty && 'Please upload receipts'}
        />
        <ConditionalRendering showIf={showComment}>
          <TextInput
            name='comment'
            label='Comment'
            type='text'
            placeHolder='Add Comment'
            defaultValue={notes.length ? notes[0].value : ''}
            register={register}
            setValue={setValue}
            unregister={unregister}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleNoteChange(e.target.value)
            }
          />
        </ConditionalRendering>
      </div>
      <FixedBottomContainer>
        <RowContainer>
          <ConditionalRendering showIf={!isBookingAmount}>
            <Button accent='bordered' onClick={handleToggleComment}>
              Add Comment
            </Button>
          </ConditionalRendering>
          <Button accent='primary' type='submit' loading={isSubmitBtnLoading}>
            Submit
          </Button>
        </RowContainer>
      </FixedBottomContainer>
    </form>
  );
};

export default UnitCartPaymentAddCell;

const schema = yup.object().shape({
  transactionId: yup.string().required(),
  amount: yup.number().required(),
  comment: yup.string(),
  settledAt: yup.date().required(),
  receiptsUrl: yup.array().of(yup.string()).min(1),
});

interface IUnitCartPaymentAddCellProps {
  unitCartId: TObjectId;
  unitId: TObjectId;
  userId: TObjectId;
  paymentOrders: IPaymentOrder[];
  project: IProject;
  onPaymentAdd: () => void;
  isBookingAmount?: boolean;
  closeModal?: () => void;
}

interface IPaymentFormData {
  transactionId: string;
  amount: number;
  settledAt: Date;
}
