import { CheckboxProvider, CheckboxProviderProps } from '@ariakit/react';
import { ErrorBoundary } from 'react-error-boundary';
import { Controller, useFormContext } from 'react-hook-form';

import { ErrorFallback } from '@/atoms/ErrorBoundary/ErrorFallback';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { IReactBaseProps } from '@/interfaces/react';

export const Root = (props: IRootProps) => {
  const { name, defaultValue = [] } = props;

  const { control, setValue } = useFormContext();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => {
          return (
            <CheckboxProvider
              {...field}
              setValue={(value) => {
                setValue(name, value);
              }}>
              <FlexDeprecated>{props.children}</FlexDeprecated>
            </CheckboxProvider>
          );
        }}
      />
    </ErrorBoundary>
  );
};

type IRootProps = CheckboxProviderProps &
  IReactBaseProps & {
    name: string;
  };
