import { Colors, FontFamily } from '@/atoms/atoms';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Text } from '@/atoms/Text/Text';
import type { Range } from '@/interfaces/range';

import styles from './styles.module.css';

interface ISeparatorProps {
  lineStyle?: 'solid' | 'dotted' | 'dashed';
  separatorText?: string;
  textPositionFromLeft?: Range<1, 101>;
  separatorColor?: Colors;
  separatorWidth?: Range<1, 101>;
  orientation?: 'horizontal' | 'vertical';
  fontFamily?: FontFamily;
  marginFromTopAndBottom?: Range<0, 101>;
  separatorHeight?: Range<0, 10>;
  marginFromLeftAndRight?: Range<0, 10>;
}

//This component Can be customized to accept any component as child instead of Text

const Separator = (props: ISeparatorProps) => {
  const {
    lineStyle = 'solid',
    separatorText = '',
    textPositionFromLeft = 0,
    separatorColor = 'color-primary-64',
    separatorWidth = 100,
    separatorHeight = 100,
    orientation = 'horizontal',
    fontFamily,
    marginFromTopAndBottom = 0,
    marginFromLeftAndRight = 0,
  } = props;

  const separatorHorizontalStyles = {
    borderStyle: lineStyle,
    borderColor: `var(--${separatorColor})`,
    color: `var(--${separatorColor})`,
    width: `${separatorWidth}%`,
    margin: `${marginFromTopAndBottom}rem ${marginFromLeftAndRight}rem`,
  };

  const separatorVerticalStyles = {
    borderStyle: lineStyle,
    borderColor: `var(--${separatorColor})`,
    color: `var(--${separatorColor})`,
    height: `${separatorHeight}rem`,
    margin: `${marginFromTopAndBottom}rem ${marginFromLeftAndRight}rem`,
  };

  const textStyles = {
    left: `${textPositionFromLeft}%`,
    transform: `translate(-${textPositionFromLeft}%, -50%)`,
  };

  return (
    <div
      className={styles.separatorWrapper}
      style={
        orientation === 'horizontal'
          ? separatorHorizontalStyles
          : separatorVerticalStyles
      }
      data-orientation={orientation}>
      <ConditionalRendering showIf={separatorText}>
        <span className={styles.separatorText} style={textStyles}>
          <Text text={separatorText} size='xs' fontFamily={fontFamily} />
        </span>
      </ConditionalRendering>
    </div>
  );
};

export default Separator;
