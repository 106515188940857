import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  dateExpiryCountDownStateInvalidValue,
  IDateExpiryCountDown,
} from '@/booking/hooks/useDateExpiryCountdown';
import type { IPaymentOrder } from '@/booking/interfaces';
import type { TObjectId } from '@/interfaces';

export enum CMDashboardStates {
  LANDING_PAGE,
  QR_CODE_PAGE,

  // Add all MainPageStates here
  LEAD_PROGRESS,
  LEAD_DETAILS,
  EOI_DETAILS,
  EOI_FORM,
  EOI_PAYMENT_FORM,
  EOI_PAYMENT_SUMMARY,
  FEEDBACK_FORM,
  BOOKING_DETAILS,
  ADD_PAYMENT_PAGE,

  // Booking
  TOWER_SELECTION, // getUnitListByProjectId
  UNIT_SELECTION, // postUnitCart
  UNIT_RESERVE, //reserveUnitByUnitCartId
  UNIT_BLOCK, // blockUnitByUnitCartId
  ADD_APPLICANT,
  BOOKING_PAYMENT_FORM,
  BOOKING_PAYMENT_SUMMARY,
}

export interface IScannedDetails {
  leadId: TObjectId;
  summary: {
    id: TObjectId;
    scannedTimestamp: Date;
    zoneId: TObjectId;
    zoneName: string;
  }[];
}

interface IState {
  cmDashboardState: CMDashboardStates;
  scannedCodeDetails?: IScannedDetails;
  recentPaymentOrder?: IPaymentOrder;
  dateExpiryCountDown: IDateExpiryCountDown; // For selected UnitCart
}

const initialState: IState = {
  cmDashboardState: CMDashboardStates.LANDING_PAGE,
  scannedCodeDetails: undefined,
  recentPaymentOrder: undefined,
  dateExpiryCountDown: dateExpiryCountDownStateInvalidValue,
};

const sliceName = 'cmDashboardSlice';

export interface ISiteVisit2Slice {
  [sliceName]: IState;
}

export const cmDashboardSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCMDashboardState: (state, action: PayloadAction<CMDashboardStates>) => {
      state.cmDashboardState = action.payload;
    },

    setScannedCodeDetails: (state, action: PayloadAction<IScannedDetails>) => {
      state.scannedCodeDetails = action.payload;
    },

    setRecentPaymentOrder: (
      state,
      action: PayloadAction<IPaymentOrder | undefined>
    ) => {
      state.recentPaymentOrder = action.payload;
    },

    setDateExpiryCountDown: (
      state,
      action: PayloadAction<IDateExpiryCountDown>
    ) => {
      state.dateExpiryCountDown = action.payload;
    },
  },
});

export const {
  setCMDashboardState,

  setScannedCodeDetails,

  setRecentPaymentOrder,

  setDateExpiryCountDown,
} = cmDashboardSlice.actions;

export const getCMDashboardState = (state: ISiteVisit2Slice) =>
  state.cmDashboardSlice.cmDashboardState;

export const getScannedCodeDetails = (state: ISiteVisit2Slice) =>
  state.cmDashboardSlice.scannedCodeDetails;

export const getRecentPaymentOrder = (state: ISiteVisit2Slice) =>
  state.cmDashboardSlice.recentPaymentOrder;

export const getDateExpiryCountDown = (state: ISiteVisit2Slice) =>
  state.cmDashboardSlice.dateExpiryCountDown;
