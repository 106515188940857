import { useFormContext } from 'react-hook-form';

import { Clickable } from '@/atoms/Clickable';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Container } from '@/atoms/Container';
import { Date } from '@/atoms/Inputs/Date';
import { Text } from '@/atoms/Text/Text';
import { Icon } from '@/components/atoms/Icon';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { DateUtils } from '@/utils/DateUtils';

import { IInputProps } from './index';

export const DateInput = (props: IInputProps) => {
  const { state, setState, onFilterSelect, setFilterState, filterConfig } =
    props;

  const { watch, setValue } = useFormContext();

  function z() {
    const newState = structuredClone(state);
    delete newState?.date;
    setState(newState);
    setFilterState(newState);
    onFilterSelect?.(newState);

    const filter = watch('filter-checkbox');
    const removeVal = filter.filter(
      (val: string) => val !== state?.date?.value
    );
    setValue('filter-checkbox', removeVal, {
      shouldValidate: true,
    });
  }

  const isDateFilterPresent = !!state?.date?.filter;
  const filter = watch('filter-checkbox');
  const isPresent = filter?.some((val: string) =>
    filterConfig.find((item) => item.value === val && item.fieldType === 'date')
  );

  return (
    <FlexDeprecated
      width='100%'
      UNSAFE_STYLES={{
        visibility: isDateFilterPresent
          ? 'visible'
          : isPresent
            ? 'visible'
            : 'hidden',
        height: isDateFilterPresent ? 'visible' : isPresent ? 'auto' : '0px',
      }}>
      <FlexDeprecated spacing={1} flexRow={false}>
        <Text
          text={state?.date?.label || ''}
          color='color-primary-text-grey-45'
          weight='bold'
          size='md'
        />
        <Clickable onClick={z}>
          <Text size='sm' color='color-primary-64' text='Remove' />
        </Clickable>
      </FlexDeprecated>
      <ConditionalRendering showIf={isDateFilterPresent && !isPresent}>
        <Text
          color='color-status-error'
          text='Invalid Filter . Please remove and apply new filter'
        />
      </ConditionalRendering>
      <FlexDeprecated
        flexRow={false}
        alignItems='center'
        justifyContent='space-between'>
        <FlexDeprecated spacing={1} flexRow={false}>
          <Container
            borderWidth={1}
            borderColor='color-primary-64'
            py={1.5}
            px={2}
            width='180px'
            UNSAFE_STYLES={{ alignText: 'center' }}>
            <Text
              text={`${
                watch('filter-date')?.from
                  ? DateUtils.format(watch('filter-date')?.from, 'dd-MM-yyyy')
                  : 'Select Start Date'
              }`}
            />
          </Container>
          <Container
            borderWidth={1}
            borderColor='color-primary-64'
            py={1.5}
            px={2}
            width='180px'
            UNSAFE_STYLES={{ alignText: 'center' }}>
            <Text
              text={`${
                watch('filter-date')?.to
                  ? DateUtils.format(watch('filter-date')?.to, 'dd-MM-yyyy')
                  : 'Select End Date'
              }`}
            />
          </Container>
        </FlexDeprecated>
        <FlexDeprecated
          backgroundColor='color-primary-white-87'
          alignItems='center'
          justifyContent='center'
          py={2.1}
          px={3}
          borderRadius='md'
          width='50px'
          spacing={0}>
          <Icon name='today' size='sm' />
        </FlexDeprecated>
      </FlexDeprecated>
      <Date disabled={isDateFilterPresent && !isPresent} name='filter-date' />
    </FlexDeprecated>
  );
};
