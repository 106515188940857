import DesktopTabs from '@/admin/components/common/DesktopTabs';
import PrimaryTitleWithDescription from '@/admin/components/common/PrimaryTitleWithDescription';
import { COUNT_WHILE_DATA_IS_LOADING } from '@/admin/constants/helpers';
import { useGetProject } from '@/admin/hooks/useGetProject';
import { usePathParams } from '@/admin/hooks/usePathParams';
import { useGetProjectBookingPaymentsCountQuery } from '@/admin/services/projectsAPISlice';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { ITab } from '@/cp/components/common/Tabs';
import { Filter, TextFilterConfig } from '@/molecules/Filter';
import { useGetFilter } from '@/molecules/Filter/hooks/FilterContext';
import { WithFilter } from '@/molecules/Filter/withFilter';
import { convertToFilterObject } from '@/molecules/Table/utils/convertToFilterObject';

import PaymentList from './PaymentList';

const ProjectBookingPayments = () => {
  const { projectId } = usePathParams();

  const { project, error } = useGetProject();

  if (!projectId || error) {
    throw new Error('Project Id not found');
  }

  const [, , filterModel] = useGetFilter();
  const filterPayload = convertToFilterObject(
    filterModel,
    bookingPaymentsSearchFieldOptions
  );

  const paymentLapsedTimeInMinutes = project?.payment?.booking.durations.lapsed;

  const apiParams = getProjectBookingPaymentsAPIParams(
    projectId,
    filterPayload,
    paymentLapsedTimeInMinutes
  );

  const {
    data,
    isLoading,
    refetch: refetchCount,
  } = useGetProjectBookingPaymentsCountQuery(apiParams);

  const tabs: ITab[] = [
    {
      id: '0',
      name: 'Pending',
      countNumber: !isLoading
        ? data?.[TAB_NAME.Pending]?.data.totalCount
        : COUNT_WHILE_DATA_IS_LOADING,
      content: (
        <PaymentList
          tabName={TAB_NAME.Pending}
          statuses={[
            PAYMENT_ORDER_STATUS.INITIATED,
            PAYMENT_ORDER_STATUS.PROCESSING,
          ]}
        />
      ),
    },
    {
      id: '1',
      name: 'Completed',
      countNumber: !isLoading
        ? data?.[TAB_NAME.Completed]?.data.totalCount
        : COUNT_WHILE_DATA_IS_LOADING,
      content: (
        <PaymentList
          tabName={TAB_NAME.Completed}
          statuses={[PAYMENT_ORDER_STATUS.COMPLETED]}
        />
      ),
    },
    {
      id: '2',
      name: 'Lapsed',
      countNumber: !isLoading
        ? data?.[TAB_NAME.Lapsed]?.data.totalCount
        : COUNT_WHILE_DATA_IS_LOADING,
      content: (
        <PaymentList
          tabName={TAB_NAME.Lapsed}
          statuses={[
            PAYMENT_ORDER_STATUS.INITIATED,
            PAYMENT_ORDER_STATUS.PROCESSING,
          ]}
        />
      ),
    },
    {
      id: '3',
      name: 'Failed',
      countNumber: !isLoading
        ? data?.[TAB_NAME.Failed]?.data.totalCount
        : COUNT_WHILE_DATA_IS_LOADING,
      content: (
        <PaymentList
          tabName={TAB_NAME.Failed}
          statuses={[PAYMENT_ORDER_STATUS.FAILED]}
        />
      ),
    },
    {
      id: '4',
      name: 'All',
      countNumber: !isLoading
        ? data?.[TAB_NAME.All]?.data.totalCount
        : COUNT_WHILE_DATA_IS_LOADING,
      content: (
        <PaymentList
          tabName={TAB_NAME.All}
          statuses={[
            PAYMENT_ORDER_STATUS.INITIATED,
            PAYMENT_ORDER_STATUS.PROCESSING,
            PAYMENT_ORDER_STATUS.COMPLETED,
            PAYMENT_ORDER_STATUS.FAILED,
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <FlexDeprecated justifyContent='space-between' flexRow={false}>
        <PrimaryTitleWithDescription
          type='tertiary'
          title='Explore payments done for Bookings'
        />
        <Filter filterConfig={bookingPaymentsSearchFieldOptions} />
      </FlexDeprecated>
      <DesktopTabs tabs={tabs} refetchCount={refetchCount} />
    </>
  );
};

export default WithFilter(ProjectBookingPayments);

export enum TAB_NAME {
  Pending = 'Pending',
  Completed = 'Completed',
  Lapsed = 'Lapsed',
  Failed = 'Failed',
  All = 'All',
}

export const bookingPaymentsSearchFieldOptions: TextFilterConfig[] = [
  { value: 'leadName', label: 'Lead name', fieldType: 'text' },
  { value: 'leadMobile', label: 'Lead mobile', fieldType: 'text' },
  {
    value: 'extendedDetails.email',
    label: 'Lead email',
    fieldType: 'text',
    renameKeyBeforeSendingNetworkRequest: 'leadEmail',
  },
  { value: 'transactionId', label: 'Transaction Id', fieldType: 'text' },
  {
    value: 'extendedDetails.paymentOrders.failureReason',
    label: 'Failure Reason',
    fieldType: 'text',
    renameKeyBeforeSendingNetworkRequest: 'failureReason',
  },
  {
    value: 'extendedDetails.unitInfo',
    label: 'Type',
    fieldType: 'text',
    renameKeyBeforeSendingNetworkRequest: 'type',
  },
  {
    value: 'paidOn',
    label: 'Paid On',
    fieldType: 'date',
  },
];
function getProjectBookingPaymentsAPIParams(
  projectId: string,
  filterPayload: Record<string, string>,
  paymentLapsedTimeInMinutes?: number
) {
  return [
    {
      projectId,
      statuses: [
        PAYMENT_ORDER_STATUS.INITIATED,
        PAYMENT_ORDER_STATUS.PROCESSING,
      ],
      tabName: TAB_NAME.Pending,
      filterPayload,
      paymentLapsedTimeInMinutes,
    },
    {
      projectId,
      statuses: [PAYMENT_ORDER_STATUS.COMPLETED],
      tabName: TAB_NAME.Completed,
      filterPayload,
      paymentLapsedTimeInMinutes,
    },
    {
      projectId,
      statuses: [
        PAYMENT_ORDER_STATUS.INITIATED,
        PAYMENT_ORDER_STATUS.PROCESSING,
      ],
      tabName: TAB_NAME.Lapsed,
      filterPayload,
      paymentLapsedTimeInMinutes,
    },
    {
      projectId,
      statuses: [PAYMENT_ORDER_STATUS.FAILED],
      tabName: TAB_NAME.Failed,
      filterPayload,
      paymentLapsedTimeInMinutes,
    },
    {
      projectId,
      statuses: [
        PAYMENT_ORDER_STATUS.INITIATED,
        PAYMENT_ORDER_STATUS.PROCESSING,
        PAYMENT_ORDER_STATUS.COMPLETED,
        PAYMENT_ORDER_STATUS.FAILED,
      ],
      tabName: TAB_NAME.All,
      filterPayload,
      paymentLapsedTimeInMinutes,
    },
  ];
}
