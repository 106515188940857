import { useEffect, useRef, useState } from 'react';
import {
  // FIXME: autofix sort issue
  generatePath as GeneratePath,
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import { usePathParams } from '@/admin/hooks/usePathParams';
import { useQueryParams } from '@/admin/hooks/useQueryParams';
import { convertToSlugString } from '@/admin/utils/slugString';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Container } from '@/atoms/Container';
import Gap from '@/atoms/Gap';
import { Text } from '@/atoms/Text/Text';
import Image from '@/booking/components/common/ImageTS';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { awsBaseUrl } from '@/constants/urls';
import { ITab } from '@/cp/components/common/Tabs';
import { RefetchCountContext } from '@/molecules/Table/hooks/RefetchCountContext';

import styles from './styles.module.css';

const DesktopTabsImpl = (props: Omit<IDesktopTabsProps, 'refetchCount'>) => {
  const { tabs } = props;

  const { queryString } = useQueryParams();

  const navLinkRefs = useRef<Array<HTMLAnchorElement | null>>([]);
  const { path, url, params } = useRouteMatch();
  const { tabId } = usePathParams();
  const enabledTabs = tabs.filter((tab) => !tab.disabled);

  if (!enabledTabs.length) {
    return (
      <FlexDeprecated
        alignItems='center'
        justifyContent='center'
        p={3}
        mt={5}
        height='40rem'>
        <Text
          weight='extrabold'
          size='xl'
          text='No tabs available, Please contact Admin'
        />
        <Image src={`${awsBaseUrl}/common/lost-connection.gif`} />
      </FlexDeprecated>
    );
  }
  const firstEnabledTabIndex = tabs.findIndex((tab) => !tab.disabled);
  const defaultTabSlug = convertToSlugString(tabs[firstEnabledTabIndex].name);

  /**
   * // FIXME: @siddhant is this best implementation ??
   * default page number should be 1. remove page when changing tab
   */
  const goToTabQueryStringParams = new URLSearchParams(queryString);
  goToTabQueryStringParams.delete('page');

  return (
    <Container mt={3}>
      <FlexDeprecated
        borderBottomColor='color-primary-64'
        width='100%'
        mb={2.5}
        UNSAFE_CLASSNAMES={styles.tabContainerHeader}
        spacing={1}>
        {tabs.map((tab, index) => (
          <NavLink
            activeClassName={`${styles.activeTab}`}
            className={`${tab.disabled ? styles.disabledTab : ''} ${
              styles.tabItem
            }`}
            to={`${GeneratePath(tabId ? path : `${path}/:tabId`, {
              ...params,
              tabId: convertToSlugString(tab.name),
            })}?${goToTabQueryStringParams}`}
            key={tab.id}
            ref={(navLink) => (navLinkRefs.current[index] = navLink)}>
            <Container>
              {tab.name}
              <ConditionalRendering showIf={(tab.countNumber as number) >= 0}>
                <span className={styles.countText}>
                  <Gap numberOfSpaces={1} />
                  {`(${tab.countNumber})`}
                </span>
              </ConditionalRendering>
            </Container>
          </NavLink>
        ))}
      </FlexDeprecated>

      <Switch>
        <Route path={`${path}/:tabId`}>
          <Content tabs={tabs} />
        </Route>
        <Redirect to={`${url}/${defaultTabSlug}`} />
      </Switch>
    </Container>
  );
};

const Content = (props: Pick<IDesktopTabsProps, 'tabs'>) => {
  const { tabs } = props;
  const { tabId } = usePathParams();

  const DEFAULT_NO_INDEX = -1;

  const [activeLinkIndex, setActiveLinkIndex] =
    useState<number>(DEFAULT_NO_INDEX);
  const enabledTabs = tabs.filter((tab) => !tab.disabled);

  useEffect(() => {
    const activeIndex = enabledTabs.findIndex(
      (tab) => convertToSlugString(tab.name) === tabId
    );
    setActiveLinkIndex(activeIndex);
  }, [enabledTabs, tabId]);

  if (activeLinkIndex === DEFAULT_NO_INDEX) {
    return null;
  }

  const Tab = tabs[activeLinkIndex].content;

  return <div key={tabId}>{Tab}</div>;
};

const DesktopTabs = (props: IDesktopTabsProps) => {
  const { refetchCount = () => {}, ...restProps } = props;
  return (
    <RefetchCountContext.Provider value={refetchCount}>
      <DesktopTabsImpl {...restProps} />
    </RefetchCountContext.Provider>
  );
};

export default DesktopTabs;

interface IDesktopTabsProps {
  tabs: ITab[];
  refetchCount?: () => void;
  propStyles?: string;
}
