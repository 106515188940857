import { useFormContext } from 'react-hook-form';

import TextInput, {
  ITextInputProps,
} from '@/booking/components/common/inputs/TextInput';
import {
  AAdhAAR_NUMBER_LENGTH,
  LQR_PIN_LENGTH,
  MOBILE_LENGTH,
  PAN_NUMBER_LENGTH,
  PINCODE_LENGTH,
} from '@/constants';

import styles from './override.module.css';

export const FormTextInput = (props: ITextInputProps) => {
  const { name, disabled } = props;

  const {
    register,
    unregister,
    getValues,
    formState: { isSubmitting, errors },
  } = useFormContext();

  return (
    <TextInput
      {...register(name as string)}
      unregister={unregister}
      disabled={disabled || isSubmitting}
      errorMessage={errors[name as string]?.message}
      defaultValue={getValues(name as string)}
      propStyles={styles.input}
      {...props}
    />
  );
};

export const LqrInput = (props: ILqrInputProps) => {
  const { required } = props;

  return (
    <FormTextInput
      label={`LQR ${required ? '*' : ''}`}
      placeHolder='Add LQR'
      {...props}
      {...(props.disabled ? { errorMessage: undefined } : {})}
      {...(props.errorMessage ? { errorMessage: props.errorMessage } : {})}
      maxLength={LQR_PIN_LENGTH}
    />
  );
};

export const NameInput = (props: INameProps) => {
  const { label, required, ...rest } = props;

  return (
    <FormTextInput
      label={`${label} ${required ? '*' : ''}`}
      placeHolder={`Add ${label}`}
      {...rest}
    />
  );
};

export const EmailInput = (props: INameProps) => {
  const { label, required, ...rest } = props;

  return (
    <FormTextInput
      label={`${label} ${required ? '*' : ''}`}
      placeHolder={`Add ${label}`}
      type='email'
      {...rest}
    />
  );
};

export const MobileInput = (props: TCommonProps) => {
  const { label, required, ...rest } = props;

  return (
    <FormTextInput
      label={`${label} ${required ? '*' : ''}`}
      placeHolder={`Add ${label}`}
      type='number'
      startIconText='+91'
      {...rest}
      {...(props.disabled ? { errorMessage: undefined } : {})}
      {...(props.errorMessage ? { errorMessage: props.errorMessage } : {})}
      maxLength={MOBILE_LENGTH}
    />
  );
};

export const PincodeInput = (props: TCommonProps) => {
  const { label, required, ...rest } = props;

  return (
    <FormTextInput
      label={`${label} ${required ? '*' : ''}`}
      placeHolder={`Add ${label}`}
      {...rest}
      type='number'
      maxLength={PINCODE_LENGTH}
    />
  );
};
export const AadhaarNumberInput = (props: TCommonProps) => {
  const { label, required, ...rest } = props;

  return (
    <FormTextInput
      label={`${label} ${required ? '*' : ''}`}
      placeHolder={`Add ${label}`}
      {...rest}
      maxLength={AAdhAAR_NUMBER_LENGTH}
    />
  );
};

export const PanNumberInput = (props: TCommonProps) => {
  const { label, required, ...rest } = props;

  return (
    <FormTextInput
      label={`${label} ${required ? '*' : ''}`}
      placeHolder={`Add ${label}`}
      maxLength={PAN_NUMBER_LENGTH}
      {...rest}
    />
  );
};

export const DateInput = (props: TCommonProps) => {
  const { label, required, ...rest } = props;

  return (
    <FormTextInput
      label={`${label} ${required ? '*' : ''}`}
      placeHolder={`Add ${label}`}
      {...rest}
      type='date'
    />
  );
};

export const DateWithTimeInput = (props: TCommonProps) => {
  const { label, required, ...rest } = props;

  return (
    <FormTextInput
      label={`${label} ${required ? '*' : ''}`}
      placeHolder={`Add ${label}`}
      {...rest}
      type='datetime-local'
    />
  );
};

interface ILqrInputProps {
  name: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  defaultValue?: string;
}

interface INameProps extends ILqrInputProps {
  label: string;
  showSecondaryLabel?: boolean;
}

type TCommonProps = INameProps;
