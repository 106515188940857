import { SyntheticEvent, useMemo, useState } from 'react';

import { generateStyles } from '@/components/atoms/common/layout';
import { CommonLayoutProps } from '@/components/atoms/common/layout/layout';
import {
  fallbackImageUrl,
  getCloudFrontUrlFromResourceUrl,
} from '@/constants/urls';
import { useResponsive } from '@/hooks/useResponsive';

const Image = (props: IImageProps) => {
  const {
    src,
    fallbackSrc = fallbackImageUrl,
    alt = 'Image',
    imgClass = '',
    containerClass = '',
    ...layoutProps
  } = props;
  const [loading, setLoading] = useState(true);

  const handleImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = fallbackSrc;
  };

  const { responsiveConstant } = useResponsive();
  const layoutStyles = generateStyles(layoutProps, responsiveConstant);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const cloudFrontSrc = useMemo(() => {
    return getCloudFrontUrlFromResourceUrl(src);
  }, [src]);

  return (
    <div
      className={`${containerClass} image-container ${
        loading ? 'loading-spin-container' : ''
      }`}>
      <img
        src={cloudFrontSrc}
        alt={alt}
        style={layoutStyles}
        className={`${imgClass} image-container__image`}
        onError={handleImageError}
        onLoad={handleImageLoad}
      />
      {loading && <span className='loading-spin'></span>}
    </div>
  );
};

export default Image;

type IImageProps = CommonLayoutProps & {
  src: string;
  fallbackSrc?: string;
  alt?: string;
  imgClass?: string;
  containerClass?: string;
};
