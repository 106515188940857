import { createContext, useContext } from 'react';
// FIXME: can this be replaced with ref. getdatastate ??
export const RefetchCountContext = createContext<() => void>(() => {});

export const useGetRefetchCount = () => {
  const context = useContext(RefetchCountContext);

  if (!context) {
    throw new Error(
      'useGetRefetchCount must be used within a RefetchCountContext'
    );
  }

  return context;
};
