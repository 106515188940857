import { FilterModel } from 'ag-grid-community';

import { IFilterConfig } from '@/molecules/Filter';

export function convertToFilterObject(
  input: FilterModel,
  filterConfig: IFilterConfig
) {
  // Initialize variables to store extracted values
  const result: Record<string, string> = {};

  // Iterate through the properties of the input object
  for (const key in input) {
    // FIXME: ts table
    // eslint-disable-next-line no-prototype-builtins
    if (input.hasOwnProperty(key)) {
      const obj = input[key];

      // Check if fieldType is "date" and extract startDate and endDate
      if (obj.filterType === 'date') {
        result.startDate = obj.dateFrom;
        result.endDate = obj.dateTo;
      }

      // Check if fieldType is "text" and extract searchField and searchName
      if (obj.filterType === 'text') {
        const option = filterConfig.find((option) => option.value === key);
        result.searchField =
          option?.renameKeyBeforeSendingNetworkRequest || key;
        result.searchName = obj.filter;
        result[option?.renameKeyBeforeSendingNetworkRequest || key] =
          obj.filter;
      }
    }
  }

  // Construct the resulting object
  // const result = {
  //   searchField: searchField,
  //   searchName: searchName,
  //  [searchField]: searchName,
  //   startDate: startDate,
  //   endDate: endDate,
  // };
  return result;
}
