import { ICellRendererParams } from 'ag-grid-community';
import { useRef } from 'react';

import AgGridCellText from '@/admin/components/common/AgGridCellText';
import CellModal, {
  ICellModalProps,
} from '@/admin/components/common/CellModal';
import DocsViewerCell, {
  IDocsViewerCellProps,
} from '@/admin/components/common/DocsViewerCell';
import { DEFAULT_NULL_VALUE } from '@/admin/components/common/ExpandedDetailSection';
import PaymentConfirmationCell from '@/admin/components/common/PaymentConfirmationCell';
import PaymentProcessCell from '@/admin/components/common/PaymentProcessCell';
import PaymentRejectionCell from '@/admin/components/common/PaymentRejectionCell';
import {
  IPaymentMoreActionMenuProps,
  PaymentsMoreActionMenu,
} from '@/admin/components/common/PaymentsMoreActionMenu';
import ExpandRowCell from '@/admin/components/pages/ActiveLeads/cell-renderers/ExpandRowCell';
import {
  bookingPaymentsSearchFieldOptions,
  TAB_NAME,
} from '@/admin/components/pages/ProjectBookingPayments';
import { getStartAndEndDateForLapsedAndPendingTab } from '@/admin/components/pages/ProjectEoiPayments/PaymentList';
import {
  hidePaymentConfirmationColumn,
  hidePaymentProcessColumn,
  hidePaymentRejectColumn,
} from '@/admin/components/pages/utils/hidePaymentListColumn';
import { useGetProject } from '@/admin/hooks/useGetProject';
import { ITransformProjectCountResponse } from '@/admin/services/projectsAPISlice';
import { Avatar } from '@/atoms/Avatar';
import { getAvatarColorFromString } from '@/atoms/Avatar/utils/getAvatarColorFromString';
import { Copy } from '@/atoms/Copy';
import { Text } from '@/atoms/Text/Text';
import { NameCard } from '@/booking/components/common/admin/NameCard';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import { IPaymentOrder } from '@/booking/interfaces';
import { baseUrl } from '@/constants/urls';
import type { IResponseBase, TObjectId } from '@/interfaces';
import { Table } from '@/molecules/Table';
import { IGridHandle, ITableMain } from '@/molecules/Table/Main';
import { DateUtils } from '@/utils/DateUtils';
import { capitalizeFirstAndLowercaseRest, toIndianRupee } from '@/utils/utils';

const PaymentList = (props: IPaymentListProps) => {
  const { project, isFetching, error } = useGetProject();
  const { tabName, statuses } = props;

  const hidePaymentConfirmation = hidePaymentConfirmationColumn(tabName);
  const hidePaymentProcess = hidePaymentProcessColumn(tabName);
  const hidePaymentReject = hidePaymentRejectColumn(tabName);

  const ref = useRef<IGridHandle<IBookingPaymentRow>>(null);

  if (isFetching) {
    return null;
  }

  if (error || !project) {
    throw new Error('project not available');
  }

  const apiConfig: ITableMain<
    IBookingPaymentRow,
    ITransformBookingPaymentsResponse,
    ITransformProjectCountResponse
  >['apiConfig'] = {
    getQParams: (filterModel) => {
      const paymentLapsedTimeInMinutes =
        project.payment?.booking.durations.lapsed;
      return {
        statuses,
        tabName,
        startDate:
          getStartAndEndDateForLapsedAndPendingTab(
            tabName,
            filterModel.startDate,
            filterModel.endDate,
            paymentLapsedTimeInMinutes
          ).startDate || '',
        endDate:
          getStartAndEndDateForLapsedAndPendingTab(
            tabName,
            filterModel.startDate,
            filterModel.endDate,
            paymentLapsedTimeInMinutes
          ).endDate || '',
        [filterModel.searchField]: filterModel.searchName,
      };
    },
    baseApi: `${baseUrl}/api/payment/unitCartPaymentOrders/${project.id}`,
    selectCount: (data) => {
      return data.data.totalCount;
    },
    selectData: (data) => {
      return data.data.paymentOrders;
    },
  };

  const getBookingStatus = (params: ICellRendererParams) => {
    return params.data.bookingStatus || DEFAULT_NULL_VALUE;
  };

  const getDetails = (params: ICellRendererParams<IBookingPaymentRow>) => {
    const { data } = params;
    if (!data) {
      return { details: [] };
    }
    const details = [
      {
        label: 'Email',
        value: data.extendedDetails.email,
      },
      {
        label: 'Executive',
        value: data.extendedDetails.executive,
      },
      {
        label: 'Type',
        value: data.extendedDetails.unitInfo,
      },
      {
        label: 'Category',
        value: data.extendedDetails.unitConfig,
      },
      {
        label: 'Final Agreement value',
        value: toIndianRupee(data.extendedDetails.totalAmount),
      },
      {
        label: 'Amount Received',
        value: toIndianRupee(data.extendedDetails.totalAmountPaid),
      },
      {
        label: 'Amount Due',
        value: toIndianRupee(data.extendedDetails.amountDue),
      },
    ];
    return { details };
  };

  const handleUpdateRow = (paymentOrderId: TObjectId) => {
    ref.current?.deleteRow(paymentOrderId);
  };

  return (
    <Table.Main<
      IBookingPaymentRow,
      ITransformBookingPaymentsResponse,
      ITransformProjectCountResponse
    >
      ref={ref}
      apiConfig={apiConfig}
      filterConfig={bookingPaymentsSearchFieldOptions}
      detailCellRendererParams={getDetails}>
      <Table.Column
        suppressMovable
        flex={0.8}
        cellClass='ag-grid-column-custom-cell'
        cellRenderer={ExpandRowCell}
        cellRendererParams={(params: ICellRendererParams) => params}
      />
      <Table.Column
        suppressMovable
        headerName='Lead Details'
        headerTooltip='Lead Details'
        field='leadName'
        filter='agTextColumnFilter'
        flex={2.5}
        cellClass='ag-cell-no-text-overflow ag-cell-wrap-text relata-ag-cell-word-break'
        cellRenderer={(params: ICellRendererParams) => {
          const avatarColor = getAvatarColorFromString(params.data.leadName);
          return (
            <NameCard
              avatar={
                <Avatar
                  avatarType='initial'
                  backgroundColor={avatarColor}
                  avatarInitial={params.data.leadName}
                />
              }
              disableCopyToClipboard
              title={params.data.leadName}
              subtitle={`${
                params.data.leadLqrPin || DEFAULT_NULL_VALUE
              } | +91 ***** ***${params.data.leadMobile.substring(8)}`}
            />
          );
        }}
      />
      <Table.Column
        suppressMovable
        headerName='Booking Status'
        headerTooltip='Booking Status'
        field='bookingStatus'
        flex={1}
        cellRenderer={(params: ICellRendererParams) => (
          <AgGridCellText
            cellText={capitalizeFirstAndLowercaseRest(getBookingStatus(params))}
          />
        )}
      />
      <Table.Column
        suppressMovable
        headerName='Mode'
        headerTooltip='Mode'
        field='mode'
        flex={1.5}
        cellClass='ag-cell-no-text-overflow ag-cell-wrap-text relata-ag-cell-word-break'
        cellRenderer={(params: ICellRendererParams) => (
          <AgGridCellText cellText={params.data.mode} />
        )}
      />
      <Table.Column
        suppressMovable
        headerName='Amount Paid'
        headerTooltip='Amount Paid'
        field='amount'
        flex={1.5}
        cellClass='ag-cell-no-text-overflow ag-cell-wrap-text relata-ag-cell-word-break'
        cellRenderer={(params: ICellRendererParams) => (
          <AgGridCellText cellText={params.data.amount} />
        )}
      />
      <Table.Column
        suppressMovable
        flex={1.2}
        headerName='Paid On'
        cellClass='ag-cell-no-text-overflow ag-cell-wrap-text relata-ag-cell-word-break'
        headerTooltip='Paid On'
        cellRenderer={(params: ICellRendererParams) => (
          <AgGridCellText
            cellText={
              params.data.paidOn &&
              DateUtils.format(params.data.paidOn, DateUtils.FormatTo.LONG_DATE)
            }
          />
        )}
      />
      <Table.Column
        suppressMovable
        flex={1.5}
        headerName='Transaction Id'
        headerTooltip='Transaction Id'
        field='transactionId'
        filter='agTextColumnFilter'
        cellClass='relata-ag-container-full-width'
        cellRenderer={(params: ICellRendererParams) => (
          <Copy fullWidth>
            <Text
              text={params.data.transactionId}
              color='color-primary-text-grey-45'
              weight='semibold'
              ellipsis
            />
          </Copy>
        )}
      />

      <Table.Column
        suppressMovable
        flex={1.5}
        hide={hidePaymentConfirmation}
        headerName='Confirm'
        cellClass='relata-ag-grid-cell-justify-center'
        cellRenderer={CellModal}
        cellRendererParams={(params: ICellRendererParams): ICellModalProps => ({
          children: (
            <PaymentConfirmationCell
              paymentOrder={params.data}
              project={project}
              onPaymentUpdate={() => handleUpdateRow(params.data.id)}
              unitCartId={params.data.unitCartId}
            />
          ),
          buttonText: 'Confirm',
          buttonBeforeIcon: 'done',
          modalTitle: 'Add Payment to Completed Tab',
          modalSubTitle:
            'Clicking on Submit button moves record to Completed tab. For more details, attach supporting document/screenshot.',
          buttonAccent: 'bordered',
          containerWidth: 'quarter',
        })}
      />

      <Table.Column
        suppressMovable
        flex={1.5}
        hide={hidePaymentProcess}
        headerName='Process'
        cellClass='relata-ag-grid-cell-justify-center'
        cellRenderer={CellModal}
        cellRendererParams={(params: ICellRendererParams): ICellModalProps => ({
          children: (
            <PaymentProcessCell
              paymentOrder={params.data}
              project={project}
              onPaymentUpdate={() => handleUpdateRow(params.data.id)}
              unitCartId={params.data.unitCartId}
            />
          ),
          buttonText: 'Process',
          buttonBeforeIcon: 'access_time',
          modalTitle: 'Add Payment to Pending Tab',
          modalSubTitle:
            'Clicking on Submit button moves record to Pending tab. For more details, attach supporting document/screenshot.',
          buttonAccent: 'bordered',
          containerWidth: 'quarter',
        })}
      />

      <Table.Column
        suppressMovable
        hide={hidePaymentReject}
        flex={1.5}
        headerName='Reject'
        cellClass='relata-ag-grid-cell-justify-center'
        cellRenderer={CellModal}
        cellRendererParams={(params: ICellRendererParams): ICellModalProps => ({
          children: (
            <PaymentRejectionCell
              paymentOrder={params.data}
              project={project}
              onPaymentUpdate={() => handleUpdateRow(params.data.id)}
            />
          ),
          buttonText: 'Reject',
          buttonBeforeIcon: 'block',
          modalTitle: 'Add Payment to Failed Tab',
          modalSubTitle:
            'Clicking on Submit button moves record to Failed tab. For more details, attach supporting document/screenshot..',
          buttonAccent: 'bordered',
          containerWidth: 'quarter',
        })}
      />
      <Table.Column
        suppressMovable
        headerName='Attachments'
        headerTooltip='Attachments'
        cellClass='relata-ag-grid-cell-justify-center'
        flex={0.8}
        cellRenderer={DocsViewerCell}
        cellRendererParams={(
          params: ICellRendererParams
        ): IDocsViewerCellProps => {
          return {
            docUrls: params.data.receiptsUrl,
            leadName: params.data.leadName,
            createdAt: params.data.paidOn,
          };
        }}
      />
      <Table.Column
        suppressMovable
        headerName='More'
        headerTooltip='More'
        flex={0.8}
        cellRenderer={PaymentsMoreActionMenu}
        cellRendererParams={(
          params: ICellRendererParams
        ): IPaymentMoreActionMenuProps => {
          return {
            paymentOrders: params.data.extendedDetails.paymentOrders,
            leadName: params.data.leadName,
          };
        }}
      />
      <Table.Column
        suppressMovable
        hide
        field='leadMobile'
        filter='agTextColumnFilter'
      />
      <Table.Column
        suppressMovable
        hide
        field='extendedDetails.email'
        filter='agTextColumnFilter'
      />
      <Table.Column
        suppressMovable
        hide
        field='extendedDetails.paymentOrders.failureReason'
        filter='agTextColumnFilter'
      />
      <Table.Column
        suppressMovable
        hide
        field='extendedDetails.unitInfo'
        filter='agTextColumnFilter'
      />
      <Table.Column
        suppressMovable
        hide
        field='paidOn'
        filter='agDateColumnFilter'
      />
    </Table.Main>
  );
};

export default PaymentList;

export interface IBookingPaymentRow {
  id: string;
  unitCartId: string;
  leadName: string;
  leadMobile: string;
  leadStatus: string;
  bookingStatus: string;
  amount: number;
  paidOn: string;
  mode: string;
  transactionId: string;
  receiptsUrl: string;
  status: string;
  extendedDetails: {
    email: string;
    executive: string;
    unitInfo: string;
    unitConfig: string;
    totalAmount: number;
    totalAmountPaid: number;
    amountDue: number;
    paymentOrders: IPaymentOrder[];
  };
}

interface IPaymentListProps {
  tabName: TAB_NAME;
  statuses: PAYMENT_ORDER_STATUS[];
}

interface ITransformBookingPaymentsResponse extends IResponseBase {
  data: { paymentOrders: IBookingPaymentRow[] };
}
