import React from 'react';
import ReactDOM from 'react-dom';

import styles from './styles.module.css';

interface IModalProps {
  show?: boolean;
  children: React.ReactNode;
  additionalClasses?: string;
  mobileModelCenter?: boolean;
  onOutsideClick?: () => void;
  fullPageModel?: boolean;
}

const Modal = (props: IModalProps) => {
  const {
    show = true,
    children,
    onOutsideClick,
    additionalClasses = '',
    mobileModelCenter = false,
    fullPageModel = false,
  } = props;

  const handleBackdropClick = () => {
    onOutsideClick && onOutsideClick();
  };

  return (
    <>
      {ReactDOM.createPortal(
        <>
          {show && (
            <div
              className={`modal ${show && 'modal--show'} ${
                mobileModelCenter && 'modal--center'
              }`}>
              <div
                className={`modal-content ${
                  fullPageModel && 'modal-fullPage'
                } ${additionalClasses}`}>
                {children}
              </div>
              <div
                className='modal-backdrop'
                onClick={handleBackdropClick}></div>
            </div>
          )}
        </>,
        document.getElementById('modal-root')!
      )}
    </>
  );
};

export default Modal;
