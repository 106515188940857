import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';

import { deleteToast, getToasts } from '../../../../../slices/toasts';

import Toast from './Toast';

const ToastContainer = () => {
  const dispatch = useDispatch();
  const toasts = useSelector(getToasts);

  const handleToastDelete = (toastId: string) => {
    dispatch(deleteToast(toastId));
  };

  return (
    <motion.div layout className='toast-container'>
      <AnimatePresence>
        {Object.keys(toasts).map((toastId) => (
          <Toast
            key={toastId}
            toast={toasts[toastId]}
            onToastDelete={() => handleToastDelete(toastId)}
          />
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

export default ToastContainer;
