import styles from './styles.module.css';

interface IMainPageBodyL {
  children?: React.ReactNode;
}

export const MainPageBodyBodyL = (props: IMainPageBodyL) => {
  const { children } = props;
  return <main className={styles.body}>{children}</main>;
};
export const MainPageBodyFooterL = (props: IMainPageBodyL) => {
  const { children } = props;
  return <footer className={styles.footer}>{children}</footer>;
};

const MainPageBodyL = (props: IMainPageBodyL) => {
  const { children } = props;
  return <div className={styles.mainPageBody}>{children}</div>;
};

export default MainPageBodyL;
