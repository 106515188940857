import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Text } from '@/atoms/Text/Text';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

export const NoRowsExist = (props: INoRowsExistProps) => {
  const { rowAvailabilityStatus } = props;

  if (!rowAvailabilityStatus) return null;
  return (
    <FlexDeprecated
      position='absolute'
      top='0'
      left='0'
      height='100%'
      width='100%'
      alignItems='center'
      justifyContent='center'
      mt={8}>
      <ConditionalRendering showIf={rowAvailabilityStatus === 'NO_ROWS_FILTER'}>
        <Text text='No Data Present. Please reset the filters' />
      </ConditionalRendering>
      <ConditionalRendering showIf={rowAvailabilityStatus === 'NO_ROWS'}>
        <Text text='No Data Present.' />
      </ConditionalRendering>
    </FlexDeprecated>
  );
};

export type INoRowsExistProps = {
  rowAvailabilityStatus: 'NO_ROWS' | 'NO_ROWS_FILTER' | null;
};
