import {
  AppliedFilters,
  FilterPreferences,
} from '@/booking/components/unitSelection/utils';
import {
  DEFAULT_SELECTED_BLOCK,
  DEFAULT_SELECTED_TOWER_NAME,
  IState,
  IUnitById,
  IUnitSelectionNavMenu,
} from '@/booking/slices/unitSelectionHeader/unitSelectionHeaderNav';
import { ONE_CRORE } from '@/constants/index';
import { UnitStatus } from '@/constants/status';

export const getUpdatedUnitList = (state: IState) => {
  const unitList = state.unitListById;
  const selectedTowerName = state.unitSelectionNavMenu.towerName.selectedValue;
  const selectedSection = state.unitSelectionNavMenu.section.selectedValue;
  if (selectedTowerName === DEFAULT_SELECTED_TOWER_NAME) {
    return Object.values(unitList);
  }
  return Object.values(unitList).filter(
    (unit) =>
      unit.towerName.toLowerCase() === selectedTowerName.toLowerCase() &&
      unit.applicableFloorplan.toLowerCase() === selectedSection.toLowerCase()
  );
};

export const updateUnitStatusByAppliedFilters = (
  selectedPreferences: AppliedFilters,
  filterPreferences: FilterPreferences,
  isSelected: boolean,
  key: string | null,
  unitListById: IUnitById,
  selectedTowerName: string,
  selectedSection: string
) => {
  Object.values(filterPreferences)
    .filter(
      (preferenceContainer) => !isSelected || preferenceContainer.key !== key
    )
    .forEach((preferenceContainer) => {
      preferenceContainer.items.forEach((item) => {
        item.unitIds.forEach((unitId) => {
          let isFilteredOut = true;

          // TODO: Make following check dynamic
          // Monky patching - Bedrooms
          if (
            (selectedPreferences.type.length === 0 ||
              selectedPreferences.type.includes(
                unitListById[unitId].type.split('-')[0]
              )) &&
            (selectedPreferences.view.length === 0 ||
              selectedPreferences.view.includes(unitListById[unitId].view)) &&
            (selectedPreferences.facing.length === 0 ||
              selectedPreferences.facing.includes(
                unitListById[unitId].facing
              )) &&
            (selectedPreferences.totalInclusiveAmount.length === 0 ||
              selectedPreferences.totalInclusiveAmount.some((amountFilter) => {
                if (typeof amountFilter === 'string') {
                  return true;
                } else {
                  // Handle the case when amountFilter is an object
                  return (
                    unitListById[unitId].costSheets[0].totalInclusiveAmount /
                      ONE_CRORE >=
                      amountFilter.from &&
                    unitListById[unitId].costSheets[0].totalInclusiveAmount /
                      ONE_CRORE <=
                      amountFilter.to
                  );
                }
              })) &&
            (selectedPreferences.area.length === 0 ||
              selectedPreferences.area.some((areaFilter) => {
                if (typeof areaFilter === 'string') {
                  return true;
                } else {
                  return (
                    unitListById[unitId].area.value >= areaFilter.from &&
                    unitListById[unitId].area.value <= areaFilter.to
                  );
                }
              })) &&
            (selectedTowerName === DEFAULT_SELECTED_TOWER_NAME ||
              (selectedTowerName === unitListById[unitId].towerName &&
                selectedSection === unitListById[unitId].applicableFloorplan))
          ) {
            isFilteredOut = false;
          }

          if (isFilteredOut) {
            if (unitListById[unitId].isPreferred) {
              item.availableUnitCount--;
            }
            unitListById[unitId].isPreferred = false;
          } else {
            if (!unitListById[unitId].isPreferred) {
              item.availableUnitCount++;
            }
            unitListById[unitId].isPreferred = true;
          }
        });
      });
    });
};

export const updateSectionBlockDropdownOptionsAvailableUnitsCnt = (
  unitSelectionNavMenu: IUnitSelectionNavMenu,
  unitListById: IUnitById
) => {
  // Update options if needed
  // Update availableUnitCount & availableUnitCountNotMatchingTag for section and blockName
  const towerName = unitSelectionNavMenu.towerName.selectedValue;
  const selectedSection = unitSelectionNavMenu.section.selectedValue;
  const selectedBlock = unitSelectionNavMenu.blockName.selectedValue;
  const isBlockSelected =
    unitSelectionNavMenu.blockName.selectedValue !== DEFAULT_SELECTED_BLOCK;
  const updateBlockOptions = () => {
    // Create blockName optinos according to unitListById.
    // where as we are not creating section option because section options
    // are hardcoded.
    const blockList = Object.values(unitListById)
      .filter((unit) => unit.towerName.toLowerCase() === towerName)
      .map((unit) => unit.blockName)
      .filter((blockName, index, array) => array.indexOf(blockName) === index);
    unitSelectionNavMenu.blockName.options = blockList.map((blockName) => {
      return {
        value: `block-${blockName?.toLowerCase()}`,
        text: `Block ${blockName}`,
        availablePreferredUnitCount: 0,
        availableNonPreferredUnitCount: 0,
      };
    });
  };
  const updateSectionOptions = () => {
    // Create section optinos according to unitListById.
    // Section has one to one mapping with floorPlan.
    const sectionList = Object.values(unitListById)
      .filter((unit) => unit.towerName.toLowerCase() === towerName)
      .map((unit) => unit.applicableFloorplan) // TODO: toLowerCase()
      .filter(
        (applicableFloorplan, index, array) =>
          array.indexOf(applicableFloorplan) === index
      );
    unitSelectionNavMenu.section.options = sectionList.map((section) => {
      return {
        value: `${section}`,
        text: `${section}`,
        availablePreferredUnitCount: 0,
        availableNonPreferredUnitCount: 0,
      };
    });
  };

  const updateOptionUnitCount = () => {
    // Re Initialise availableUnitCount to Zero
    unitSelectionNavMenu.section.options.forEach((option) => {
      option.availablePreferredUnitCount = 0;
      option.availableNonPreferredUnitCount = 0;
    });
    unitSelectionNavMenu.blockName.options.forEach((option) => {
      option.availablePreferredUnitCount = 0;
      option.availableNonPreferredUnitCount = 0;
    });

    // Update section's available unit count
    Object.values(unitListById)
      .filter(
        (unit) =>
          unit.towerName.toLowerCase() === towerName &&
          unit.status === UnitStatus.AVAILABLE
        // TODO: Enable block selection
        // &&
        // (!isBlockSelected ||
        //   `block-${unit.blockName.toLowerCase()}` === selectedBlock)
      )
      .forEach((unit) => {
        const sectionOption = unitSelectionNavMenu.section.options.find(
          (option) => option.value === unit.applicableFloorplan
        );
        if (sectionOption) {
          unit.isPreferred && sectionOption.availablePreferredUnitCount++;
          !unit.isPreferred && sectionOption.availableNonPreferredUnitCount++;
        }
      });

    // Update blockName's available unit count
    Object.values(unitListById)
      .filter(
        (unit) =>
          unit.towerName.toLowerCase() === towerName &&
          unit.status === UnitStatus.AVAILABLE &&
          selectedSection === unit.applicableFloorplan
      )
      .forEach((unit) => {
        const blockOption = unitSelectionNavMenu.blockName.options.find(
          (option) => option.value === `block-${unit.blockName.toLowerCase()}`
        );
        if (blockOption) {
          unit.isPreferred && blockOption.availablePreferredUnitCount++;
          !unit.isPreferred && blockOption.availableNonPreferredUnitCount++;
        }
      });
  };

  updateSectionOptions();
  updateBlockOptions();

  updateOptionUnitCount();
};

export const updateTowerDropdownOptionsAvailableUnitsCnt = (
  unitSelectionNavMenu: IUnitSelectionNavMenu,
  unitListById: IUnitById
) => {
  const updateTowerOptions = () => {
    // Create towerName optinos according to unitListById.
    const towerList = Object.values(unitListById)
      .map((unit) => unit.towerName)
      .filter((towerName, index, array) => array.indexOf(towerName) === index);
    unitSelectionNavMenu.towerName.options = towerList.map((towerName) => {
      return {
        value: towerName.toLowerCase(),
        text: towerName,
        availablePreferredUnitCount: 0,
        availableNonPreferredUnitCount: 0,
      };
    });
  };

  const updateTowerOptionUnitCount = () => {
    Object.values(unitListById)
      .filter((unit) => unit.status === UnitStatus.AVAILABLE)
      .forEach((unit) => {
        const towerOption = unitSelectionNavMenu.towerName.options.find(
          (option) => option.value === unit.towerName.toLowerCase()
        );
        if (towerOption) {
          towerOption.availablePreferredUnitCount += unit.isPreferred ? 1 : 0;
          towerOption.availableNonPreferredUnitCount += unit.isPreferred
            ? 0
            : 1;
        }
      });
  };

  updateTowerOptions();
  updateTowerOptionUnitCount();
};
