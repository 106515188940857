import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { authAPISlice } from '../../../api/auth';
import { adminUserAPISlice } from '@/admin/services/adminUserAPISlice';
import type { IUser } from '@/interfaces';

const authUser = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user') as string)
  : {};

interface IState {
  user: IUser;
  thirdPartyUserId: string;
  showAuthForms: boolean;
}

const initialState: IState = {
  user: authUser,
  thirdPartyUserId: '',
  showAuthForms: false,
};

const sliceName = 'authSlice';

interface IAuthSlice {
  [sliceName]: IState;
}

export const authSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(state.user));

      // if (state.user.id) {
      //   window.ga('set', 'dimension1', state.user.id);
      // }
    },
    setThirdPartyUserId: (state, action: PayloadAction<string>) => {
      state.thirdPartyUserId = action.payload;
    },
    setShowAuthForms: (state, action: PayloadAction<boolean>) => {
      state.showAuthForms = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authAPISlice.endpoints.register.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        localStorage.setItem('user', JSON.stringify(state.user));
        state.showAuthForms = false;

        // if (state.user.id) {
        //   window.ga('set', 'dimension1', state.user.id);
        // }
      }
    );
    builder.addMatcher(
      authAPISlice.endpoints.updateUser.matchFulfilled,
      (state, { payload }) => {
        state.user = { ...state.user, ...payload };
        localStorage.setItem('user', JSON.stringify(state.user));
      }
    );
    builder.addMatcher(
      authAPISlice.endpoints.getUserByMobileOld.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        localStorage.setItem('user', JSON.stringify(state.user));
        state.showAuthForms = false;

        // if (state.user.id) {
        //   window.ga('set', 'dimension1', state.user.id);
        // }
      }
    );
  },
});

export const { setUser, setThirdPartyUserId, setShowAuthForms } =
  authSlice.actions;

export const getUser = (state: IAuthSlice) => state.authSlice.user;
export const getThirdPartyUserId = (state: IAuthSlice) =>
  state.authSlice.thirdPartyUserId;
export const getShowAuthForms = (state: IAuthSlice) =>
  state.authSlice.showAuthForms;
