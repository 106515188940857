import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { usePathParams } from '@/admin/hooks/usePathParams';
import { useGetProjectsByProjectIdQuery } from '@/apps/admin/services/projectsAPISlice';
import { getProjectDetails } from '@/apps/admin/slices/projectDetailsAdminSlice';
import { IProject } from '@/interfaces';

export const useGetProject = (): Response => {
  /**
   * Try to fetch using query params
   */
  const { projectId } = usePathParams();
  const match = useRouteMatch([
    '/admin/projects',
    '/forms/site-visit',
    '/scanners/crm',
    '/scanners/lqr',
    '/scanners/closingManager',
    '/scanners/assisted-tracking',
  ]);

  const {
    data: project,
    isFetching,
    error,
  } = useGetProjectsByProjectIdQuery(projectId ? projectId : skipToken);
  // TODO: @mekhla, pls save this project in redux
  /**
   * Try to get project using redux
   */
  const projectFromRedux = useSelector(getProjectDetails);

  if (!match) {
    throw new Error('Please call useGetProject only from admin/projects route');
  }

  if (projectId) {
    return {
      error,
      project: project,
      isFetching,
    };
  }

  return {
    project: projectFromRedux,
  };
};

type Response = {
  project: IProject | undefined;
  error?: unknown;
  isFetching?: boolean;
};
