import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FixedBottomContainer } from '@/admin/components/common/Containers';
import LoadingPage from '@/admin/components/common/LoadingPage';
import {
  AddAllocationStates,
  getAddAllocationLead,
  setAddAllocationLead,
  setAddAllocationSelectedEoI,
  setAddAllocationState,
} from '@/admin/components/pages/AddAllocation/addAllocationSlice';
import { TIMER_LENGTH } from '@/admin/constants/const';
import type { IEOI } from '@/admin/interfaces';
import {
  useGetUserByMobileMutation,
  useOtpv2SendMutation,
  useOtpv2VerifyMutation,
} from '@/admin/services/adminUserAPISlice';
import { useGetEOIForUserMutation } from '@/admin/services/eoiAPISlice';
import { useAddProjectLeadMutation } from '@/admin/services/projectLeadsAPI';
import { useGetLeadByPinMutation } from '@/admin/services/qrCodeAPISlice';
import { useLazyGetLqrByLeadIdAndProjectIdQuery } from '@/admin/services/siteVisitAPI';
import { Button } from '@/atoms/Button';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { IconNames } from '@/atoms/Icon';
import { ModalWrapper } from '@/atoms/ModalWrapper';
import Separator from '@/atoms/Separator';
import DropDownInput, {
  DropDownOptionType,
} from '@/booking/components/common/inputs/DropDownInput';
import TextInput from '@/booking/components/common/inputs/TextInput';
import useDateExpiryCountdown, {
  DateExpiryCountDownState,
} from '@/booking/hooks/useDateExpiryCountdown';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { OTP_TEMPLATE_DEFAULT } from '@/constants/smsEmailTemplates';
import { EOI_STATUS } from '@/constants/status';
import useToast from '@/hooks/useToast';
import type { IErrorResponse, IProject, IUser } from '@/interfaces';

import styles from './styles.module.css';

export const Lead = (props: ILeadProps) => {
  const { project, onClose } = props;

  const dispatch = useDispatch();

  const [pin, setPin] = useState<string>();
  const [mobile, setMobile] = useState<string>();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [leadInfoState, setLeadInfoState] = useState(LEAD_INFO_STATE.NIL);
  const [leadState, setLeadState] = useState(LEAD_STATE.NIL);
  const [otpState, setOtpState] = useState(OTP_STATE.NIL);
  const [otp, setOtp] = useState('');
  const [leadEoIs, setLeadEoIs] = useState<IEOI[]>([]);
  const [otpExpirationDate, setOtpExpirationDate] = useState<Date>();

  const lead = useSelector(getAddAllocationLead);

  const [getLeadByPinAPI, { isLoading: isFetchingLeadByPin }] =
    useGetLeadByPinMutation();
  const [getUserByMobileAPI, { isLoading: isFetchingUserByMobile }] =
    useGetUserByMobileMutation();
  const [addProjectLeadAPI, { isLoading: isAddingUser }] =
    useAddProjectLeadMutation();
  const [otpv2SendAPI, { isLoading: isOtpSending }] = useOtpv2SendMutation();
  const [otpv2VerifyAPI, { isLoading: isOtpVerifying }] =
    useOtpv2VerifyMutation();
  const [getEOIForUserAPI, { isLoading: isFetchingUserEoIs }] =
    useGetEOIForUserMutation();
  const [
    getLqrByLeadIdAndProjectId,
    { data: lqrCode, isLoading: isFetchingLqrCode },
  ] = useLazyGetLqrByLeadIdAndProjectIdQuery();

  const [addToast] = useToast();
  const dateExpiryCountDown = useDateExpiryCountdown(otpExpirationDate);

  useEffect(() => {
    if (lead) {
      setName(lead.name);
      setEmail(lead.email);
      setMobile(lead.mobile);

      fetchLeadEoIs();
    }
  }, [lead]);

  useEffect(() => {
    if (
      dateExpiryCountDown.state === DateExpiryCountDownState.EXPIRED &&
      otpState === OTP_STATE.SENT
    ) {
      setOtpState(OTP_STATE.NIL);
    }
  }, [dateExpiryCountDown]);

  useEffect(() => {
    if (lqrCode) {
      setPin(lqrCode.pin);
    }
  }, [lqrCode]);

  const otpEndIconBottomMessageMap = {
    [OTP_STATE.SENT]: `Resend Otp in ${dateExpiryCountDown.minutes}:${dateExpiryCountDown.seconds}`,
    [OTP_STATE.NIL]: '',
    [OTP_STATE.VERIFIED]: '',
  };

  const isNextButtonDisabledMap = {
    [LEAD_STATE.DOES_NOT_EXISTS]:
      otpState !== OTP_STATE.VERIFIED || !name?.length || !email?.length,
    [LEAD_STATE.NIL]: leadInfoState === LEAD_INFO_STATE.NIL,
    [LEAD_STATE.EXISTS]: false,
  };

  const shouldShowLoading =
    isFetchingLeadByPin ||
    isFetchingUserByMobile ||
    isAddingUser ||
    isFetchingUserEoIs;

  const isBtnLoading = isOtpSending || isOtpVerifying;

  let loadingMessage;
  switch (true) {
    case isFetchingLeadByPin:
      loadingMessage = 'Fetching lead by LQR Pin ...';
      break;
    case isFetchingUserByMobile:
      loadingMessage = 'Fetching lead by Mobile ...';
      break;
    case isAddingUser:
      loadingMessage = 'Creating lead ...';
      break;
    case isFetchingUserEoIs:
      loadingMessage = 'Fetching EOI for lead ...';
      break;
    case isFetchingLqrCode:
      loadingMessage = 'Fetching LqrCode for lead ...';
      break;
    default:
      loadingMessage = 'Loading ...';
  }

  const eoisDropDown: DropDownOptionType<string>[] = useMemo(() => {
    const newEoisDropDown: DropDownOptionType<string>[] = leadEoIs.map(
      (eoi) => {
        return {
          value: eoi.id,
          displayText: `${eoi.type} | ${eoi.unitTag} | ${
            displayEoiStatus[eoi.status]
          }`,
          isDisabled: eoi.status !== EOI_STATUS.ACTIVE,
        };
      }
    );
    return newEoisDropDown;
  }, [leadEoIs]);

  const fetchLeadEoIs = async () => {
    const { data: eois } = await getEOIForUserAPI({
      userId: lead?.id,
      projectId: project.id,
    }).unwrap();

    setLeadEoIs(eois);
  };
  const handleNextButton = () => {
    const nextButtonCallbackMap = {
      [LEAD_STATE.NIL]: handleRetrieveLeadDetails,
      [LEAD_STATE.DOES_NOT_EXISTS]: handleCreateLead,
      [LEAD_STATE.EXISTS]: handleProceed,
    };

    nextButtonCallbackMap[leadState]();
  };
  const handleSetPin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPin = e.target.value.toUpperCase();

    setPin(newPin);

    if (newPin.length === 0) {
      setLeadInfoState(LEAD_INFO_STATE.NIL);
    } else if (leadInfoState !== LEAD_INFO_STATE.PIN) {
      setLeadInfoState(LEAD_INFO_STATE.PIN);
    }

    if (leadState !== LEAD_STATE.NIL) {
      setLeadState(LEAD_STATE.NIL);
    }
  };
  const handleSetMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMobile = e.target.value;

    setMobile(newMobile);

    if (newMobile.length === 0) {
      setLeadInfoState(LEAD_INFO_STATE.NIL);
    } else if (leadInfoState !== LEAD_INFO_STATE.MOBILE) {
      setLeadInfoState(LEAD_INFO_STATE.MOBILE);
    }

    if (leadState !== LEAD_STATE.NIL) {
      setLeadState(LEAD_STATE.NIL);
    }
  };
  const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;

    setName(newName);
  };
  const handleSetEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;

    setEmail(newEmail);
  };
  const handleRetrieveLeadDetails = async () => {
    let user;

    if (leadInfoState === LEAD_INFO_STATE.PIN) {
      try {
        user = await getLeadByPinAPI(pin as string).unwrap();
      } catch (error) {
        console.error('Error: ', error);
      }
    } else {
      try {
        const { data } = await getUserByMobileAPI({
          mobile: mobile as string,
        }).unwrap();
        getLqrByLeadIdAndProjectId({ leadId: data.id, projectId: project.id });
        user = data;
      } catch (error) {
        console.error('Error: ', error);
      }
    }

    dispatch(setAddAllocationLead(user));

    setLeadState(user ? LEAD_STATE.EXISTS : LEAD_STATE.DOES_NOT_EXISTS);
  };
  const handleSetOtp = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOtp = e.target.value;

    setOtp(newOtp);
  };
  const handleOtpEndIconClick = () => {
    const otpCallbackMap = {
      [OTP_STATE.NIL]: handleSendOtp,
      [OTP_STATE.SENT]: handleOtpVerify,
      [OTP_STATE.VERIFIED]: () => {},
    };

    otpCallbackMap[otpState]();
  };
  const handleSendOtp = async () => {
    const address = mobile as string;
    await otpv2SendAPI({
      address,
      addressType: 'MOBILE',
      otpTemplate: OTP_TEMPLATE_DEFAULT,
    }).unwrap();

    addToast({
      type: 'SUCCESS',
      primaryMessage: `OTP Sent to ${address}`,
      timeout: 1000,
    });

    const currentDate = new Date();
    const MILLISECONDS_IN_A_SECOND = 1000;
    const expirationDate = new Date(
      currentDate.getTime() + TIMER_LENGTH * MILLISECONDS_IN_A_SECOND
    );

    setOtpExpirationDate(expirationDate);
    setOtpState(OTP_STATE.SENT);
  };
  const handleOtpVerify = async () => {
    try {
      await otpv2VerifyAPI({
        address: `${mobile}`,
        otp: `${otp}`,
      }).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'OTP verified successfully',
        secondaryMessage: '',
        timeout: 1000,
      });
      setOtpState(OTP_STATE.VERIFIED);
    } catch (error) {
      const errMsg = (error as IErrorResponse).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    }
  };
  const handleCreateLead = async () => {
    try {
      const { data: projectLead } = await addProjectLeadAPI({
        requestPayload: {
          project: project.id,
          name,
          email,
          mobile: mobile as string,
        },
      }).unwrap();

      const lead: IUser = {
        name: projectLead.name,
        email: projectLead.email,
        mobile: projectLead.mobile,
        id: projectLead.lead,
        createdAt: projectLead.createdAt,
      } as IUser;

      dispatch(setAddAllocationLead(lead));
      setLeadState(LEAD_STATE.EXISTS);

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Lead Created!',
        timeout: 1000,
      });
    } catch (error) {
      const errMsg = (error as IErrorResponse).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add new Lead',
        timeout: 1000,
      });
    }
  };
  const handleProceed = async () => {
    dispatch(setAddAllocationState(AddAllocationStates.UNIT_SELECTION));
  };
  const handleSelectedEoIs = (selectedEoIs: DropDownOptionType<string>[]) => {
    const selectedEoI = leadEoIs.find(
      (eoi) => eoi.id === selectedEoIs[0].value
    );

    dispatch(setAddAllocationSelectedEoI(selectedEoI));
  };

  return (
    <ModalWrapper
      modalTitle='Add New Allocation'
      subtitle='For adding new Allocation verify through LQR or Lead Phone Number'
      onClose={onClose}
      containerWidth='quarter'>
      <ConditionalRendering showIf={shouldShowLoading}>
        <LoadingPage
          errorMessage={loadingMessage}
          pageType='loading'
          loadingSize='small'
        />
      </ConditionalRendering>
      <ConditionalRendering showIf={!shouldShowLoading}>
        <>
          <FlexDeprecated flexColumn>
            <TextInput
              type='text'
              label='LQR (if applicable)'
              placeHolder='Enter LQR'
              name='lqr'
              defaultValue={pin}
              onChange={handleSetPin}
              propStyles={styles.inputBox}
              inputHTMLElementStyles={styles.inputPin}
              disabled={
                !(
                  leadInfoState === LEAD_INFO_STATE.NIL ||
                  leadInfoState === LEAD_INFO_STATE.PIN
                )
              }
              maxLength={5}
              errorMessage={
                leadInfoState === LEAD_INFO_STATE.PIN &&
                leadState === LEAD_STATE.DOES_NOT_EXISTS
                  ? 'Invalid LQR'
                  : undefined
              }
            />
            <Separator lineStyle='solid' separatorColor='color-primary-90' />
            <FlexDeprecated flexRow justifyContent='space-between'>
              <TextInput
                type='number'
                label='Mobile number'
                placeHolder='Enter Mobile number'
                name='mobile'
                defaultValue={mobile}
                startIconName='local_phone'
                onChange={handleSetMobile}
                propStyles={styles.inputBox}
                disabled={
                  !(
                    leadInfoState === LEAD_INFO_STATE.NIL ||
                    leadInfoState === LEAD_INFO_STATE.MOBILE
                  )
                }
                maxLength={10}
                errorMessage={
                  leadInfoState === LEAD_INFO_STATE.MOBILE &&
                  leadState === LEAD_STATE.DOES_NOT_EXISTS &&
                  otpState !== OTP_STATE.VERIFIED
                    ? 'Mobile not verified'
                    : undefined
                }
                endIconName={
                  otpState === OTP_STATE.VERIFIED ? 'check' : undefined
                }
              />
              <ConditionalRendering
                showIf={
                  leadInfoState === LEAD_INFO_STATE.MOBILE &&
                  leadState === LEAD_STATE.DOES_NOT_EXISTS &&
                  otpState !== OTP_STATE.VERIFIED
                }>
                <TextInput
                  type='number'
                  label='Verify With OTP'
                  placeHolder={
                    otpState === OTP_STATE.NIL
                      ? 'Click button to send OTP'
                      : 'Add Otp'
                  }
                  name='otp'
                  propStyles={styles.inputBox}
                  maxLength={6}
                  endIconName={otpEndIconNameMap[otpState]}
                  onEndIconClick={handleOtpEndIconClick}
                  bottomMessage={otpEndIconBottomMessageMap[otpState]}
                  defaultValue={`${otp}`}
                  onChange={handleSetOtp}
                />
              </ConditionalRendering>
            </FlexDeprecated>
            <FlexDeprecated flexRow justifyContent='space-between'>
              <TextInput
                type='text'
                label='Lead Name'
                placeHolder='Add Lead Name'
                name='name'
                defaultValue={name}
                propStyles={styles.inputBox}
                readOnly={
                  !(
                    otpState === OTP_STATE.VERIFIED &&
                    leadState !== LEAD_STATE.EXISTS
                  )
                }
                onChange={handleSetName}
              />
              <TextInput
                type='text'
                label='Lead Email'
                placeHolder='Add Lead Email'
                name='email'
                defaultValue={email}
                propStyles={styles.inputBox}
                readOnly={
                  !(
                    otpState === OTP_STATE.VERIFIED &&
                    leadState !== LEAD_STATE.EXISTS
                  )
                }
                onChange={handleSetEmail}
              />
            </FlexDeprecated>
            <Separator separatorColor='color-primary-90' />
            <DropDownInput
              options={eoisDropDown}
              lable='Select EoI'
              disabled={!eoisDropDown.length}
              onValueSelected={handleSelectedEoIs}
              propStyles={styles.inputBox}
            />
          </FlexDeprecated>
          <FixedBottomContainer>
            <Button
              accent='primary'
              disabled={isNextButtonDisabledMap[leadState]}
              onClick={handleNextButton}
              propStyles={styles.inputBox}
              loading={isBtnLoading}>
              {nextButtonTextMap[leadState]}
            </Button>
          </FixedBottomContainer>
        </>
      </ConditionalRendering>
    </ModalWrapper>
  );
};

export const displayEoiStatus: Record<EOI_STATUS, string> = {
  [EOI_STATUS.DRAFT]: 'Draft',
  [EOI_STATUS.INITIATED]: 'Initiated',
  [EOI_STATUS.ACTIVE]: 'Confirmed',
  [EOI_STATUS.ATTACHED]: 'Attached',
  [EOI_STATUS.BLOCKED]: 'Blocked',
  [EOI_STATUS.BOOKED]: 'Booked',
  [EOI_STATUS.CANCELLED]: 'Cancelled',
};

interface ILeadProps {
  project: IProject;
  onClose: () => void;
}

enum OTP_STATE {
  NIL,
  SENT,
  VERIFIED,
}

enum LEAD_INFO_STATE {
  NIL,
  MOBILE,
  PIN,
}

enum LEAD_STATE {
  NIL,
  DOES_NOT_EXISTS,
  EXISTS,
}

const nextButtonTextMap = {
  [LEAD_STATE.NIL]: 'Retrieve Details',
  [LEAD_STATE.DOES_NOT_EXISTS]: 'Create New Lead',
  [LEAD_STATE.EXISTS]: 'Proceed',
};

const otpEndIconNameMap: { [key: number]: IconNames } = {
  [OTP_STATE.NIL]: 'sms',
  [OTP_STATE.SENT]: 'navigate_next',
  [OTP_STATE.VERIFIED]: 'check',
};
