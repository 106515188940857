import * as Popover from '@radix-ui/react-popover';
import { AnimatePresence, motion } from 'framer-motion';

import { Clickable } from '@/atoms/Clickable';
import { Container } from '@/atoms/Container';
import { Text } from '@/atoms/Text/Text';
import { Icon } from '@/components/atoms/Icon';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { useGetFilter } from '@/molecules/Filter/hooks/FilterContext';
import genericStyles from '@/styles/genericStyles.module.css';

import { IFilterState, IIsFilterValidProps } from '.';

export function FilterTrigger(props: IFilterTriggerProps) {
  const {
    state,
    setState,
    setFilterState,
    isDateFilterValid,
    isTextFilterValid,
  } = props;

  return (
    <Popover.Trigger className={genericStyles.buttonReset}>
      <AnimatePresence>
        <motion.div>
          <FlexDeprecated
            // transition={{ duration: 3 }}
            backgroundColor='color-e5'
            spacing={0.5}
            flexRow={false}
            alignItems='center'
            borderRadius='full'
            px={0.5}
            py={0.5}>
            <AnimatePresence>
              {state?.text?.filter && (
                <motion.div
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 'auto' }}
                  exit={{ opacity: 0, width: 0 }}>
                  <Container
                    backgroundColor='color-cp-primary-96'
                    py={1.5}
                    px={{ sm: 3, md: 2 }}
                    borderRadius='full'
                    width='120px'
                    UNSAFE_STYLES={{ overflow: 'hidden' }}>
                    <Text
                      maxLines={1}
                      ellipsis
                      color={
                        isTextFilterValid
                          ? 'color-primary-32'
                          : 'color-primary-64'
                      }
                      weight='bold'
                      text={state?.text?.label}
                    />
                  </Container>
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {state?.date?.filter && (
                <motion.div
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 'auto' }}
                  exit={{ opacity: 0, width: 0 }}>
                  <Container
                    backgroundColor='color-cp-primary-96'
                    py={1.5}
                    px={{ sm: 3, md: 2 }}
                    borderRadius='full'
                    width='120px'
                    UNSAFE_STYLES={{ overflow: 'hidden' }}>
                    <Text
                      maxLines={1}
                      ellipsis
                      color={
                        isDateFilterValid
                          ? 'color-primary-32'
                          : 'color-primary-64'
                      }
                      weight='bold'
                      text={state?.date?.label}
                    />
                  </Container>
                </motion.div>
              )}
            </AnimatePresence>
            <Container py={1.5} px={{ sm: 3, md: 2 }} borderRadius='full'>
              <FlexDeprecated
                spacing={0.5}
                alignItems='center'
                justifyContent='center'>
                <Icon name='tune' size='sm' />
                <Text text='Add filter' />
              </FlexDeprecated>
            </Container>
            <AnimatePresence>
              {(state?.text || state?.date) && (
                <motion.div
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 40 }}
                  exit={{ opacity: 0, width: 0 }}>
                  <Clickable
                    onClick={(evt) => {
                      evt.stopPropagation();
                      setState(null);
                      setFilterState(null);
                    }}>
                    <FlexDeprecated
                      borderRadius='full'
                      width='40px'
                      height='40px'
                      backgroundColor='color-primary-97'
                      alignItems='center'
                      justifyContent='center'>
                      <Icon
                        color='color-status-warning-high'
                        name='delete'
                        size='sm'
                      />
                    </FlexDeprecated>
                  </Clickable>
                </motion.div>
              )}
            </AnimatePresence>
          </FlexDeprecated>
        </motion.div>
      </AnimatePresence>
    </Popover.Trigger>
  );
}
type IFilterTriggerProps = IIsFilterValidProps & {
  state: IFilterState;
  setState: ReturnType<typeof useGetFilter>[1];
  setFilterState: (filterState: IFilterState) => void;
  // onFilterSelect: (filterState: IFilterState) => void;
};
