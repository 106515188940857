import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAuthTokenLocal, setAuthTokenLocal } from '@/utils/localStorage';
import { adminUserAPISlice } from '@/admin/services/adminUserAPISlice';
import type { ICpUser } from '@/cp/interfaces';
import {
  getCpUserLocal,
  setCpUserLocal,
} from '@/cp/components/pages/CpLoginPage/cpLocalStorage';
import { cpUserAPISlice } from '@/cp/services/cpUserAPISlice';
import type { IUser } from '@/interfaces';

const cpUser = getCpUserLocal();

interface IState {
  cpUser?: ICpUser;
}

const initialState: IState = {
  cpUser: cpUser,
};

const sliceName = 'cpUserSlice';

interface ICpUserSliceState {
  [sliceName]: IState;
}

export const cpUserSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCpUser: (state, action: PayloadAction<ICpUser>) => {
      state.cpUser = action.payload;
      setCpUserLocal(state.cpUser);
    },
    removeCpUser: (state) => {
      state.cpUser = undefined;
    },

    setCpUserProfile: (state, action: PayloadAction<IUser>) => {
      if (state.cpUser) {
        state.cpUser.user = action.payload;
        setCpUserLocal(state.cpUser);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      adminUserAPISlice.endpoints.otpv2Verify.matchFulfilled,
      (state, { payload }) => {
        const { token } = payload.data;
        const authToken = getAuthTokenLocal();
        if (!authToken) {
          setAuthTokenLocal(token);
        }
      }
    );

    builder.addMatcher(
      cpUserAPISlice.endpoints.addCpUser.matchFulfilled,
      (state, { payload }) => {
        const cpUser = payload.data;
        state.cpUser = cpUser;
        setCpUserLocal(cpUser);
      }
    );

    builder.addMatcher(
      cpUserAPISlice.endpoints.getCpUserByMobile.matchFulfilled,
      (state, { payload }) => {
        const cpUser = payload.data;
        state.cpUser = cpUser;
        setCpUserLocal(cpUser);
      }
    );

    builder.addMatcher(
      cpUserAPISlice.endpoints.getCpUserApprovalStatus.matchFulfilled,
      (state, { payload }) => {
        const status = payload.data;
        if (state.cpUser) {
          state.cpUser.approved = status.approved;
          setCpUserLocal(state.cpUser);
        }
      }
    );
  },
});

export const getCpUser = (state: ICpUserSliceState) => state.cpUserSlice.cpUser;

// Action creators are generated for each case reducer function
export const { setCpUser, removeCpUser, setCpUserProfile } =
  cpUserSlice.actions;
