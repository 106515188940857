import { CSSProperties } from 'react';

import { generateStyles } from '@/atoms/common/layout';
import { CommonLayoutProps } from '@/atoms/common/layout/layout';
import { useResponsive } from '@/hooks/useResponsive';
import { Range } from '@/interfaces/range';

export const Flex = (props: IFlexProps) => {
  const {
    children,
    gap = 0,
    justifyContent = 'start',
    basis,
    direction = 'row',
    alignItems = 'start',
    wrap,
    UNSAFE_STYLES,
    UNSAFE_CLASSNAMES,
    ...layoutProps
  } = props;

  const { responsiveConstant } = useResponsive();
  const layoutStyles = generateStyles(layoutProps, responsiveConstant);

  const flexStyle = {
    display: 'flex',
    gap: `${gap}rem`,
    justifyContent,
    flexDirection: direction,
    alignItems,
    flexBasis: `${basis}%`,
    flexWrap: wrap,
    ...layoutStyles,
  };

  return (
    <div
      className={`${UNSAFE_CLASSNAMES}`}
      style={{ ...flexStyle, ...UNSAFE_STYLES }}>
      {children}
    </div>
  );
};

type IFlexProps = CommonLayoutProps & {
  children: React.ReactNode;
  gap?: number;
  direction?: CSSProperties['flexDirection'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  basis?: Range<1, 101>;
  wrap?: CSSProperties['flexWrap'];
  UNSAFE_STYLES?: Record<string, string>;
  UNSAFE_CLASSNAMES?: string;
};
