import { Icon } from '@/atoms/Icon';
import Image from '@/booking/components/common/ImageTS';
import Modal from '@/booking/components/common/modal/ModalTS';

import styles from './styles.module.css';

interface IImagePreviewModalProps {
  imageUrl: string;
  onModalClose: () => void;
}

const ImagePreviewModal = (props: IImagePreviewModalProps) => {
  const { imageUrl, onModalClose } = props;

  return (
    <Modal
      onOutsideClick={() => onModalClose()}
      additionalClasses={styles.modalWrapper}>
      <Icon name='close' onClick={onModalClose} propStyles={styles.closeIcon} />
      <Image src={imageUrl} alt='image_preview' />
    </Modal>
  );
};

export default ImagePreviewModal;
