export const TIMER_LENGTH = 300;

export const AADHAR_VALIDATION_DIGITS = 12;

export const SEARCH_INITIAL_STATE = {
  searchName: '',
  searchField: '',
  startDate: '',
  endDate: '',
};
