import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  FixedBottomContainer,
  RowContainer,
} from '@/admin/components/common/Containers';
import styles from '@/admin/components/common/PaymentConfirmationCell/styles.module.css';
import { SingleSelectOption } from '@/admin/components/common/SingleSelectOption';
import { paymentModeOptions } from '@/admin/components/pages/CMDashboard/pageBodies/MainPageBody/components/PaymentForm';
import { Button } from '@/atoms/Button';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import DropDownInput, {
  DropDownOptionType,
} from '@/booking/components/common/inputs/DropDownInput';
import FileUploadNew from '@/booking/components/common/inputs/FileUploadNew';
import TextInput from '@/booking/components/common/inputs/TextInput';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import type { INote, IPaymentOrder } from '@/booking/interfaces';
import { useCreateOrUpdatePaymentOrderMutation } from '@/booking/services/paymentsAPISlice';
import type { IProject } from '@/interfaces';
import { randomId } from '@/utils/utils';

// TODO: integrate with the actual data
const rejectionOption = [
  { text: 'Account closed/blocked' },
  { text: 'Alteration on cheque' },
  { text: 'Funds insufficient' },
  { text: 'Signature Difference' },
  { text: 'Payment stopped by Drawer' },
  { text: 'Connectivity not established' },
  { text: 'Name and contact details are not matching' },
  { text: 'Transaction ID not matching' },
  { text: 'Rectify with Bank Statement' },
];

const schema = yup.object().shape({
  transactionId: yup.string().required(),
  amount: yup.number().required(),
  comment: yup.string(),
});

interface IPaymentRejectionCellProps {
  paymentOrder: IPaymentOrder;
  project: IProject;
  onPaymentUpdate: () => void;
}
const PaymentRejectionCell = (props: IPaymentRejectionCellProps) => {
  const { paymentOrder, project, onPaymentUpdate } = props;

  const [showComment, setShowComment] = useState(false);
  const [mode, setMode] = useState(paymentOrder.mode);
  const [receiptsUrl, setReceiptsUrl] = useState(paymentOrder.receiptsUrl);
  const [notes, setNotes] = useState(paymentOrder.notes || []);
  const [selectedReason, setSelectedReason] = useState(
    paymentOrder.reasonOfFailure
  );

  const [createOrUpdatePaymentOrderAPI] =
    useCreateOrUpdatePaymentOrderMutation();

  const {
    register,
    handleSubmit,
    unregister,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleToggleComment = () => {
    setShowComment(!showComment);
  };
  const handleDropDownSelection = (value: DropDownOptionType<string>[]) => {
    setMode(value[0]?.value);
  };

  const handleSetSelectedReason = (selectedOption: string) => {
    setSelectedReason(selectedOption);
  };

  const handleAddPaymentSlips = (imageUrls: string[]) => {
    const urls = [
      ...(paymentOrder.receiptsUrl ? paymentOrder.receiptsUrl : []),
      ...imageUrls,
    ];
    setReceiptsUrl(urls);
  };

  const handleRemovePaymentSlips = (imageUrl: string) => {
    const urls = (
      paymentOrder.receiptsUrl ? paymentOrder.receiptsUrl : []
    ).filter((image) => image !== imageUrl);
    setReceiptsUrl(urls);
  };

  const handleNoteChange = (value: string) => {
    const updatedNotes: INote[] = [{ name: '', value: '' }];
    updatedNotes[0]['name'] = 'comment';
    updatedNotes[0]['value'] = value;

    setNotes(updatedNotes);
  };

  const handlePaymentReject = async (paymentData: {
    transactionId: string;
    amount: number;
    settledAt: Date;
  }) => {
    try {
      await createOrUpdatePaymentOrderAPI({
        id: paymentOrder.id,
        status: PAYMENT_ORDER_STATUS.FAILED,
        transactionId: paymentData.transactionId,
        mode: mode,
        amount: paymentData.amount,
        notes: notes,
        settledAt: paymentData.settledAt,
        receiptsUrl: receiptsUrl,
        reasonOfFailure: selectedReason,
      }).unwrap();

      onPaymentUpdate();
    } catch (error) {
      /* empty */
      //TODO: implement catch block,if possible
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handlePaymentReject)}>
        <div className={styles.contentWrapper}>
          <RowContainer>
            <DropDownInput
              options={paymentModeOptions}
              defaultSelectedOption={paymentModeOptions.find(
                ({ value }) => value === paymentOrder.mode
              )}
              lable='Payment Mode'
              onValueSelected={handleDropDownSelection}
            />
          </RowContainer>
          {/* To get the selected value Use  'selectedValue' */}
          <SingleSelectOption
            label='Reason for Failure'
            options={rejectionOption}
            onOptionClick={handleSetSelectedReason}
          />

          <RowContainer>
            <TextInput
              label='Transaction ID'
              type='text'
              defaultValue={paymentOrder.transactionId}
              placeHolder='Enter Transaction ID'
              name='transactionId'
              register={register}
              setValue={setValue}
              unregister={unregister}
              errorMessage={
                errors.transactionId && 'Transaction Id is required'
              }
            />
            <TextInput
              defaultValue={
                paymentOrder.amount ? paymentOrder.amount.toString() : ''
              }
              name='amount'
              label='Amount'
              type='number'
              placeHolder='Enter amount'
              startIconName='currency_rupee'
              register={register}
              setValue={setValue}
              unregister={unregister}
              errorMessage={errors.amount && 'Amount is required'}
            />
          </RowContainer>

          <FileUploadNew
            placeholder='Upload Documents & Images'
            label='Upload Documents'
            s3DirPath={`projects/${project.id}/paymentSlips/${randomId()}`}
            name='paymentSlips'
            appType='RELATA'
            multiple
            showDisplayInputs
            displayInputs={receiptsUrl}
            onMultipleFileUpload={handleAddPaymentSlips}
            onRemove={handleRemovePaymentSlips}
            isFilePreviewEnabled
          />
          <ConditionalRendering showIf={showComment}>
            <TextInput
              name='comment'
              label='Comment'
              type='text'
              placeHolder='Add Comment'
              defaultValue={notes.length ? notes[0].value : ''}
              register={register}
              setValue={setValue}
              unregister={unregister}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleNoteChange(e.target.value)
              }
            />
          </ConditionalRendering>
        </div>
        <FixedBottomContainer>
          <RowContainer>
            <Button accent='bordered' onClick={handleToggleComment}>
              Add Comment
            </Button>
            <Button accent='primary' type='submit'>
              Submit
            </Button>
          </RowContainer>
        </FixedBottomContainer>
      </form>
    </>
  );
};

export default PaymentRejectionCell;
