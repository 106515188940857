import { useState } from 'react';

import { Button, TBtnAccentType } from '@/atoms/Button';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import Gap from '@/atoms/Gap';
import { Icon, IconNames } from '@/atoms/Icon';
import { Text } from '@/atoms/Text/Text';
import Modal from '@/booking/components/common/modal/ModalTS';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

import styles from './styles.module.css';

export interface ICellModalProps {
  children: React.ReactNode;
  iconName?: IconNames;
  modalTitle?: string;
  modalSubTitle?: string;
  buttonAccent?: TBtnAccentType;
  text?: string;
  buttonText?: string;
  containerWidth?: 'half' | 'quarter';
  disabled?: boolean;
  buttonBeforeIcon?: IconNames;
  subText?: string;
  statusIndicator?: React.ReactNode;
  onClose?: () => void;
}

const CellModal = (props: ICellModalProps) => {
  const {
    children,
    iconName = 'details',
    modalTitle = '',
    modalSubTitle = '',
    buttonText,
    buttonAccent = 'primary',
    buttonBeforeIcon,
    containerWidth = 'half',
    disabled = false,
    text,
    subText,
    statusIndicator,
    onClose,
  } = props;

  const [modalShown, setModalShown] = useState(false);

  const openModal = () => {
    setModalShown(true);
  };
  const closeModal = () => {
    onClose?.();
    setModalShown(false);
  };

  return (
    <>
      <ConditionalRendering showIf={modalShown}>
        <Modal
          onOutsideClick={closeModal}
          additionalClasses={`${styles.modelWrap} ${styles[containerWidth]}`}>
          <div
            className={`${styles.headerWrap} ${
              modalTitle ? styles.justifyCenter : ''
            }`}>
            <FlexDeprecated flexColumn spacing={0.5}>
              <ConditionalRendering showIf={modalTitle}>
                <Text
                  size='lg'
                  weight='extrabold'
                  color='color-cp-primary-32'
                  text={modalTitle}
                />
              </ConditionalRendering>
              <ConditionalRendering showIf={modalSubTitle}>
                <>
                  <FlexDeprecated spacing={0} alignItems='baseline'>
                    {statusIndicator && (
                      <>
                        {statusIndicator}
                        <Gap numberOfSpaces={1} />
                      </>
                    )}
                    <Text
                      size='sm'
                      color='color-primary-text-grey-45'
                      text={modalSubTitle}
                    />
                  </FlexDeprecated>
                </>
              </ConditionalRendering>
            </FlexDeprecated>
            <Icon
              name='close'
              propStyles={styles.closeIcon}
              onClick={closeModal}
            />
          </div>

          <div className={styles.contentWrapper}>{children}</div>
          <span className={styles.fixedBottom} />
        </Modal>
      </ConditionalRendering>
      <ConditionalRendering showIf={buttonText}>
        <Button
          accent={buttonAccent}
          onClick={openModal}
          beforeIcon={buttonBeforeIcon && buttonBeforeIcon}
          propStyles={styles.modelBtn}
          disabled={disabled}>
          {buttonText}
        </Button>
      </ConditionalRendering>
      <ConditionalRendering showIf={!buttonText && !text}>
        <Icon
          name={iconName}
          propStyles={`${disabled && 'click-disabled--icon'} ${
            styles.modalIcon
          } `}
          onClick={!disabled ? openModal : () => {}}
        />
      </ConditionalRendering>
      <ConditionalRendering showIf={text}>
        <div
          className={styles.textWrapper}
          onClick={!disabled ? openModal : () => {}}
          data-disabled={disabled}>
          <span className={styles.text}>{text}</span>
          <span className={styles.subText} data-disabled={disabled}>
            {subText}
          </span>
        </div>
      </ConditionalRendering>
    </>
  );
};

export default CellModal;
