import { IAvatarActivityStatusProps } from '@/atoms/Avatar/AvatarActivityStatus/avatarActivityStatus';
import { AvatarProps } from '@/atoms/Avatar/avatarInterfaces';
import { Container } from '@/atoms/Container';
import { Copy } from '@/atoms/Copy';
import { Text } from '@/atoms/Text/Text';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { Link } from 'react-router-dom';

export const NameCard = (props: INameCardProps) => {
  const {
    title,
    subtitle,
    secondarySubtitle,
    avatar,
    disabled,
    isExpired,
    disableCopyToClipboard = false,
    navigationPath,
  } = props;

  const mainTitle = (
    <Container width='80%'>
      <Text
        text={title}
        size='md'
        weight='bold'
        color={
          disabled || isExpired ? 'color-primary-70' : 'color--secondary-205'
        }
        ellipsis
        maxLines={2}
      />
    </Container>
  );

  return (
    <Container>
      <FlexDeprecated alignItems='center' flexRow={false}>
        {avatar && <Container>{avatar}</Container>}

        <Container>
          {navigationPath ? (
            <Link to={navigationPath}>{mainTitle}</Link>
          ) : (
            mainTitle
          )}

          <Container>
            {secondarySubtitle && (
              <Text
                size='sm'
                text={secondarySubtitle}
                color='color-primary-57'
                weight='semibold'
              />
            )}
            {subtitle && (
              <Copy disabled={disableCopyToClipboard}>
                <Text
                  color={
                    disabled || isExpired
                      ? 'color-primary-70'
                      : 'color-primary-57'
                  }
                  size='xs'
                  weight='semibold'
                  text={subtitle}
                />
              </Copy>
            )}
          </Container>
        </Container>
      </FlexDeprecated>
    </Container>
  );
};

export interface INameCardProps {
  title: string;
  subtitle?: string;
  secondarySubtitle?: string;
  avatar?: React.ReactElement<IAvatarActivityStatusProps | AvatarProps>;
  disabled?: boolean;
  disableCopyToClipboard?: boolean;
  navigationPath?: string;
  isExpired?: boolean;
}
