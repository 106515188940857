import { useRef, useState } from 'react';
import genericStyles from 'src/styles/genericStyles.module.css';

import { DEFAULT_NULL_VALUE } from '@/admin/components/common/ExpandedDetailSection';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Icon, IconNames } from '@/atoms/Icon';
import { Text } from '@/atoms/Text/Text';
import useOnClickOutside from '@/utils/useOnClickOutside';

import styles from './styles.module.css';

export type MenuItem = {
  displayText: string;
  displaySubText?: string;
  icon: IconNames;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

// Local Interfaces
interface IMoreMenu {
  menuItems: MenuItem[];
  disabled?: boolean;
  menuIconStyles?: string;
  menuPosition?: 'right' | 'left';
}

const MoreMenu = (props: IMoreMenu) => {
  const { menuItems, disabled = false, menuPosition = 'left' } = props;

  const [menuShown, setMenuShown] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  //For Closing menu when clicked outside
  useOnClickOutside(menuRef, () => {
    return setMenuShown(false);
  });

  return (
    <div className={styles.moreMenuContainer}>
      {/* Menu Options */}
      {menuShown && (
        <div
          ref={menuRef}
          className={`${styles.MenuContainer} ${styles[menuPosition]}`}>
          {menuItems.map((menuItem) => (
            <div key={menuItem.displayText}>
              <div
                className={`${genericStyles.pointer} ${styles.individualMenuItem}`}
                onClick={menuItem.onClick}>
                <p className={styles.moreIndividualMenuLabel}>
                  {menuItem.displayText}
                  <ConditionalRendering showIf={menuItem.displaySubText}>
                    <Text
                      text={menuItem.displaySubText || DEFAULT_NULL_VALUE}
                      size='xs'
                    />
                  </ConditionalRendering>
                </p>
                <Icon
                  name={menuItem.icon as IconNames}
                  propStyles={styles.moreIndividualMenuIcon}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {/* 3 dots menu toogler Icon in Grid */}
      <div
        className={`${styles.moreMenuIconContainer} ${genericStyles.pointer} ${
          disabled ? styles.disabled : ''
        }`}>
        <Icon
          name='more_vert'
          propStyles={`${styles.moreMenuIcon}`}
          disabled={disabled}
          onClick={() => setMenuShown(!menuShown)}
        />
      </div>
    </div>
  );
};

export default MoreMenu;
