import { Colors } from '@/atoms/atoms';
import { PAYMENT_MODES, PAYMENT_ORDER_STATUS } from '@/booking/constants';
import type { IPaymentOrder } from '@/booking/interfaces';
import { toIndianRupee } from '@/utils/utils';

export const getStatusVariantByPaymentStatus = (
  paymentStatus: PAYMENT_ORDER_STATUS
): Colors => {
  let variant: Colors = 'color-primary-64';
  switch (paymentStatus) {
    case PAYMENT_ORDER_STATUS.INITIATED:
      variant = 'color--hot-indicator';
      break;
    case PAYMENT_ORDER_STATUS.PROCESSING:
      variant = 'color--hot-indicator';
      break;
    case PAYMENT_ORDER_STATUS.COMPLETED:
      variant = 'color-status-success';
      break;
    case PAYMENT_ORDER_STATUS.FAILED:
      variant = 'color-status-danger';
      break;
    default:
      variant = 'color-primary-64';
  }
  return variant;
};

export const getDisplayTextForPaymentModes = (paymentMode: string) => {
  return (
    Object.values(PAYMENT_MODES).find((mode) => mode.value === paymentMode)
      ?.displayText || ''
  );
};

export const getStatusVarientColor = (status: string) => {
  const lowerCaseStatus = status.toLowerCase();
  switch (lowerCaseStatus) {
    case 'blocked':
    case 'pending':
    case 'initiated':
    case 'processing':
      return 'color--hot-indicator';
    case 'assigned':
    case 'completed':
    case 'confirmed':
    case 'attached':
      return 'color-status-success';
    case 'mandatory':
    case 'draft':
      return 'color-primary-64';
    default:
      return 'color-primary-64';
  }
};

export const getPaymentOverallStatus = (
  paymentOrders: IPaymentOrder[]
): string => {
  if (paymentOrders.length === 0) {
    return 'Mandatory';
  }

  const statuses = paymentOrders.map((paymentOrder) =>
    paymentOrder.status.toLowerCase()
  );

  switch (true) {
    case statuses.includes('initiated') || statuses.includes('processing'):
      return 'Pending';
    case statuses.every((status) => status === 'completed'):
      return 'Completed';
    default:
      return 'Pending';
  }
};

export const getMinimumBlockAmount = (
  minimumPercentageForBlock: number,
  tokenAmount: number
): string => {
  return toIndianRupee((minimumPercentageForBlock / 100) * tokenAmount);
};
