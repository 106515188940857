import { forwardRef } from 'react';

import { generateStyles } from '@/atoms/common/layout';
import type { CommonLayoutProps } from '@/atoms/common/layout/layout';
import { useResponsive } from '@/hooks/useResponsive';

export const Container = forwardRef(
  (props: IContainerProps, ref?: React.Ref<HTMLDivElement>) => {
    const { children, UNSAFE_STYLES, UNSAFE_CLASSNAMES, ...layoutProps } =
      props;

    const { responsiveConstant } = useResponsive();
    const styles = generateStyles(layoutProps, responsiveConstant);

    return (
      <div
        ref={ref}
        className={UNSAFE_CLASSNAMES}
        style={{ ...styles, ...UNSAFE_STYLES }}>
        {children}
      </div>
    );
  }
);

Container.displayName = 'Container';

type IContainerProps = CommonLayoutProps & {
  children: React.ReactNode;
  display?: 'none' | 'inline' | 'inline-block' | 'block';
  UNSAFE_STYLES?: Record<string, string>;
  UNSAFE_CLASSNAMES?: string;
};
