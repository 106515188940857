import { ColDef } from 'ag-grid-community';

/**
 * although this component behaves like a dummy component,
 * adding props here with type helps in ts inference at Table.Column
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Column = (props: ColDef) => {
  return null;
};
