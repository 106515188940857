import { useEffect, useState } from 'react';

import { FontFamily } from '@/atoms/atoms';
import { Icon, IconNames } from '@/atoms/Icon';

import styles from './styles.module.css';

export const Button = (props: IButton) => {
  const {
    propStyles,
    title = '',
    onClick = () => {},
    type = 'button',
    link = '',
    accent = '',
    crApp = false,
    disabled = false,
    loading = false,
    beforeIcon = '',
    disabledWithAccent = false,
    children,
    fontFamily = 'roboto',
    size = 'medium',
    borderRadius = 'initial',
    form,
  } = props;

  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  const buttonStyles = {
    fontFamily: fontFamily,
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      timeout = setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  const internalClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });

    if (link) {
      window.open(`${link}`, '_blank', 'noopener noreferrer');
    }

    onClick(e);
  };

  return (
    <button
      form={form}
      type={type}
      title={title}
      style={buttonStyles}
      className={`${styles.button} ${styles[size]} ${styles[accent]} ${
        styles[borderRadius]
      } ${disabledWithAccent && styles.disabledAccent} ${propStyles} ${
        loading && 'loading-spin-container'
      } ${crApp && styles.cpButton}`}
      disabled={disabled}
      onClick={(event) => internalClickHandler(event)}>
      {isRippling && (
        <span
          className='ripple'
          style={{
            left: coords.x,
            top: coords.y,
          }}
        />
      )}
      {beforeIcon && <Icon propStyles={styles.iconStyles} name={beforeIcon} />}
      {children}
      <span className='ic2-fa-spin-blue'></span>
    </button>
  );
};

export type TBtnAccentType =
  | 'primary'
  | 'success'
  | 'danger'
  | 'warn'
  | 'secondary'
  | 'ghost'
  | 'bordered';

export type TBtnSizes = 'small' | 'medium' | 'large';
export type TBtnBorderRadius = 'rounded' | 'semiRounded' | 'initial' | 'none';

export interface IButton {
  propStyles?: string;
  fontFamily?: FontFamily;
  size?: TBtnSizes;
  title?: string;
  link?: string;
  crApp?: boolean;
  disabled?: boolean;
  loading?: boolean;
  beforeIcon?: IconNames;
  disabledWithAccent?: boolean;
  //TODO: Get button accents from design team
  accent?: TBtnAccentType;
  type?: 'submit' | 'reset' | 'button';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  borderRadius?: TBtnBorderRadius;
  form?: string;
}
