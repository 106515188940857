import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { RedirectPaths } from '@/admin/components/containers/AdminSidebar/constants/userSideBarItems';
import { TObjectId } from '@/interfaces';

export const usePathParams = () => {
  const params = useParams<IParams>();

  const pathParams = useMemo(() => {
    return {
      projectId: params.projectId,
      pageId: params.pageId as RedirectPaths,
      tabId: params.tabId,
      leadId: params.leadId,
      siteVisitId: params.siteVisitId,
    };
  }, [
    params.projectId,
    params.pageId,
    params.tabId,
    params.leadId,
    params.siteVisitId,
  ]);

  return pathParams;
};

type IParams = {
  projectId?: TObjectId;
  pageId?: string;
  tabId?: string;
  leadId?: string;
  siteVisitId?: string;
};
