import styles from './styles.module.css';

interface IRowContainerProps {
  children: React.ReactNode;
  rowGap?: number;
}

interface IFixedBottomContainerProps {
  children: React.ReactNode;
}

export const RowContainer = (props: IRowContainerProps) => {
  const { children, rowGap = 2 } = props;

  const rowStyle = {
    gap: `${rowGap}rem`,
  };

  return (
    <div style={rowStyle} className={styles.rowContainer}>
      {children}
    </div>
  );
};

export const FixedBottomContainer = (props: IFixedBottomContainerProps) => {
  const { children } = props;

  return <div className={styles.fixedBottomContainer}>{children}</div>;
};
