import { DateRange, DayPicker, DayPickerRangeProps } from 'react-day-picker';
import { Controller, useFormContext } from 'react-hook-form';

export const Date = (props: IDateProps) => {
  const { name, defaultValue, onSelect, disabled } = props;
  const {
    // setValue,
    control,
    // formState: { isSubmitting, errors },
  } = useFormContext();
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => (
        <DayPicker
          disabled={disabled}
          numberOfMonths={2}
          fromYear={2015} // FIXME : what to add here ?
          toYear={2025} // replace with current year
          captionLayout='dropdown'
          style={{
            margin: '0px',
          }}
          styles={{
            button: {
              fontSize: '14px',
            },
          }}
          mode='range'
          selected={field.value}
          onSelect={(...args) => {
            field.onChange(...args);
            onSelect?.(...args);
          }}
        />
      )}></Controller>
  );
};

type IDateProps = {
  disabled?: boolean;
  name: string;
  onSelect?: DayPickerRangeProps['onSelect'];
  defaultValue?: DateRange;
};
