import '@/utils/polyfill';
import './styles/index.scss';

import React from 'react';
import { render } from 'react-dom';

import App from '@/App';

//FIXME: @sachin is reportWebVitals required? because I don't think its functional

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({ onUnhandledRequest: 'bypass' });
}

enableMocking()
  .then(() => {
    render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch((err) => {
    console.error(err);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// datadogRumSetup();
