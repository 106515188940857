import { useEffect, useState } from 'react';

import { toTwoDigits } from '@/utils/utils';

export enum DateExpiryCountDownState {
  'EXPIRED',
  'VALID',
  'INVALID',
}

export interface IDateExpiryCountDown {
  state: DateExpiryCountDownState;
  hours: number;
  minutes: number;
  seconds: number;
}

export const dateExpiryCountDownStateInvalidValue = {
  state: DateExpiryCountDownState.INVALID,
  hours: 0,
  minutes: 0,
  seconds: 0,
};
export const dateExpiryCountDownStateExpiredValue = {
  state: DateExpiryCountDownState.EXPIRED,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export const getTimerGivenDateExpiryCountDown = (
  dateExpiryCountdown: IDateExpiryCountDown
) => {
  const hours = toTwoDigits(dateExpiryCountdown.hours);
  const minutes = toTwoDigits(dateExpiryCountdown.minutes);
  const seconds = toTwoDigits(dateExpiryCountdown.seconds);

  if (dateExpiryCountdown.hours) {
    return `${hours}:${minutes}:${seconds}`;
  }

  return `${minutes}:${seconds}`;
};

//TODO: check if can remove above function and use below function instead
export const getTimerGivenDateExpiryCountDownByUnit = (
  dateExpiryCountdown: IDateExpiryCountDown
) => {
  const NUMBER_OF_HOURS_IN_A_DAY = 24;

  const days = toTwoDigits(
    Math.floor(dateExpiryCountdown.hours / NUMBER_OF_HOURS_IN_A_DAY)
  );
  const hours = toTwoDigits(dateExpiryCountdown.hours);
  const minutes = toTwoDigits(dateExpiryCountdown.minutes);
  const seconds = toTwoDigits(dateExpiryCountdown.seconds);

  if (dateExpiryCountdown.hours > NUMBER_OF_HOURS_IN_A_DAY) {
    return `${days}d:${hours}h`;
  } else if (dateExpiryCountdown.hours) {
    return `${hours}h:${minutes}m:${seconds}s`;
  } else {
    return `${minutes}m:${seconds}s`;
  }
};

const useDateExpiryCountdown = (expiredOn?: Date) => {
  const [hourMinSecCountDownObj, setHourMinSecCountDownObj] =
    useState<IDateExpiryCountDown>(dateExpiryCountDownStateInvalidValue);

  useEffect(() => {
    if (!expiredOn) {
      setHourMinSecCountDownObj(dateExpiryCountDownStateInvalidValue);
      return;
    }
    const countDownDateTime = new Date(expiredOn).getTime();
    const timer = setInterval(() => {
      const nowDateTime = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDateTime - nowDateTime;

      if (distance < 0) {
        setHourMinSecCountDownObj(dateExpiryCountDownStateExpiredValue);
        return;
      }

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setHourMinSecCountDownObj({
        state: DateExpiryCountDownState.VALID,
        hours: days * 24 + hours,
        minutes: minutes,
        seconds: seconds,
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [expiredOn]);

  return hourMinSecCountDownObj;
};

export default useDateExpiryCountdown;
