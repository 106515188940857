import type { Colors, FontFamily, Size } from '@/atoms/atoms';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Text } from '@/atoms/Text/Text';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

import styles from './styles.module.css';

export interface IStatusIndicatorProps {
  variant?: Colors;
  label: string;
  fontSize: Size;
  fontFamily?: FontFamily;
}

const StatusIndicator = (props: IStatusIndicatorProps) => {
  const { variant = 'color-primary-64', label, fontSize, fontFamily } = props;

  const dotStyles = {
    backgroundColor: `var(--${variant})`,
  };

  return (
    <FlexDeprecated
      flexRow
      fullFlexWidth={false}
      spacing={0.5}
      alignItems='center'>
      <ConditionalRendering showIf={label}>
        <span className={styles.dot} style={dotStyles}></span>
      </ConditionalRendering>
      <Text
        size={fontSize}
        text={label}
        weight='semibold'
        fontFamily={fontFamily}
        textTransform='capitalize'
        color={variant}
      />
    </FlexDeprecated>
  );
};

export default StatusIndicator;
