import { useEffect, useRef, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { Button } from '@/atoms/Button';
import { Icon } from '@/atoms/Icon';
import useOnClickOutside from '../../../../../../utils/useOnClickOutside';

import styles from './styles.module.css';

interface DropDownOptionTypeValueObject {
  id: string;
}

export interface DropDownOptionType<T> {
  value: T;
  displayText: string;
  isDisabled?: boolean;
}

interface IDropDownInputProps<T> {
  // TODO: Support multiple selected default values
  defaultSelectedOption?: DropDownOptionType<T>;
  options: DropDownOptionType<T>[];
  multiSelect?: boolean;
  showSelectedOptionDisplayText?: boolean;
  lable: string;
  disabled?: boolean;
  name?: string;
  errorMessage?: string;
  propStyles?: string;
  isLoading?: boolean;
  resetSelection?: boolean;
  appType?: 'RELATA' | 'RELATA-CP' | 'RELATA-CR';
  // Form
  // TODO: Type these functions properly ?
  register?: Function;
  unregister?: Function;
  setValue?: Function;
  onValueSelected?: (selectedOptions: DropDownOptionType<T>[]) => void;
}

const DropDownInput = <T extends any>(props: IDropDownInputProps<T>) => {
  const {
    defaultSelectedOption,
    options,
    multiSelect = false,
    showSelectedOptionDisplayText = true,
    lable = '',
    onValueSelected,
    resetSelection,
    disabled = false,

    // Form
    register,
    unregister,
    setValue,
    name,
    errorMessage,
    appType,
    propStyles,
    isLoading,
  } = props;

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<
    DropDownOptionType<T>[]
  >([]);

  const [lastSelectedOption, setLastSelectedOption] = useState(
    defaultSelectedOption
  );

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  // useForm
  register && register(name);
  useEffect(() => {
    setValue &&
      defaultSelectedOption &&
      setValue(name, defaultSelectedOption.value, { shouldValidate: true });

    return () => {
      // Anything in here is fired on component unmount.
      unregister && unregister(name);
    };
  }, [name, setValue, unregister]);

  useEffect(() => {
    // Check if resetValue is true, and reset the dropdown if needed
    if (resetSelection) {
      setSelectedOptions([]);
      setLastSelectedOption(defaultSelectedOption);
    }
  }, [resetSelection]);

  const toggleDropDown = () => setIsDropDownOpen(!isDropDownOpen);

  const isSelectedValue = (option: DropDownOptionType<T>) => {
    return defaultSelectedOption && selectedOptions.length === 0
      ? !(option.value === defaultSelectedOption.value)
      : !isOptionInSelection(option);
  };

  const setSelectedOptionsWrapper = (
    selectedOptions: DropDownOptionType<T>[]
  ) => {
    setSelectedOptions(selectedOptions);
    onValueSelected && onValueSelected(selectedOptions);
    setValue &&
      setValue(
        name,
        multiSelect
          ? selectedOptions.map((option) => option.value)
          : selectedOptions[0]?.value,
        { shouldValidate: true }
      );
    setLastSelectedOption(
      selectedOptions.at(-1) || ({} as DropDownOptionType<T>)
    );
  };

  const handleOptionSelection = (option: DropDownOptionType<T>) => {
    if (!multiSelect) {
      // After option selection, hide dropdown for single select
      // Do not hide for multi select as user might want to select multiple options
      setIsDropDownOpen(!isDropDownOpen);
    }

    if (multiSelect) {
      if (isOptionInSelection(option)) {
        const selectionAfterRemoval = selectedOptions.filter(
          (selectionOption) => selectionOption.value !== option.value
        );
        setSelectedOptionsWrapper([...selectionAfterRemoval]);
        return;
      }
    }

    const finalSelectedOptions = multiSelect
      ? [...selectedOptions, option]
      : [option];
    setSelectedOptionsWrapper(finalSelectedOptions);
  };

  const isOptionValueObject = (
    value: any
  ): value is DropDownOptionTypeValueObject => {
    return (value as DropDownOptionTypeValueObject).id !== undefined;
  };

  const isOptionInSelection = (option: DropDownOptionType<T>) => {
    return selectedOptions.some((selectedOption) => {
      if (
        isOptionValueObject(option.value) &&
        isOptionValueObject(selectedOption.value)
      ) {
        return selectedOption.value.id === option.value.id;
      }
      return selectedOption.value === option.value;
    });
  };

  useOnClickOutside(ref, () => {
    return setIsDropDownOpen(false);
  });

  return (
    <div
      ref={ref}
      className={`${styles.dropDownInput} ${
        disabled ? styles.disabled : ''
      } ${propStyles}`}>
      <span className={styles.lable} data-app={appType}>
        {lable}
      </span>
      <div
        tabIndex={0}
        className={`pointer ${styles.inputValueContainer} ${
          disabled ? styles.disabled : ''
        }`}
        role='button'
        onKeyPress={() => toggleDropDown()}
        onClick={() => toggleDropDown()}
        data-app={appType}>
        <div className={styles.inputValue}>
          {(showSelectedOptionDisplayText &&
            (lastSelectedOption?.displayText ||
              defaultSelectedOption?.displayText)) ||
            'Select an Option'}
        </div>
        <div
          className={`${styles.iconContainer} ${
            isDropDownOpen ? styles.iconUp : ''
          }`}>
          <Icon name='arrow_drop_down' />
        </div>
      </div>
      <AnimatePresence>
        {isDropDownOpen && (
          <motion.ul
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -4 }}
            className={`${styles.options} ${
              isLoading && `loading-spin-container ${styles.optionLoading}`
            }`}>
            {isLoading ? (
              <span className='loading-spin' />
            ) : (
              options.map((option, index: number) => (
                <li key={index}>
                  <Button
                    propStyles='dropDownOption'
                    disabled={option.isDisabled}
                    onClick={() => {
                      handleOptionSelection(option);
                    }}>
                    <span>{option.displayText}</span>
                    <span className={styles.correctTickicon}>
                      {isSelectedValue(option) || <Icon name='check' />}
                    </span>
                  </Button>
                </li>
              ))
            )}
          </motion.ul>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {errorMessage && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`${styles.inputFieldError}`}>
            {errorMessage}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DropDownInput;
