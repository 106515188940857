import type { IEOI } from '@/admin/interfaces';
import type { IProject } from '@/interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IState {
  project: IProject;
  leadUserEOIs: IEOI[];
  selectedEoi?: IEOI;
}

const initialState: IState = {
  project: {} as IProject,
  leadUserEOIs: [] as IEOI[],
};

const sliceName = 'projectSlice';

interface IProjectSlice {
  [sliceName]: IState;
}

export const projectSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<IProject>) => {
      state.project = action.payload;
    },
    setLeadUserEOIs: (state, action: PayloadAction<IEOI[]>) => {
      state.leadUserEOIs = action.payload;
    },
    setSelectedEoi: (state, action: PayloadAction<IEOI | undefined>) => {
      state.selectedEoi = action.payload;
    },

    resetSelectedEoi: (state) => {
      state.selectedEoi = initialState.selectedEoi;
    },
  },
});

export const { setProject, setLeadUserEOIs, setSelectedEoi, resetSelectedEoi } =
  projectSlice.actions;

export const getProject = (state: IProjectSlice) => state.projectSlice.project;
export const getLeadUserEOIs = (state: IProjectSlice) =>
  state.projectSlice.leadUserEOIs;
export const getSelectedEoi = (state: IProjectSlice) =>
  state.projectSlice.selectedEoi;
