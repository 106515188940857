import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { IProject } from '@/interfaces';
import { cpProjectAPISlice } from '@/cp/services/cpProjectAPISlice';

interface IState {
  projects?: IProject[];
  type: 'CR' | 'CP'; // CR - Passcode C45, CP - Channel Partner
}

const initialState: IState = {
  projects: [],
  type: 'CR',
};

const sliceName = 'cpAppSlice';

interface ICpAppSliceState {
  [sliceName]: IState;
}

export const cpAppSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCpAppType: (state, action: PayloadAction<'CR' | 'CP'>) => {
      state.type = action.payload;
    },

    setCpAppProjects: (state, action: PayloadAction<IProject[]>) => {
      state.projects = action.payload;
    },
    removeCpAppProjects: (state) => {
      state.projects = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      cpProjectAPISlice.endpoints.getClubRhodiumProjects.matchFulfilled,
      (state, { payload }) => {
        const projects = payload;
        state.projects = projects;
      }
    );
  },
});

// TODO: Cache project list in local storage
export const getCpAppProjects = (state: ICpAppSliceState) =>
  state.cpAppSlice.projects;
export const getCpAppType = (state: ICpAppSliceState) => state.cpAppSlice.type;

// Action creators are generated for each case reducer function
export const {
  setCpAppType,

  setCpAppProjects,
  removeCpAppProjects,
} = cpAppSlice.actions;
