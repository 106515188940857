import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Icon } from '@/atoms/Icon';
import { Text } from '@/atoms/Text/Text';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

import styles from './styles.module.css';

interface ISingleSelectOptionProps {
  options: { id?: string; text: string; value?: string }[];
  label: string;
  onOptionClick: (selectedOption: string) => void;
  isIconValueEnabled?: boolean;
  defaultOption?: string;
  errorMessage?: string | boolean;
}

export const SingleSelectOption = (props: ISingleSelectOptionProps) => {
  const {
    options,
    label,
    onOptionClick,
    isIconValueEnabled = false,
    defaultOption,
    errorMessage = '',
  } = props;

  const [activeOption, setActiveOption] = useState(defaultOption);

  const handleSetActiveOption = (option: string) => {
    setActiveOption(option);
    onOptionClick(option);
  };

  return (
    <div className={styles.selectionWrapper}>
      <p className={styles.labelText}>{label}</p>
      <div className={styles.itemsWrapper}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`${styles.selectionItem} ${
              option.text === activeOption ? styles.active : ''
            }`}
            onClick={() => handleSetActiveOption(option.text)}>
            <FlexDeprecated
              flexRow
              alignItems='center'
              justifyContent='space-between'
              spacing={0}>
              <FlexDeprecated flexRow spacing={0} fullFlexWidth={false}>
                {option.text}
              </FlexDeprecated>
              <ConditionalRendering showIf={!isIconValueEnabled}>
                <FlexDeprecated
                  flexRow
                  alignItems='center'
                  spacing={1}
                  fullFlexWidth={false}>
                  <ConditionalRendering showIf={option.text !== activeOption}>
                    <>{option.value && <Text text={option.value} />}</>
                  </ConditionalRendering>
                  <ConditionalRendering showIf={option.text === activeOption}>
                    <Icon name='done' propStyles={styles.doneIcon} />
                  </ConditionalRendering>
                </FlexDeprecated>
              </ConditionalRendering>
              <ConditionalRendering showIf={isIconValueEnabled}>
                <FlexDeprecated
                  flexRow
                  alignItems='center'
                  spacing={1}
                  fullFlexWidth={false}>
                  {option.value && <Text text={option.value} />}
                  <ConditionalRendering showIf={option.text === activeOption}>
                    <Icon name='done' propStyles={styles.doneIcon} />
                  </ConditionalRendering>
                </FlexDeprecated>
              </ConditionalRendering>
            </FlexDeprecated>
          </div>
        ))}
      </div>
      <AnimatePresence>
        <ConditionalRendering showIf={errorMessage}>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <span className={styles.errorsWrapper}>
              <Text
                color='color-status-error'
                size='xs'
                text={errorMessage as string}
                weight='light'
              />
            </span>
          </motion.p>
        </ConditionalRendering>
      </AnimatePresence>
    </div>
  );
};
