import type { FontFamily } from '@/atoms/atoms';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import Gap from '@/atoms/Gap';
import { Text } from '@/atoms/Text/Text';

import styles from './styles.module.css';

interface ITextWithPrefixAndSuffixProps {
  textPrefix?: string;
  textSuffix?: string;
  highlightedText: string;
  fontFamily?: FontFamily;
}

export const TextWithPrefixAndSuffix = (
  props: ITextWithPrefixAndSuffixProps
) => {
  const {
    textPrefix = '',
    textSuffix = '',
    highlightedText,
    fontFamily,
  } = props;

  return (
    <div className={styles.textContainer}>
      <ConditionalRendering showIf={textPrefix}>
        <>
          <Text text={textPrefix} fontFamily={fontFamily} />
          <Gap numberOfSpaces={1} />
        </>
      </ConditionalRendering>
      <Text text={highlightedText} weight='bold' fontFamily={fontFamily} />
      <Gap numberOfSpaces={1} />
      <Text text={textSuffix} fontFamily={fontFamily} />
    </div>
  );
};
