import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { projectsAPISlice } from '@/admin/services/projectsAPISlice';
import type { IProject } from '@/interfaces';

const sliceName = 'projectDetailsAdminSlice';

interface IState {
  project?: IProject;
}

interface IProjectSliceState {
  [sliceName]: IState;
}
interface IInitialProjectState {
  project?: IProject;
}
const initialState: IInitialProjectState = {
  project: undefined,
};

export const projectDetailsAdminSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setProjectDetails: (state, action: PayloadAction<IProject | undefined>) => {
      state.project = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      projectsAPISlice.endpoints.getProjectsByProjectId.matchFulfilled,
      (state, { payload }) => {
        state.project = payload;
      }
    );
  },
});

export const getProjectDetails = (state: IProjectSliceState) =>
  state[sliceName].project;

export const { setProjectDetails } = projectDetailsAdminSlice.actions;
