import { avatarColorList } from '@/atoms/Avatar/const';

const DEFAULT_COLOR_IF_NO_STRING = 0;
const STR_INDEX_TO_GENERATE_COLOR = 1;
const ASCII_CODE_CHAR_a = 96;

export function getAvatarColorFromString(str: string) {
  if (!str || str.length === 1)
    return avatarColorList[DEFAULT_COLOR_IF_NO_STRING];

  const index =
    (str.toLowerCase().charCodeAt(STR_INDEX_TO_GENERATE_COLOR) -
      ASCII_CODE_CHAR_a) %
    avatarColorList.length;
  return avatarColorList[index];
}
