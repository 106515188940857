import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { TObjectId, ICpLead } from '@/interfaces';

interface IState {
  cpLead?: ICpLead;
}

const sliceName = 'cpLeadSlice';

interface ICpLeadSliceState {
  [sliceName]: IState;
}

interface ICpLeadInitialState {
  cpLead: {
    id: TObjectId;
    name: string;
    mobileLastFourDigits: string;
  };
}

const initialState: ICpLeadInitialState = {
  cpLead: {
    id: '',
    name: '',
    mobileLastFourDigits: '',
  },
};

export const cpLeadSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCpLead: (state, action: PayloadAction<ICpLead>) => {
      state.cpLead = action.payload;
    },
  },
});

export const getCpLead = (state: ICpLeadSliceState) => state.cpLeadSlice.cpLead;

// Action creators are generated for each case reducer function
export const { setCpLead } = cpLeadSlice.actions;
