import { JsonParam, useQueryParam } from 'use-query-params';

import { IFilterState } from '@/molecules/Filter';
import { FilterContext } from '@/molecules/Filter/hooks/FilterContext';

type WithFilterProps = Record<string, never>;

export function WithFilter<TProps>(Component: React.ComponentType<TProps>) {
  return function FilterComponent(props: TProps & WithFilterProps) {
    const [state, setState] = useQueryParam<IFilterState>('filter', JsonParam);

    return (
      <FilterContext.Provider value={[state, setState]}>
        <Component {...props} />
      </FilterContext.Provider>
    );
  };
}
