import { UnitStatus } from '@/constants/status';
import { ONE_CRORE } from '@/constants/index';
import type { IUnit, IProject } from '@/interfaces';
import { UNIT_OF_MEASUREMENT } from '@/booking/constants';

export interface IPreferenceItem {
  from?: number;
  to?: number;
  name?: string;
  isSelected: boolean;
  availableUnitCount: number;
  unitIds: string[];
  type: 'LIST' | 'PRICE' | 'AREA';
}

export interface IFilterPreferenceType {
  key: string;
  name: string;
  items: IPreferenceItem[];
  defaultItem: IPreferenceItem;
  type: 'LIST' | 'PRICE' | 'AREA';
}

export type FilterPreferences = {
  [key: string]: IFilterPreferenceType;
};

export type AppliedFilters = {
  [key: string]: (string | { from: number; to: number })[];
};

export const constructFilterPreferences = (
  unitList: IUnit[] = []
): FilterPreferences => {
  const filterPreferences: FilterPreferences = {};
  const preferenceTypes: FilterPreferences = {
    type: {
      key: 'type',
      name: 'Bedrooms',
      type: 'LIST',
      items: [],
      defaultItem: {
        type: 'LIST',
        name: '1 BHK',
        isSelected: false,
        availableUnitCount: 0,
        unitIds: [],
      },
    },
    view: {
      key: 'view',
      name: 'Unit View',
      type: 'LIST',
      items: [],
      defaultItem: {
        type: 'LIST',
        name: 'North',
        isSelected: false,
        availableUnitCount: 0,
        unitIds: [],
      },
    },
    facing: {
      key: 'facing',
      name: 'Unit Facing',
      type: 'LIST',
      items: [],
      defaultItem: {
        type: 'LIST',
        name: 'Courtyard Facing',
        isSelected: false,
        availableUnitCount: 0,
        unitIds: [],
      },
    },
    totalInclusiveAmount: {
      key: 'totalInclusiveAmount',
      name: 'Price Range',
      type: 'PRICE',
      items: [
        {
          type: 'PRICE',
          from: 0,
          to: 0.5,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 0.5,
          to: 1,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 1,
          to: 2,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 2,
          to: 3,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 3,
          to: 4,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 4,
          to: 5,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 5,
          to: 10,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 10,
          to: 20,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 20,
          to: 100,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        // TODO: Make list dynamic based rage of unit price
      ],
      defaultItem: {
        type: 'PRICE',
        isSelected: false,
        availableUnitCount: 0,
        unitIds: [],
      },
    },
    area: {
      key: 'area',
      name: 'Built Up Area',
      type: 'AREA',
      items: [
        {
          type: 'AREA',
          from: 0,
          to: 3000,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'AREA',
          from: 3000,
          to: 6000,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'AREA',
          from: 6000,
          to: 10000,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        // TODO: Make list dynamic based rage of unit area
      ],
      defaultItem: {
        type: 'AREA',
        isSelected: false,
        availableUnitCount: 0,
        unitIds: [],
      },
    },
  };

  unitList
    .filter((unit) => unit.status === UnitStatus.AVAILABLE)
    .forEach((unit) => {
      Object.values(preferenceTypes).forEach((preferenceType) => {
        const type = preferenceType.key;

        if (!filterPreferences[type]) {
          filterPreferences[type] = JSON.parse(JSON.stringify(preferenceType));
        }

        let itemIndex: number;
        if (preferenceType.type === 'PRICE') {
          itemIndex = filterPreferences[type].items.findIndex(
            (item) =>
              item.from! <=
                unit.costSheets[0]?.totalInclusiveAmount / ONE_CRORE &&
              item.to! >= unit.costSheets[0]?.totalInclusiveAmount / ONE_CRORE
          );
        } else if (preferenceType.type === 'AREA') {
          const SQMT_TO_SQFT = 10.7639;
          let value = unit.area.value;
          if (unit.area.unit === UNIT_OF_MEASUREMENT.SQMT) {
            value = value * SQMT_TO_SQFT;
          }
          itemIndex = filterPreferences[type]?.items.findIndex(
            (item) => item.from! <= value && item.to! >= value
          );
        } else if (preferenceType.key === 'type') {
          // Monkey patching - Bedrooms
          const typeName = unit[type as keyof IUnit] as string;
          itemIndex = filterPreferences[type].items.findIndex(
            (item) => item.name === typeName.split('-')[0]
          );
        } else {
          // Here, 'type' can only be the keys of IUnit, so we use keyof IUnit
          itemIndex = filterPreferences[type as keyof IUnit]?.items.findIndex(
            (item) => item.name === unit[type as keyof IUnit]
          );
        }

        if (itemIndex === -1) {
          const { defaultItem } = filterPreferences[type];
          const defaultItemNew: IPreferenceItem = JSON.parse(
            JSON.stringify(defaultItem)
          );
          defaultItemNew.name = unit[type as keyof IUnit] as string;
          // Monkey patching - Bedrooms
          if (type === 'type') {
            defaultItemNew.name = unit[type]?.split('-')[0];
          }
          filterPreferences[type].items.push(defaultItemNew);
          itemIndex = filterPreferences[type].items.length - 1;
        }
        filterPreferences[type].items[itemIndex].availableUnitCount++;
        filterPreferences[type].items[itemIndex].unitIds?.push(unit.id);
      });
    });

  return filterPreferences;
};

function padUnitNumber(unitNumber: string = '') {
  return unitNumber.toString().padStart(2, '0');
}

export const getConfigDetailsFromPath = (
  configPath: string,
  project: IProject
) => {
  if (!configPath) {
    return {};
  }
  const unitPath = configPath.replace('a-', '').split('-x-');
  const blockName = unitPath[1];
  let unitNumberDigit = unitPath[2];
  unitNumberDigit = padUnitNumber(unitNumberDigit);

  //TODO: correction need to make helios and future projects with generic
  //MasterSVG
  // id="a-{towerName/SectorName}-x-end"
  // TowerSVG
  // id="a-{applicableFloorplan}-x-{blockName}-x-{unitNumber}-x-end
  //a-na-x-1-x-end
  return { blockName, unitNumberDigit };
};

export const recreateConfigPath = (
  blockName: string,
  unitNumberDigit: string
) => {
  const configPath = `a-na-x-${blockName}-x-${unitNumberDigit}-x-end`;
  return configPath;
};

export const computeSelectedPreferences = (
  preferenceContainers: FilterPreferences
) => {
  const appliedFilters: AppliedFilters = {};

  Object.values(preferenceContainers).forEach((preferenceContainer) => {
    appliedFilters[preferenceContainer.key] = [];
    preferenceContainer.items.forEach((item) => {
      const { from, to, name } = item;
      if (item.isSelected) {
        if (
          ['area', 'totalInclusiveAmount'].includes(preferenceContainer.key) &&
          from &&
          to
        ) {
          appliedFilters[preferenceContainer.key].push({
            from,
            to,
          });
        } else if (name) {
          appliedFilters[preferenceContainer.key].push(name);
        }
      }
    });
  });
  return appliedFilters;
};

export const floorPlanFileName = (unit: IUnit) => {
  const fileName =
    unit?.type.replaceAll(' ', '').toLowerCase().replace('.', '-x-point-x-') +
    '.jpg';
  return fileName;
};
