import { useState } from 'react';

import { FixedBottomContainer } from '@/admin/components/common/Containers';
import { HistoryCard } from '@/admin/components/common/HistoryCard';
import { AllocationBreadCrumbs } from '@/admin/components/pages/AddAllocation/AllocationFooter';
import { PaymentDetailsModal } from '@/admin/components/pages/ProjectAllocations/PaymentDetailsModal';
import {
  getDisplayTextForPaymentModes,
  getStatusVariantByPaymentStatus,
} from '@/admin/components/pages/ProjectAllocations/utils';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Copy } from '@/atoms/Copy';
import { ModalWrapper } from '@/atoms/ModalWrapper';
import { Text } from '@/atoms/Text/Text';
import { IPaymentOrder } from '@/booking/interfaces';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { DateUtils } from '@/utils/DateUtils';
import { toIndianRupee } from '@/utils/utils';

import styles from './styles.module.css';

export const PaymentHistoryModal = (props: IPaymentHistoryModalProps) => {
  const { paymentOrders, leadName, onClose, showBreadCrumb } = props;

  const [showPaymentReason, setShowPaymentReason] = useState(false);
  const [selectedPaymentOrder, setSelectedPaymentOrder] =
    useState<IPaymentOrder>();

  const handleOpenPaymentDetails = (payment: IPaymentOrder) => {
    setShowPaymentReason(true);
    setSelectedPaymentOrder(payment);
  };

  if (showPaymentReason && selectedPaymentOrder) {
    return (
      <PaymentDetailsModal
        title={`Payment on, ${
          selectedPaymentOrder.createdAt &&
          DateUtils.format(
            selectedPaymentOrder.createdAt,
            DateUtils.FormatTo.MONTH_DATE
          )
        } `}
        paymentOrder={selectedPaymentOrder}
        onClose={() => setShowPaymentReason(false)}
        isPaymentReasonShown
      />
    );
  }

  return (
    <ModalWrapper
      containerWidth='quarter'
      modalTitle={`Payment History for ${leadName}`}
      subtitle={`Explore all payments details for ${leadName} `}
      onClose={onClose}>
      <div className={styles.paymentContainer}>
        <FlexDeprecated flexColumn spacing={2} alignItems='normal'>
          {paymentOrders.map((payment) => (
            <HistoryCard
              endIcon={{
                name: 'info_outlined',
                action: () => handleOpenPaymentDetails(payment),
              }}
              historyDetails={{
                iconName: 'payments_outlined',
                prefixInfo:
                  payment.createdAt &&
                  DateUtils.format(
                    payment.createdAt,
                    DateUtils.FormatTo.MONTH_DATE
                  ),
                suffixInfo: getDisplayTextForPaymentModes(payment.mode),
                highlightedInfo: `${toIndianRupee(payment.amount)}`,
                status: payment.status.toLowerCase(),
                statusSuffix: (
                  <FlexDeprecated
                    spacing={1}
                    alignItems='center'
                    justifyContent='center'
                    fullFlexWidth={false}>
                    <Text
                      text='TxID :'
                      size='xs'
                      weight='light'
                      color='color-primary-64'
                    />
                    <Copy>
                      <Text
                        text={payment.transactionId}
                        size='xs'
                        weight='light'
                        color='color-primary-64'
                      />
                    </Copy>
                  </FlexDeprecated>
                ),
                statusVariant: getStatusVariantByPaymentStatus(payment.status),
                statusIndicatorFontSize: 'xs',
              }}
              key={payment.id}
            />
          ))}
        </FlexDeprecated>
      </div>
      <ConditionalRendering showIf={showBreadCrumb}>
        <FixedBottomContainer>
          <FlexDeprecated flexRow justifyContent='space-between'>
            <AllocationBreadCrumbs activeIndex={2} />
          </FlexDeprecated>
        </FixedBottomContainer>
      </ConditionalRendering>
    </ModalWrapper>
  );
};

export interface IPaymentHistoryModalProps {
  showBreadCrumb?: boolean;
  leadName: string;
  paymentOrders: IPaymentOrder[];
  onClose: () => void;
}
