import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { IApplicant } from '@/booking/interfaces';

export enum ADD_APPLICANT_STATES {
  BASIC_INFO,
  DEMOGRAPHIC_INFO,
  ADDRESS_INFO,
  KYC_INFO,
}

const initialState: IState = {
  addApplicantStates: ADD_APPLICANT_STATES.BASIC_INFO,
  applicantsList: [],
  verifiedApplicantMobiles: [],
};

const sliceName = 'addApplicantSlice';

export const addApplicantSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setAddApplicantState: (
      state,
      action: PayloadAction<ADD_APPLICANT_STATES>
    ) => {
      state.addApplicantStates = action.payload;
    },

    setApplicantDetails: (
      state,
      action: PayloadAction<IApplicant | undefined>
    ) => {
      state.applicantDetails = action.payload;
    },

    setCurrentApplicant: (
      state,
      action: PayloadAction<IApplicant | undefined>
    ) => {
      state.currentApplicant = action.payload;
    },

    setApplicantsList: (state, action: PayloadAction<IApplicant[]>) => {
      state.applicantsList = action.payload;
    },

    setVerifiedApplicantMobiles: (state, action: PayloadAction<string[]>) => {
      state.verifiedApplicantMobiles = action.payload;
    },
  },
});

export const {
  setAddApplicantState,
  setApplicantDetails,
  setCurrentApplicant,
  setApplicantsList,
  setVerifiedApplicantMobiles,
} = addApplicantSlice.actions;

export const getAddApplicantState = (state: IAddApplicantSlice) =>
  state.addApplicantSlice.addApplicantStates;

export const getApplicantDetails = (state: IAddApplicantSlice) =>
  state.addApplicantSlice.applicantDetails;

export const getCurrentApplicant = (state: IAddApplicantSlice) =>
  state.addApplicantSlice.currentApplicant;

export const getApplicantsList = (state: IAddApplicantSlice) =>
  state.addApplicantSlice.applicantsList;

export const getVerifiedApplicantMobiles = (state: IAddApplicantSlice) =>
  state.addApplicantSlice.verifiedApplicantMobiles;
export interface IAddApplicantSlice {
  [sliceName]: IState;
}

interface IState {
  addApplicantStates: ADD_APPLICANT_STATES;
  applicantDetails?: IApplicant;
  currentApplicant?: IApplicant;
  applicantsList: IApplicant[];
  verifiedApplicantMobiles: string[];
}
