import { generateStyles } from '@/atoms/common/layout';
import { CommonLayoutProps } from '@/atoms/common/layout/layout';
import { useResponsive } from '@/hooks/useResponsive';
import type { Range } from '@/interfaces/range';

import styles from './styles.module.css';

export const FlexDeprecated = (props: IFlexContainerProps) => {
  const {
    children,
    spacing = 2,
    flexColumn = false,
    flexRow = true,
    justifyContent = 'start',
    alignItems = flexColumn ? 'start' : 'end',
    fullFlexWidth = true,
    flexBasis,
    UNSAFE_STYLES,
    UNSAFE_CLASSNAMES,
    ...layoutProps
  } = props;

  const { responsiveConstant } = useResponsive();
  const layoutStyles = generateStyles(layoutProps, responsiveConstant);

  const flexStyle = {
    gap: `${spacing}rem`,
    justifyContent: justifyContent,
    alignItems: alignItems,
    flexBasis: `${flexBasis}%`,
    ...layoutStyles,
  };

  return (
    <div
      className={`${styles.wrapper} ${flexColumn && styles.column} ${
        flexRow && styles.row
      } ${fullFlexWidth && styles.fullFlexWidth} ${UNSAFE_CLASSNAMES}`}
      style={{ ...flexStyle, ...UNSAFE_STYLES }}>
      {children}
    </div>
  );
};

type IFlexContainerProps = CommonLayoutProps & {
  children: React.ReactNode;
  spacing?: number;
  flexColumn?: boolean;
  flexRow?: boolean;
  fullFlexWidth?: boolean;
  justifyContent?: 'start' | 'center' | 'end' | 'space-between';
  alignItems?: 'center' | 'end' | 'start' | 'baseline' | 'normal';
  flexBasis?: Range<1, 101>;
  UNSAFE_STYLES?: Record<string, string>;
  UNSAFE_CLASSNAMES?: string;
};
