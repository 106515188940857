import useToast from '@/hooks/useToast';
import { useEffect } from 'react';

const useCheckInternetSpeed = () => {
  const [addToast] = useToast();

  const SECONDS = 1000;
  const interval = 90 * SECONDS;
  const threshold = 0.9;

  useEffect(() => {
    const checkInternetSpeed = async () => {
      try {
        const connection =
          (navigator as any).connection ||
          (navigator as any).mozConnection ||
          (navigator as any).webkitConnection;

        if (connection) {
          const speedMbps = connection.downlink;

          if (speedMbps < threshold) {
            addToast({
              type: 'ERROR',
              primaryMessage: 'Weak internet Connection',
              timeout: 3000,
            });
          }
        }
      } catch (error) {
        console.error('Error checking internet speed:', error);
      }
    };

    checkInternetSpeed();
    const intervalId = setInterval(checkInternetSpeed, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [threshold, interval]);
};

export default useCheckInternetSpeed;
