import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IState {
  bookingRoutesCurrentPage: string;
}

const initialState: IState = {
  bookingRoutesCurrentPage: 'unit-selection',
};

const sliceName = 'bookingEngineRoutesSlice';

interface IBookingEngineRoutesSlice {
  [sliceName]: IState;
}

export const bookingEngineRoutesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setBookingRoutesCurrentPage: (state, action: PayloadAction<string>) => {
      state.bookingRoutesCurrentPage = action.payload;
    },
  },
});

export const { setBookingRoutesCurrentPage } = bookingEngineRoutesSlice.actions;
export const getBookingRoutesCurrentPage = (state: IBookingEngineRoutesSlice) =>
  state.bookingEngineRoutesSlice.bookingRoutesCurrentPage;
