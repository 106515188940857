import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PrimaryTitleWithDescription from '@/admin/components/common/PrimaryTitleWithDescription';
import {
  CMDashboardStates,
  getDateExpiryCountDown,
  setCMDashboardState,
  setRecentPaymentOrder,
} from '@/admin/components/pages/CMDashboard/cmDashboardSlice';
import PaymentForm, {
  IPaymentFormData,
} from '@/admin/components/pages/CMDashboard/pageBodies/MainPageBody/components/PaymentForm';
import { MainPageBodyBodyL } from '@/admin/components/pages/CMDashboard/pageBodies/MainPageBody/MainPageBodyL';
import {
  useLazyGetUnitCartQuery,
  useUpdateUnitCartMutation,
} from '@/api/unitCart';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import { useCreateOrUpdatePaymentOrderMutation } from '@/booking/services/paymentsAPISlice';
import { useBlockUnitByUnitCartIdMutation } from '@/booking/services/unitsAPISlice';
import { getUnitCart, setUnitCart } from '@/booking/slices/selectedUnit';
import { UNIT_STATUSES_INDEX, UnitStatus } from '@/constants/status';
import type { IUnitCart } from '@/cp/interfaces';
import useToast from '@/hooks/useToast';
import type { ICostSheet, IErrorResponse, IProject } from '@/interfaces';
import { isEmpty } from '@/utils/utils';

interface IBookingPaymentFormProps {
  project: IProject;
}

export const shouldBlock = (unitCart: IUnitCart, project: IProject) => {
  if (
    UNIT_STATUSES_INDEX[unitCart.unit.status] !==
    UNIT_STATUSES_INDEX[UnitStatus.RESERVED]
  )
    return false;

  const selectedCostSheet = unitCart.costSheets.find(
    (costSheet) => costSheet.id === unitCart.selectedCostSheetId
  ) as ICostSheet;

  let totalAmountPaid = unitCart.paymentOrders.reduce(
    (sum, payment) =>
      payment.status !== PAYMENT_ORDER_STATUS.FAILED
        ? sum + payment.amount
        : sum,
    0
  );

  if (unitCart.eoi) {
    totalAmountPaid = unitCart.eoi.paymentOrders.reduce(
      (sum, payment) =>
        payment.status !== PAYMENT_ORDER_STATUS.FAILED
          ? sum + payment.amount
          : sum,
      totalAmountPaid
    );
  }
  const minimumPercentageAmountForBlockUnit =
    project.booking.minimumPercentageAmountFor.block / 100;

  const canBlock =
    totalAmountPaid >=
    selectedCostSheet.tokenAmount * minimumPercentageAmountForBlockUnit;

  return canBlock;
};

const BookingPaymentForm = (props: IBookingPaymentFormProps) => {
  const { project } = props;

  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const unitCart = useSelector(getUnitCart) as IUnitCart;

  const [updateUnitCartAPI] = useUpdateUnitCartMutation();
  const [createOrUpdatePaymentOrderAPI] =
    useCreateOrUpdatePaymentOrderMutation();
  const [blockUnitByUnitCartIdAPI] = useBlockUnitByUnitCartIdMutation();
  const [getUnitCartAPI] = useLazyGetUnitCartQuery();
  const dateExpiryCountDown = useSelector(getDateExpiryCountDown);

  const [addToast] = useToast();

  const handlePaymentRequest = async (data: IPaymentFormData) => {
    try {
      setIsSubmitting(true);
      const { data: order } = await createOrUpdatePaymentOrderAPI({
        transactionId: data.transactionId,
        mode: data.mode,
        amount: data.amount,
        status: PAYMENT_ORDER_STATUS.INITIATED,
        receiptsUrl: data.slips,
        unitCart: unitCart.id,
      }).unwrap();
      const paymentOrders = unitCart?.paymentOrders || [];
      const newPaymentOrders = [...paymentOrders, order];
      const unitCartBody = {
        id: unitCart.id,
        paymentOrders: newPaymentOrders.map((order) => order.id),
        unit: unitCart.unit.id,
        user: unitCart.user.id,
      };
      const { data: unitCartData } = await updateUnitCartAPI({
        ...unitCartBody,
      }).unwrap();

      if (!isEmpty(unitCartData)) {
        dispatch(setUnitCart(unitCartData));
      }

      if (shouldBlock(unitCartData, project)) {
        await blockUnitByUnitCartIdAPI(unitCart.id).unwrap();
        getUnitCartAPI({
          unit_id: unitCart.unit.id,
          user_id: unitCart.user.id,
        });
      }

      dispatch(setRecentPaymentOrder(order));
      dispatch(setCMDashboardState(CMDashboardStates.BOOKING_PAYMENT_SUMMARY));
    } catch (error) {
      const errMsg = (error as IErrorResponse).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Unit is not reserve, please reserve again',
        timeout: 1000,
      });
      dispatch(setCMDashboardState(CMDashboardStates.UNIT_RESERVE));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSetCMDashboardState = () => {
    dispatch(setCMDashboardState(CMDashboardStates.UNIT_BLOCK));
  };

  return (
    <>
      <PrimaryTitleWithDescription
        title='Lead Payments'
        description='Add details for the lead payments'
      />
      <MainPageBodyBodyL>
        <PaymentForm
          project={project}
          showFooterBtnLoading={isSubmitting}
          onBackButtonClick={handleSetCMDashboardState}
          onSubmit={handlePaymentRequest}
          dateExpiryCountDown={dateExpiryCountDown}
        />
      </MainPageBodyBodyL>
      {/* <MainPageBodyFooterL>
        <FlexibleBottomButtons></FlexibleBottomButtons>
      </MainPageBodyFooterL> */}
    </>
  );
};

export default BookingPaymentForm;
