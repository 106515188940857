import { createContext, useContext, useMemo } from 'react';
import { useQueryParam } from 'use-query-params';

import { IFilterState } from '@/molecules/Filter';
import { getFilterModel } from '@/molecules/Table/Main';

export const FilterContext = createContext<
  [IFilterState, ReturnType<typeof useQueryParam<IFilterState>>[1]]
>([null, () => {}]);

export const useGetFilter = () => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useGetFilterRef must be used within a FilterContext');
  }

  const [state, setState] = context;
  const filterModel = useMemo(() => getFilterModel(state), [state]);
  return [state, setState, filterModel] as const;
};

/**
 * 1. filter should change only when applied ??
 */
