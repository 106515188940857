import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILeadDetails } from '@/admin/components/pages/CMDashboard/pageBodies/MainPageBody/LeadDetails';
import { siteVisitAPI } from '@/admin/services/siteVisitAPI';

const sliceName = 'leadDetailsDuringSiteVisit';

interface IState {
  leadDetails?: ILeadDetails;
}

interface IProjectSliceState {
  [sliceName]: IState;
}

const initialState: IState = {
  leadDetails: undefined,
};

export const leadDetailsDuringSiteVisitSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLeadDetailsDuringSiteVisit: (
      state,
      action: PayloadAction<ILeadDetails>
    ) => {
      state.leadDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      siteVisitAPI.endpoints.getLeadDetailsDuringSiteVisit.matchFulfilled,
      (state, { payload }) => {
        state.leadDetails = payload;
      }
    );
  },
});

export const { setLeadDetailsDuringSiteVisit } =
  leadDetailsDuringSiteVisitSlice.actions;

export const getLeadDetailsDuringSiteVisit = (state: IProjectSliceState) =>
  state[sliceName].leadDetails;
