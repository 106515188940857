import styles from './styles.module.css';

export interface IExpandedDetailSectionProps {
  details: { label: string; value?: string }[];
}

export const DEFAULT_NULL_VALUE = '---';

const ExpandedDetailSection = (props: IExpandedDetailSectionProps) => {
  const { details } = props;

  return (
    <div className={styles.allDetailsContainer}>
      {details.map((detail) => (
        <div key={detail.label} className={styles.individualDetailContainer}>
          <div className={styles.detailLabel}>{detail.label}</div>
          <p className={styles.detailValue}>
            {detail.value ? detail.value : DEFAULT_NULL_VALUE}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ExpandedDetailSection;
