import {
  ColDef,
  FilterModel,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, Ref, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { ErrorBoundary } from 'react-error-boundary';

import AgGridTableWrapper from '@/apps/admin/components/common/AgGridTableWrapper';
import ExpandedDetailSection, {
  IExpandedDetailSectionProps,
} from '@/apps/admin/components/common/ExpandedDetailSection';
import { ErrorFallback } from '@/atoms/ErrorBoundary/ErrorFallback';
import {
  DEFAULT_COLUMN_DEF,
  TABLE_CONFIG,
} from '@/components/molecules/Table/config';
import { IProject } from '@/interfaces';
import { IFilterConfig, IFilterState } from '@/molecules/Filter';
import { useGetFilter } from '@/molecules/Filter/hooks/FilterContext';
import { usePageNumberUrlParam } from '@/molecules/Table/hooks/usePageNumberUrlParam';
import { useUpdateTableDataUsingRef } from '@/molecules/Table/hooks/useUpdateTableDataUsingRef';
import { fetchRowsCount } from '@/molecules/Table/Main/fetchRowsCount';
import {
  INoRowsExistProps,
  NoRowsExist,
} from '@/molecules/Table/Main/NoRowsExist';
import { convertToFilterObject } from '@/molecules/Table/utils/convertToFilterObject';

import { fetchRows } from './fetchRows';

export type IGridHandle<TRowData> = {
  refresh: (purge?: boolean) => void;
  updateRow: (rowId: string, data: TRowData) => void;
  deleteRow: (rowId: string) => void;
  addRow: (data: TRowData) => void;
  getRowData(rowId: string): TRowData;
};

const MainImpl = <TRowData, TFetchData, TCountData>(
  props: ITableMain<TRowData, TFetchData, TCountData>,
  ref?: Ref<IGridHandle<TRowData>>
) => {
  const { apiConfig, detailCellRendererParams, filterConfig, getRowId } = props;
  const getColDefsFromChildren = props.children?.map((child) => child.props);
  const [state] = useGetFilter();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowAvailabilityStatus, setRowAvailabilityStatus] =
    useState<INoRowsExistProps['rowAvailabilityStatus']>(null);

  const { onPaginationChanged, navigateToDefaultPage } =
    usePageNumberUrlParam();

  useUpdateTableDataUsingRef<TRowData>(gridApi, ref);

  const updateRowCount = async (filters: Record<string, string> = {}) => {
    const count = await fetchRowsCount<TRowData, TFetchData, TCountData>(
      filters,
      apiConfig
    );

    return count;
  };

  const onGridReady = async (params: GridReadyEvent) => {
    setGridApi(params.api);
    await updateRowCount();

    const updateData = () => {
      const dataSource: IServerSideDatasource = {
        getRows: async function (params: IServerSideGetRowsParams) {
          const filterPayload = convertToFilterObject(
            params.request.filterModel as FilterModel,
            filterConfig
          );

          const rowData = await fetchRows<TRowData, TFetchData, TCountData>(
            params.request.startRow || TABLE_CONFIG.DEFAULT_START_ROW,
            filterPayload,
            apiConfig
          );
          const count = await updateRowCount(filterPayload);

          const noDataExists = !count;
          const filtersApplied = !!Object.keys(filterPayload).length;

          if (noDataExists && filtersApplied) {
            setRowAvailabilityStatus('NO_ROWS_FILTER');
          } else if (noDataExists) {
            setRowAvailabilityStatus('NO_ROWS');
          } else {
            setRowAvailabilityStatus(null);
          }

          params.success({
            rowData,
            rowCount: count,
          });
        },
      };

      params.api.setGridOption('serverSideDatasource', dataSource);
    };

    updateData();
  };

  useEffect(() => {
    if (!gridApi) return;

    const currentFilterModel = gridApi.getFilterModel();
    const filterModel: Record<
      string,
      Record<string, string | DateRange>
    > = getFilterModel(state);
    const isFilterModelSame = currentFilterModel == filterModel;

    if (isFilterModelSame) return;
    gridApi.setFilterModel(filterModel);
    gridApi.onFilterChanged();
  }, [state, gridApi]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AgGridTableWrapper rowAvailabilityStatus={rowAvailabilityStatus}>
        <AgGridReact<TRowData>
          getRowId={getRowId}
          onGridReady={onGridReady}
          enableAdvancedFilter={false}
          defaultColDef={DEFAULT_COLUMN_DEF}
          columnDefs={getColDefsFromChildren}
          onFirstDataRendered={navigateToDefaultPage}
          // layout related props
          animateRows
          detailRowAutoHeight
          // Pagination related props
          pagination
          paginationPageSize={TABLE_CONFIG.PAGINATION_PAGE_SIZE}
          paginationPageSizeSelector={false}
          onPaginationChanged={onPaginationChanged}
          // Data loading model related props
          cacheBlockSize={TABLE_CONFIG.CACHE_BLOCK_SIZE}
          maxBlocksInCache={TABLE_CONFIG.MAX_BLOCKS_IN_CACHE}
          rowModelType={TABLE_CONFIG.ROW_MODEL_TYPE}
          // Expand Section Related Props
          masterDetail
          detailCellRenderer='detailCellRenderer'
          components={{
            detailCellRenderer: ExpandedDetailSection,
          }}
          detailCellRendererParams={detailCellRendererParams}
          // other props
          enableBrowserTooltips
          suppressDragLeaveHidesColumns
        />

        <NoRowsExist rowAvailabilityStatus={rowAvailabilityStatus} />
      </AgGridTableWrapper>
    </ErrorBoundary>
  );
};

export const Main = forwardRef(MainImpl) as <TRowData, TFetchData, TCountData>(
  props: ITableMain<TRowData, TFetchData, TCountData> & {
    ref?: Ref<IGridHandle<TRowData>>;
  }
) => ReturnType<typeof MainImpl>;

export type ITableApiConfig<TFetchData, TRowData, TCountData> = {
  getQParams: (filterModel: Record<string, string>) => Record<string, unknown>;
  baseApi: string;
  selectData: (data: TFetchData) => TRowData[];
  selectCount: (data: TCountData) => number;
};

export function getFilterModel(filterState: IFilterState) {
  const filterModel: Record<string, Record<string, string | DateRange>> = {};
  if (filterState) {
    Object.values(filterState).forEach((value) => {
      if (!value?.value) return;

      if (value.fieldType === 'date') {
        filterModel[value.value] = {
          type: 'inRange',
          filterType: 'date',
          // FIXME: ts table
          // @ts-expect-error fix this
          dateFrom: value.filter?.['from'],
          // FIXME: ts table
          // @ts-expect-error fix this
          dateTo: value.filter?.['to'],
        };
        return;
      }

      filterModel[value.value] = {
        type: 'equals',
        filterType: 'text',
        ...value,
      };
    });
  }
  return filterModel;
}

export type ITableMain<TRowData, TFetchData, TCountData> = {
  children: React.ReactElement<ColDef>[];
  detailCellRendererParams?: (
    params: ICellRendererParams<TRowData>
  ) => IExpandedDetailSectionProps;
  apiConfig: ITableApiConfig<TFetchData, TRowData, TCountData>;
  filterConfig: IFilterConfig;
  getRowId?: GridOptions<TRowData>['getRowId'];
  getRowClass?: (params: { data: IProject }) => string;
};

export type IPageDetails = {
  totalPages: number | null;
  currentPage: number | null;
};
