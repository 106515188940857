import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAllocationDetails } from '@/admin/components/pages/ProjectAllocations/AllocationsList';
import type { IEOI } from '@/admin/interfaces';
import { IUser } from '@/interfaces';

export enum AddAllocationStates {
  LEAD,
  UNIT_SELECTION,
  BOOKING_DETAILS,
  ADD_UPDATE_APPLICANT,
  PAYMENT,
  FEEDBACK,
}

interface IState {
  addAllocationState: AddAllocationStates;
  lead?: IUser;
  selectedEoI?: IEOI;
  allocation?: IAllocationDetails;
}

const initialState: IState = {
  addAllocationState: AddAllocationStates.LEAD,
};

const sliceName = 'addAllocationSlice';

export interface IAddAllocationSlice {
  [sliceName]: IState;
}

export const addAllocationSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setAddAllocationState: (
      state,
      action: PayloadAction<AddAllocationStates>
    ) => {
      state.addAllocationState = action.payload;
    },
    setAddAllocationLead: (state, action: PayloadAction<IUser | undefined>) => {
      state.lead = action.payload;
    },
    setAddAllocationSelectedEoI: (
      state,
      action: PayloadAction<IEOI | undefined>
    ) => {
      state.selectedEoI = action.payload;
    },
    setAddAllocationDetails: (
      state,
      action: PayloadAction<IAllocationDetails | undefined>
    ) => {
      state.allocation = action.payload;
    },
  },
});

export const {
  setAddAllocationState,
  setAddAllocationLead,
  setAddAllocationSelectedEoI,
  setAddAllocationDetails,
} = addAllocationSlice.actions;

export const getAddAllocationState = (state: IAddAllocationSlice) =>
  state.addAllocationSlice.addAllocationState;
export const getAddAllocationLead = (state: IAddAllocationSlice) =>
  state.addAllocationSlice.lead;
export const getAddAllocationSelectedEoI = (state: IAddAllocationSlice) =>
  state.addAllocationSlice.selectedEoI;
export const getAddAllocationDetails = (state: IAddAllocationSlice) =>
  state.addAllocationSlice.allocation;
