import { adminAPISlice } from '@/admin/services/index';

export const unitsAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    reserveUnitByUnitCartId: build.mutation({
      query: (unitCartId) => ({
        url: `api/units/reserve`,
        method: 'POST',
        body: {
          unitCartId,
        },
      }),
    }),
    blockUnitByUnitCartId: build.mutation({
      query: (unitCartId) => ({
        url: `api/units/block`,
        method: 'POST',
        body: {
          unitCartId,
        },
      }),
    }),
    bookUnitByUnitCartId: build.mutation({
      query: (unitCartId) => ({
        url: `api/units/book`,
        method: 'POST',
        body: {
          unitCartId,
        },
      }),
    }),
    releaseUnitByUnitCartId: build.mutation({
      query: (unitCartId) => ({
        url: `api/units/release`,
        method: 'POST',
        body: {
          unitCartId,
        },
      }),
    }),

    getTagsByProjectId: build.query({
      query: ({ projectId }) => {
        return {
          url: `api/units/tags/projects/${projectId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: { data: string[] }) => {
        return response.data;
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useReserveUnitByUnitCartIdMutation,
  useBlockUnitByUnitCartIdMutation,
  useBookUnitByUnitCartIdMutation,
  useReleaseUnitByUnitCartIdMutation,
  useGetTagsByProjectIdQuery,
} = unitsAPISlice;
