import React, { useEffect, useRef, useState } from 'react';

import { motion } from 'framer-motion';

import { Icon } from '@/atoms/Icon';

export type TOAST_STATUSES = 'SUCCESS' | 'ERROR' | 'WARN' | 'INFO';

const SECOND = 1000;
const DEFAULT_DELAY = 0 * SECOND;

const getTimeoutByType = (type: TOAST_STATUSES) => {
  switch (type) {
    case 'ERROR':
      return 10 * SECOND;
    case 'SUCCESS':
      return 5 * SECOND;
    case 'WARN':
      return 10 * SECOND;
    default:
      return 5 * SECOND;
  }
};

const getIconByType = (type: TOAST_STATUSES) => {
  switch (type) {
    case 'SUCCESS':
      return 'task_alt';
    case 'ERROR':
      return 'schedule';
    case 'WARN':
      return 'warning';
    case 'INFO':
      return 'info';
    default:
      return 'schedule';
  }
};

export interface IToast {
  type: TOAST_STATUSES;
  primaryMessage: string;
  secondaryMessage?: string;
  timeout?: number;
  delay?: number;
}

interface IToastProps {
  toast: IToast;
  onToastDelete: () => void;
}

const Toast = (props: IToastProps) => {
  const {
    toast: {
      type,
      primaryMessage,
      secondaryMessage,
      timeout,
      delay = DEFAULT_DELAY,
    },
    onToastDelete,
  } = props;

  const [showWithDelay, setShowWithDelay] = useState(false);
  const [isMouseOvered, setIsMouseOvered] = useState(false);
  const [timeCount, setTimeCount] = useState(0);

  const toastRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        timeCount * SECOND >
          (timeout || getTimeoutByType(type) + DEFAULT_DELAY) &&
        !isMouseOvered
      ) {
        onToastDelete();
        return;
      }
      setTimeCount(timeCount + 1);
    }, SECOND);
    return () => {
      clearInterval(timer);
    };
  }, [isMouseOvered, onToastDelete, timeCount, timeout, type]);

  useEffect(() => {
    const onHover = () => {
      setIsMouseOvered(true);
    };
    const onLeave = () => {
      setIsMouseOvered(false);
    };

    const currentElm = toastRef && toastRef.current;
    if (currentElm) {
      currentElm.addEventListener('mouseover', onHover, false);
      currentElm.addEventListener('mouseleave', onLeave, false);

      return function cleanup() {
        currentElm.removeEventListener('mouseover', onHover, false);
        currentElm.removeEventListener('mouseleave', onLeave, false);
      };
    }
  }, []);

  useEffect(() => {
    const showDelayTimer = setTimeout(() => {
      setShowWithDelay(true);
    }, delay);

    return () => {
      clearTimeout(showDelayTimer);
    };
  }, [delay]);

  return (
    <div ref={toastRef as React.RefObject<HTMLDivElement>}>
      {showWithDelay && (
        <motion.div
          layout
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -400 }}
          className='toast'>
          <span className='icon-container'>
            <Icon
              name={getIconByType(type)}
              propStyles={`icon--${type.toLowerCase()}`}
            />
          </span>
          <div className='toast-body'>
            <div className='toast-body__primary-message'>{primaryMessage}</div>
            <div className='toast-body__secondary-message'>
              {secondaryMessage}
            </div>
          </div>
          <Icon
            name='close'
            propStyles='icon-cross'
            onClick={() => onToastDelete()}
          />
        </motion.div>
      )}
    </div>
  );
};

export default Toast;
