import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseUrl } from '@/constants/urls';

export const siteVisitAPISlice = createApi({
  reducerPath: 'siteVisitAPI',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      return headers;
    },
  }),
  endpoints: (build) => ({
    // siteVisitOld & siteVisitsByUserIdOld apis used in booking engine
    siteVisitOld: build.mutation({
      query: ({ ...body }) => {
        return {
          url: `sitevisit`,
          method: 'POST',
          body,
        };
      },
    }),

    siteVisitsByUserIdOld: build.mutation({
      query: (userId) => {
        return {
          url: `sitevisit_list/${userId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useSiteVisitOldMutation, useSiteVisitsByUserIdOldMutation } =
  siteVisitAPISlice;
