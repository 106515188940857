import type {
  Colors,
  FontFamily,
  FontWeight,
  Size,
  TextTransform,
} from '@/atoms/atoms';
import { TEXT_TOKENS } from '@/atoms/Colors/Colors';
import { multipleStringHighlights } from '@/atoms/common/stringHighlight';

import styles from './Text.module.css';

export const Text = (props: ITextProps) => {
  const {
    size = 'sm',
    color = '',
    weight = 'normal',
    fontFamily = 'roboto',
    text,
    textTransform = 'none',
    customClasses = '',
    token,
    ellipsis,
    maxLines = 1,
    highlights = [],
  } = props;

  const sizeClass = sizeToClassMapping[size];
  const weightClass = weightToClassMapping[weight];
  const fontClass = fontFamilyClassMapping[fontFamily];
  const ellipsisClass = ellipsis
    ? maxLines > 1
      ? styles.ellipsisMultiLine
      : styles.ellipsisOneLine
    : '';

  return (
    <p
      className={[
        sizeClass,
        weightClass,
        fontClass,
        customClasses,
        styles.textStyles,
        ellipsisClass,
      ].join(' ')}
      style={
        {
          color: token ? TEXT_TOKENS[token] : `var(--${color})`,
          textTransform,
          '--max-lines': maxLines,
        } as React.CSSProperties
      }>
      {multipleStringHighlights(text, highlights)}
    </p>
  );
};

const sizeToClassMapping: Record<Size, string> = {
  sm: styles.smallSize,
  md: styles.mediumSize,
  lg: styles.largeSize,
  xs: styles.extraSmallSize,
  xxs: styles.extraExtraSmallSize,
  xl: styles.extraLargeSize,
} as const;

const weightToClassMapping: Record<FontWeight, string> = {
  light: styles.lightWeight,
  normal: styles.normalWeight,
  semibold: styles.semiboldWeight,
  bold: styles.boldWeight,
  extrabold: styles.extraBoldWeight,
  black: styles.blackWeight,
};

const fontFamilyClassMapping: Record<FontFamily, string> = {
  poppins: styles.poppinsFont,
  roboto: styles.robotoFont,
};

interface CommonTextProps {
  size?: Size;
  color?: Colors;
  weight?: FontWeight;
  fontFamily?: FontFamily;
  text: string;
  customClasses?: string;
  textTransform?: TextTransform;
  highlights?: string[];
  token?: keyof typeof TEXT_TOKENS;
}

type EllipsisConditionalProps =
  | { ellipsis?: true; maxLines?: number }
  | {
      ellipsis?: false;
      maxLines?: never;
    };

export type ITextProps = CommonTextProps & EllipsisConditionalProps;
