import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseUrl } from '@/constants/urls';
import type { IResponseBase, IUnit } from '@/interfaces';

export const unitsAPISlice = createApi({
  reducerPath: 'unitsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      return headers;
    },
  }),
  endpoints: (build) => ({
    // Units
    getUnitDetails: build.query({
      query: ({ id, towerName, blockName, number }) => {
        const idQuery = id ? `id=${id}` : '';
        return `unit_details?${idQuery}&blockName=${blockName}&towerName=${towerName}&number=${number}`;
      },
      keepUnusedDataFor: 120,
    }),

    getUnitList1: build.query({
      query: ({ thirdPartyProjectId }: { thirdPartyProjectId: string }) => {
        return `api/units/get-all/${thirdPartyProjectId}`;
      },
      transformResponse: (response: ITransformUnitsResponse) => {
        return response.data;
      },
      keepUnusedDataFor: 120,
    }),
    getUnitListByProjectId: build.query({
      query: ({
        projectId,
        nonPartial,
      }: {
        projectId: string;
        nonPartial: boolean;
      }) => {
        return `api/units/get-all/project/${projectId}?nonPartial=${nonPartial}`;
      },
      transformResponse: (response: ITransformUnitsResponse) => {
        return response.data;
      },
      keepUnusedDataFor: 120,
    }),

    blockUnit: build.mutation({
      query: (unitCartId) => {
        return {
          url: `block_unit/${unitCartId}`,
          method: 'PUT',
        };
      },
    }),

    syncInventory: build.mutation({
      query: (data) => ({
        url: 'api/units/sync-invenotry',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: ITransformSyncUnitResponse) => {
        return response;
      },
    }),

    // PDF
    costSheetPdf: build.mutation({
      query: ({ user_id, unit_id }) => {
        return {
          url: `costsheet_pdf?${
            user_id && `user_id=${user_id}`
          }&unit_id=${unit_id}`,
          method: 'POST',
        };
        // TODO: Cache forever
      },
    }),

    paymentPlanPdf: build.mutation({
      query: (unitCartId) => {
        return {
          url: `payment_plan_pdf?unit_cart_id=${unitCartId}`,
          method: 'POST',
        };
        // TODO: Cache forever
      },
    }),

    agreementPdf: build.mutation({
      query: (unitCartId) => {
        return {
          url: `agreement_pdf?unit_cart_id=${unitCartId}`,
          method: 'POST',
        };
        // TODO: Cache forever
      },
    }),
  }),
});

interface ITransformUnitsResponse extends IResponseBase {
  data: IUnit[];
}

interface ITransformSyncUnitResponse extends IResponseBase {
  data: {
    validatedInfo: {
      unitName: string;
      result: string;
    }[];
  };
}

export const {
  // Unit
  useGetUnitDetailsQuery,
  useGetUnitList1Query,
  useLazyGetUnitList1Query,
  useGetUnitListByProjectIdQuery,
  useLazyGetUnitListByProjectIdQuery,
  useBlockUnitMutation,
  useSyncInventoryMutation,

  // PDF
  useCostSheetPdfMutation,
  usePaymentPlanPdfMutation,
  useAgreementPdfMutation,
} = unitsAPISlice;
