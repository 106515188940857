import { ChangeEvent } from 'react';

import { Checkbox } from '@/atoms/Inputs/CheckboxGroup';
import { Text } from '@/atoms/Text/Text';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

import { IFilterConfig, IFilterState, TextFilterConfig } from './index';

export const FilterCheckbox = (props: IFilterCheckboxProps) => {
  const {
    state,
    filterConfig,
    setState,
    setIsFiltersVisible,
    isFiltersVisible,
  } = props;

  const defaultState =
    state &&
    Object.values(state)
      .map((filter) => filter?.value)
      .filter((val) => val);

  return (
    <FlexDeprecated
      UNSAFE_STYLES={{
        visibility: isFiltersVisible ? 'visible' : 'hidden',
        height: isFiltersVisible ? 'auto' : '0px',
      }}>
      <Text text='Select a Field' color='color-primary-64' />
      {/* // FIXME: ts table siddhant
        // @ts-expect-error fix this       */}
      <Checkbox.Root name='filter-checkbox' defaultValue={defaultState || []}>
        {filterConfig.map((filter: TextFilterConfig) => (
          <Checkbox.Item
            disabled={!!state?.[filter.fieldType]}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.checked) {
                setState({
                  ...state,
                  [filter.fieldType]: filter,
                });
              }
              setIsFiltersVisible(false);
            }}
            key={filter.value}
            content={<Text size='sm' weight='bold' text={filter.label} />}
            value={filter.value}
            variant='label'
          />
        ))}
      </Checkbox.Root>
    </FlexDeprecated>
  );
};
type IFilterCheckboxProps = {
  state: IFilterState;
  filterConfig: IFilterConfig;
  setState: React.Dispatch<React.SetStateAction<IFilterState>>;
  setIsFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isFiltersVisible: boolean;
};
