import {
  addMonths as dateFnsAddMonths,
  addYears as dateFnsAddYears,
  differenceInCalendarDays as dateFnsDifferenceInCalendarDays,
  differenceInCalendarMonths as dateFnsDifferenceInCalendarMonths,
  differenceInCalendarYears as dateFnsDifferenceInCalendarYears,
  differenceInDays as dateFnsDifferenceInDays,
  differenceInHours as dateFnsDifferenceInHours,
  differenceInMinutes as dateFnsDifferenceInMinutes,
  differenceInMonths as dateFnsDifferenceInMonths,
  differenceInSeconds as dateFnsDifferenceInSeconds,
  differenceInYears as dateFnsDifferenceInYears,
  endOfMonth as dateFnsEndOfMonth,
  format as dateFnsFormat,
  isAfter as dateFnsIsAfter,
  isEqual as dateFnsIsEqual,
  isPast as dateFnsIsPast,
  isSaturday as dateFnsIsSaturday,
  isSunday as dateFnsIsSunday,
  isToday as dateFnsIsToday,
  setDate as dateFnsSetDate,
} from 'date-fns';

const FormatTo = {
  YYYY_MM_DD: 'yyyy-MM-dd', // 2024-01-14
  DD_MM_YYYY: 'dd-MM-yyyy', // 14-01-2024
  LONG_DATE: 'do MMMM, yyyy', // 14th January, 2024
  MONTH_DATE: 'do MMMM', // 14th January
  TIME_AM_PM_LONG_DATE: 'pp PP', // 10:14:10 PM Jan 14, 2024
  SHORT_DAY_TIME_LONG_DATE: 'p eeee, do MMMM', // 10:40 PM Tue, 18 Oct
  ISO_DATE_TIME: "yyyy-MM-dd'T'HH:mm:ss", // 2024-01-14T22:15:14
  LONG_DATE_DAY: 'do MMMM yyyy, eeee', // 14th January 2024, Sunday
  DAY_DATE_LONG_DATE: 'eeee, do MMMM yyyy', // Sunday, 14th January 2024
  MONTH_DATE_SHORT_TIME: 'do MMMM, p', // 14th January, 10:14 PM
  SHORT_DAY_TIME: 'p eeee', // 10:40 PM Tuesday
  SHORT_TIME: 'p', // 10:14 PM
} as const;

type DateType = Date | number | string;
type FormatType = (typeof FormatTo)[keyof typeof FormatTo];

const DefaultType: FormatType = FormatTo.ISO_DATE_TIME;

const Month = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
} as const;

const currentDate = () => {
  return new Date();
};

const convertIntoDate = (date: DateType) => {
  const parsedDate = new Date(date);
  const isInvalid =
    isNaN(parsedDate.getTime()) || date === null || date === undefined;

  // Check if the date is valid
  if (isInvalid) {
    // TODO: log this as error at Datadog
    return new Date();
  }

  return parsedDate;
};

const format = (date: DateType, formatType?: FormatType) => {
  return dateFnsFormat(
    convertIntoDate(date),
    formatType ? formatType : DefaultType
  );
};

const isToday = (date: DateType) => {
  return dateFnsIsToday(convertIntoDate(date));
};

const isWeekend = (date: DateType) => {
  return (
    dateFnsIsSunday(convertIntoDate(date)) ||
    dateFnsIsSaturday(convertIntoDate(date))
  );
};

const getDifferenceInCalendarDays = (
  laterDate: DateType,
  earlierDate: DateType
) => {
  return dateFnsDifferenceInCalendarDays(
    convertIntoDate(laterDate),
    convertIntoDate(earlierDate)
  );
};

const getDifferenceInCalendarHours = (
  laterDate: DateType,
  earlierDate: DateType
) => {
  return dateFnsDifferenceInHours(
    convertIntoDate(laterDate),
    convertIntoDate(earlierDate)
  );
};

const getDifferenceInCalendarMinutes = (
  laterDate: DateType,
  earlierDate: DateType
) => {
  return dateFnsDifferenceInMinutes(
    convertIntoDate(laterDate),
    convertIntoDate(earlierDate)
  );
};

const getDifferenceInCalendarMonths = (
  laterDate: DateType,
  earlierDate: DateType
) => {
  return dateFnsDifferenceInCalendarMonths(
    convertIntoDate(laterDate),
    convertIntoDate(earlierDate)
  );
};

const getDifferenceInCalendarYears = (
  laterDate: DateType,
  earlierDate: DateType
) => {
  return dateFnsDifferenceInCalendarYears(
    convertIntoDate(laterDate),
    convertIntoDate(earlierDate)
  );
};

const isPast = (date: DateType) => {
  return dateFnsIsPast(convertIntoDate(date));
};

const addMonths = (date: DateType, numberOfMonths: number) => {
  return dateFnsAddMonths(convertIntoDate(date), numberOfMonths);
};

const addYears = (date: DateType, numberOfYears: number) => {
  return dateFnsAddYears(convertIntoDate(date), numberOfYears);
};

const setDate = (date: DateType, day: number) => {
  return dateFnsSetDate(convertIntoDate(date), day);
};

const endOfMonth = (date: DateType) => {
  return dateFnsEndOfMonth(convertIntoDate(date));
};

const isAfterOrEqual = (laterDate: DateType, earlierDate: Date) => {
  return (
    dateFnsIsAfter(convertIntoDate(laterDate), convertIntoDate(earlierDate)) ||
    dateFnsIsEqual(convertIntoDate(laterDate), convertIntoDate(earlierDate))
  );
};

const formatDateDifference = (date1: Date, date2: Date): string => {
  const diffInSeconds = dateFnsDifferenceInSeconds(date2, date1);

  if (diffInSeconds > 59) {
    const diffInMinutes = dateFnsDifferenceInMinutes(date2, date1);
    if (diffInMinutes > 59) {
      const diffInHours = dateFnsDifferenceInHours(date2, date1);
      if (diffInHours > 23) {
        const diffInDays = dateFnsDifferenceInDays(date2, date1);
        if (diffInDays > 30) {
          const diffInMonths = dateFnsDifferenceInMonths(date2, date1);
          if (diffInMonths > 11) {
            const diffInYears = dateFnsDifferenceInYears(date2, date1);
            return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'}`;
          }
          return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'}`;
        }
        return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'}`;
      }
      return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'}  `;
    }
    return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'}`;
  }

  return `${diffInSeconds} ${diffInSeconds === 1 ? 'second' : 'seconds'}`;
};

export const DateUtils = {
  currentDate,
  convertIntoDate,
  format,
  isToday,
  isWeekend,
  getDifferenceInCalendarDays,
  getDifferenceInCalendarHours,
  getDifferenceInCalendarMinutes,
  getDifferenceInCalendarMonths,
  getDifferenceInCalendarYears,
  isPast,
  addMonths,
  addYears,
  setDate,
  endOfMonth,
  isAfterOrEqual,
  formatDateDifference,
  FormatTo,
  DefaultType,
  Month,
};
