import type { Range } from '@/interfaces/range';

import styles from './styles.module.css';

const SpinLoader = (props: ISpinProps) => {
  const { loaderWidth = 3 } = props;

  const spinnerStyles = {
    width: `${loaderWidth}rem`,
    height: `${loaderWidth}rem`,
  };
  return (
    <div className={styles.loadingSpinContainer}>
      <span style={spinnerStyles} className={styles.loadingSpin} />
    </div>
  );
};

export default SpinLoader;

interface ISpinProps {
  loaderWidth?: Range<1, 101>;
}
