import { useState } from 'react';
import MoreMenu, { MenuItem } from 'src/apps/admin/components/common/MoreMenu';
import { PaymentHistoryModal } from 'src/apps/admin/components/common/PaymentHistoryModal';

import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { IPaymentOrder } from '@/booking/interfaces';

export const PaymentsMoreActionMenu = (props: IPaymentMoreActionMenuProps) => {
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);

  const moreMenuOptions: MenuItem[] = [
    {
      displayText: 'Payment History',
      icon: 'fiber_smart_record',
      onClick: () => setShowPaymentHistory(true),
    },
  ];
  return (
    <>
      <ConditionalRendering showIf={showPaymentHistory}>
        <PaymentHistoryModal
          {...props}
          onClose={() => setShowPaymentHistory(false)}
        />
      </ConditionalRendering>
      <MoreMenu menuItems={moreMenuOptions} />
    </>
  );
};

export interface IPaymentMoreActionMenuProps {
  showBreadCrumb?: boolean;
  leadName: string;
  paymentOrders: IPaymentOrder[];
}
