import { createSlice } from '@reduxjs/toolkit';

import type { TObjectId } from '@/interfaces';

const sliceName = 'leadUserDetailsSlice';

interface IState {
  leadUserId: TObjectId;
}

interface ILeadUserSliceState {
  [sliceName]: IState;
}
interface IInitialLeadUserState {
  leadUserId: TObjectId;
}
const initialState: IInitialLeadUserState = {
  leadUserId: '',
};

export const leadUserDetailsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLeadUserDetails: (state, action) => {
      state.leadUserId = action.payload;
    },
  },
});

export const getLeadUserDetails = (state: ILeadUserSliceState) =>
  state.leadUserDetailsSlice.leadUserId;

export const { setLeadUserDetails } = leadUserDetailsSlice.actions;
