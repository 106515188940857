import * as React from 'react';

import { DEFAULT_NULL_VALUE } from '@/admin/components/common/ExpandedDetailSection';
import StatusIndicator from '@/admin/components/common/StatusIndicator';
import { TextWithPrefixAndSuffix } from '@/admin/components/common/TextWithPrefixAndSuffix';
import type { Colors, FontFamily, Size } from '@/atoms/atoms';
import { Button, IButton } from '@/atoms/Button';
import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Container } from '@/atoms/Container';
import { Flex } from '@/atoms/Flex';
import Gap from '@/atoms/Gap';
import { Icon, IconNames } from '@/atoms/Icon';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

import styles from './styles.module.css';

interface IHistoryCardProps {
  endIcon?: {
    name: IconNames;
    action?: (history?: unknown) => void;
  };
  children?: React.ReactNode;
  buttonDetails?: IButton;
  historyDetails: {
    iconName: IconNames;
    prefixInfo?: string;
    suffixInfo?: string;
    highlightedInfo: string;
    status?: string;
    statusSuffix?: React.ReactNode;
    statusVariant?: Colors;
    statusIndicatorFontSize: Size;
  };
  fontFamily?: FontFamily;
}

export const HistoryCard = (props: IHistoryCardProps) => {
  const { buttonDetails, endIcon, historyDetails, children, fontFamily } =
    props;

  return (
    <>
      <div className={styles.cardWrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.iconContainer}>
            <Icon
              name={historyDetails.iconName}
              propStyles={styles.historyIcon}
            />
          </div>
          <div className={styles.historyInfo}>
            <TextWithPrefixAndSuffix
              textPrefix={historyDetails.prefixInfo}
              textSuffix={historyDetails.suffixInfo}
              highlightedText={historyDetails.highlightedInfo}
              fontFamily={fontFamily}
            />
            <div className={styles.statusInfo}>
              <ConditionalRendering showIf={historyDetails?.status}>
                <StatusIndicator
                  variant={historyDetails.statusVariant}
                  label={historyDetails.status || DEFAULT_NULL_VALUE}
                  fontSize={historyDetails.statusIndicatorFontSize}
                  fontFamily={fontFamily}
                />
              </ConditionalRendering>
              <Gap numberOfSpaces={1} />
              {!React.isValidElement(historyDetails.statusSuffix) ? (
                <span className={styles.statusSuffix}>
                  {historyDetails.statusSuffix}
                </span>
              ) : (
                historyDetails.statusSuffix
              )}
            </div>
          </div>
        </div>

        <Flex gap={2} alignItems='center'>
          <ConditionalRendering showIf={buttonDetails?.children}>
            <Button
              onClick={buttonDetails?.onClick}
              accent='bordered'
              beforeIcon={buttonDetails?.beforeIcon}
              disabled={buttonDetails?.disabled}>
              {buttonDetails?.children}
            </Button>
          </ConditionalRendering>
          <ConditionalRendering showIf={endIcon?.name}>
            <Container width='3rem'>
              <Icon
                name={endIcon?.name as IconNames}
                propStyles={styles.infoIcon}
                onClick={endIcon?.action}
              />
            </Container>
          </ConditionalRendering>
        </Flex>
      </div>
      <ConditionalRendering showIf={children}>
        <div className={styles.detailedContent}>
          <FlexDeprecated flexColumn spacing={0.8}>
            {children}
          </FlexDeprecated>
        </div>
      </ConditionalRendering>
    </>
  );
};
