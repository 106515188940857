import { UserRole } from '@/constants/userRoles';
import type { IUser } from '@/interfaces';

export const adminUserLocalStorageItemName = 'adminUser';

export const isAdminUserLoggedIn = () =>
  !!localStorage.getItem(adminUserLocalStorageItemName);

export const isAdminUserRole = (role: UserRole) => {
  return getAdminUserLocal().role === role;
};

export const getAdminUserRole = (): UserRole => getAdminUserLocal().role;

export const setAdminUserLocal = (adminUser: IUser) => {
  localStorage.setItem(
    adminUserLocalStorageItemName,
    JSON.stringify(adminUser)
  );
};

export const getAdminUserLocal = (): IUser => {
  const adminUserStr = localStorage.getItem(
    adminUserLocalStorageItemName
  ) as string;
  return JSON.parse(adminUserStr);
};
