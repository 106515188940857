import { useState } from 'react';

import { Button } from '@/atoms/Button';
import { ModalWrapper } from '@/atoms/ModalWrapper';

import styles from './styles.module.css';

interface IUpdateSVGProps {
  fileName: string;
  svgPathIds: string[];
  onModalClose: (svgPathIds: string[], updatedFileName: string) => void;
  fileStatus: string;
}

const UpdateSVGModal = (props: IUpdateSVGProps) => {
  const { onModalClose, fileName, svgPathIds, fileStatus } = props;

  const [newValues, setNewValues] = useState([...svgPathIds]);
  const [updatedFileName, setUpdatedFileName] = useState(fileName);

  const handleInputChange = (index: number, newValue: string) => {
    const updatedValues = [...newValues];
    updatedValues[index] = newValue;
    setNewValues(updatedValues);
  };

  return (
    <ModalWrapper
      onClose={() => onModalClose(newValues, updatedFileName)}
      modalTitle='Update File'
      subtitle={`Edit details. ${fileStatus}`}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeading}>File name: </h2>
          <input
            style={{ width: '50%' }}
            type='text'
            value={updatedFileName}
            onChange={(e) => setUpdatedFileName(e.target.value)}
          />
        </div>
      </header>
      {svgPathIds.length > 0 && (
        <div className={styles.tableWrapper}>
          <table className={styles.updateTable}>
            <thead>
              <tr>
                <th className={styles.updateTh}>Current Path Id</th>
                <th className={styles.updateTh}>New Path Id</th>
              </tr>
            </thead>
            <tbody>
              {svgPathIds.map((pathId, index) => (
                <tr key={index}>
                  <td className={styles.updateTd}>{pathId}</td>
                  <td className={styles.updateTd}>
                    <div className={styles.updateSVGInput}>
                      <input
                        type='text'
                        value={newValues[index]}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => onModalClose(newValues, updatedFileName)}
          propStyles={styles.addEoiButton}>
          Save
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default UpdateSVGModal;
