import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum PROJECT_EOI_STATE {
  ADD_OR_UPDATE_EOI,
  EOI_DETAILS,
  ADD_OR_UPDATE_APPLICANT,
  PAYMENT,
  FOLLOWUPS,
}

interface IState {
  projectEoiState: PROJECT_EOI_STATE;
}

const initialState: IState = {
  projectEoiState: PROJECT_EOI_STATE.ADD_OR_UPDATE_EOI,
};

const sliceName = 'projectEoiSlice';

export interface IProjectEoiSlice {
  [sliceName]: IState;
}

export const projectEoiSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setProjectEoiState: (state, action: PayloadAction<PROJECT_EOI_STATE>) => {
      state.projectEoiState = action.payload;
    },
  },
});

export const { setProjectEoiState } = projectEoiSlice.actions;

export const getProjectEoiState = (state: IProjectEoiSlice) =>
  state.projectEoiSlice.projectEoiState;
