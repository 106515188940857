import DesktopTabs from '@/admin/components/common/DesktopTabs';
import PrimaryTitleWithDescription from '@/admin/components/common/PrimaryTitleWithDescription';
import PaymentList from '@/admin/components/pages/ProjectEoiPayments/PaymentList';
import { COUNT_WHILE_DATA_IS_LOADING } from '@/admin/constants/helpers';
import { useGetProject } from '@/admin/hooks/useGetProject';
import { usePathParams } from '@/admin/hooks/usePathParams';
import { useGetProjectEoiPaymentsCountQuery } from '@/admin/services/projectsAPISlice';
import { PAYMENT_ORDER_STATUS } from '@/booking/constants';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';
import { ITab } from '@/cp/components/common/Tabs';
import { Filter, TextFilterConfig } from '@/molecules/Filter';
import { useGetFilter } from '@/molecules/Filter/hooks/FilterContext';
import { WithFilter } from '@/molecules/Filter/withFilter';
import { convertToFilterObject } from '@/molecules/Table/utils/convertToFilterObject';

export enum TAB_NAME {
  Pending = 'Pending',
  Completed = 'Completed',
  Lapsed = 'Lapsed',
  Failed = 'Failed',
  All = 'All',
}

const ProjectEoiPayments = () => {
  const { projectId } = usePathParams();
  const { project } = useGetProject();

  if (!projectId) {
    throw new Error('projectId not found');
  }

  const [, , filterModel] = useGetFilter();
  const filterPayload = convertToFilterObject(
    filterModel,
    eoiPaymentsSearchFieldOptions
  );

  const paymentLapsedTimeInMinutes = project?.payment?.eoi.durations.lapsed;
  const apiParams = getProjectPaymentsAPIParams(
    projectId,
    filterPayload,
    paymentLapsedTimeInMinutes
  );

  const {
    data,
    isLoading,
    refetch: refetchCount,
  } = useGetProjectEoiPaymentsCountQuery(apiParams);

  const tabs: ITab[] = [
    {
      id: '0',
      name: 'Pending',
      countNumber: !isLoading
        ? data?.[TAB_NAME.Pending]?.data.totalCount
        : COUNT_WHILE_DATA_IS_LOADING,
      content: (
        <PaymentList
          tabName={TAB_NAME.Pending}
          statuses={[
            PAYMENT_ORDER_STATUS.INITIATED,
            PAYMENT_ORDER_STATUS.PROCESSING,
          ]}
        />
      ),
    },
    {
      id: '1',
      name: 'Completed',
      countNumber: !isLoading
        ? data?.[TAB_NAME.Completed]?.data.totalCount
        : COUNT_WHILE_DATA_IS_LOADING,
      content: (
        <PaymentList
          tabName={TAB_NAME.Completed}
          statuses={[PAYMENT_ORDER_STATUS.COMPLETED]}
        />
      ),
    },
    {
      id: '2',
      name: 'Lapsed',
      countNumber: !isLoading
        ? data?.[TAB_NAME.Lapsed]?.data.totalCount
        : COUNT_WHILE_DATA_IS_LOADING,
      content: (
        <PaymentList
          tabName={TAB_NAME.Lapsed}
          statuses={[
            PAYMENT_ORDER_STATUS.INITIATED,
            PAYMENT_ORDER_STATUS.PROCESSING,
          ]}
        />
      ),
    },
    {
      id: '3',
      name: 'Failed',
      countNumber: !isLoading
        ? data?.[TAB_NAME.Failed]?.data.totalCount
        : COUNT_WHILE_DATA_IS_LOADING,
      content: (
        <PaymentList
          tabName={TAB_NAME.Failed}
          statuses={[PAYMENT_ORDER_STATUS.FAILED]}
        />
      ),
    },
    {
      id: '4',
      name: 'All',
      countNumber: !isLoading
        ? data?.[TAB_NAME.All]?.data.totalCount
        : COUNT_WHILE_DATA_IS_LOADING,
      content: (
        <PaymentList
          tabName={TAB_NAME.All}
          statuses={[
            PAYMENT_ORDER_STATUS.INITIATED,
            PAYMENT_ORDER_STATUS.PROCESSING,
            PAYMENT_ORDER_STATUS.COMPLETED,
            PAYMENT_ORDER_STATUS.FAILED,
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <FlexDeprecated justifyContent='space-between' flexRow={false}>
        <PrimaryTitleWithDescription
          type='tertiary'
          title='Explore payments done for EOIs'
        />
        <Filter filterConfig={eoiPaymentsSearchFieldOptions} />
      </FlexDeprecated>

      <DesktopTabs tabs={tabs} refetchCount={refetchCount} />
    </>
  );
};
export default WithFilter(ProjectEoiPayments);

export const eoiPaymentsSearchFieldOptions: TextFilterConfig[] = [
  {
    value: 'extendedDetails.eoiCategory',
    label: 'Category',
    fieldType: 'text',
    renameKeyBeforeSendingNetworkRequest: 'unitTag',
  },
  {
    value: 'extendedDetails.eoiType',
    label: 'EOI Type',
    fieldType: 'text',
    renameKeyBeforeSendingNetworkRequest: 'type',
  },
  { value: 'leadName', label: 'Lead name', fieldType: 'text' },
  {
    value: 'extendedDetails.executive',
    label: 'Executive name',
    fieldType: 'text',
    renameKeyBeforeSendingNetworkRequest: 'executiveName',
  },
  { value: 'leadMobile', label: 'Lead mobile', fieldType: 'text' },
  {
    value: 'extendedDetails.email',
    label: 'Lead email',
    fieldType: 'text',
    renameKeyBeforeSendingNetworkRequest: 'leadEmail',
  },
  { value: 'transactionId', label: 'Transaction Id', fieldType: 'text' },
  {
    value: 'extendedDetails.paymentOrders.failureReason',
    label: 'Failure Reason',
    fieldType: 'text',
    renameKeyBeforeSendingNetworkRequest: 'failureReason',
  },
  {
    value: 'leadLqrPins',
    label: 'Lqr Code',
    fieldType: 'text',
    renameKeyBeforeSendingNetworkRequest: 'pin',
  },
  {
    value: 'status',
    label: 'Payment Status',
    fieldType: 'text',
    renameKeyBeforeSendingNetworkRequest: 'paymentStatus',
  },
  {
    value: 'paidOn',
    label: 'Select Paid Date',
    fieldType: 'date',
  },
];
function getProjectPaymentsAPIParams(
  projectId: string,
  filterPayload: Record<string, string>,
  paymentLapsedTimeInMinutes?: number
) {
  return [
    {
      projectId,
      statuses: [
        PAYMENT_ORDER_STATUS.INITIATED,
        PAYMENT_ORDER_STATUS.PROCESSING,
      ],
      tabName: TAB_NAME.Pending,
      filterPayload,
      paymentLapsedTimeInMinutes,
    },
    {
      projectId,
      statuses: [PAYMENT_ORDER_STATUS.COMPLETED],
      tabName: TAB_NAME.Completed,
      filterPayload,
      paymentLapsedTimeInMinutes,
    },
    {
      projectId,
      statuses: [
        PAYMENT_ORDER_STATUS.INITIATED,
        PAYMENT_ORDER_STATUS.PROCESSING,
      ],
      tabName: TAB_NAME.Lapsed,
      filterPayload,
      paymentLapsedTimeInMinutes,
    },
    {
      projectId,
      statuses: [PAYMENT_ORDER_STATUS.FAILED],
      tabName: TAB_NAME.Failed,
      filterPayload,
      paymentLapsedTimeInMinutes,
    },
    {
      projectId,
      statuses: [
        PAYMENT_ORDER_STATUS.INITIATED,
        PAYMENT_ORDER_STATUS.PROCESSING,
        PAYMENT_ORDER_STATUS.COMPLETED,
        PAYMENT_ORDER_STATUS.FAILED,
      ],
      tabName: TAB_NAME.All,
      filterPayload,
      paymentLapsedTimeInMinutes,
    },
  ];
}
