import { ConditionalRendering } from '@/atoms/ConditionalRendering';
import { Copy } from '@/atoms/Copy';
import { Text } from '@/atoms/Text/Text';
import { DisplayInputs } from '@/booking/components/common/inputs/DisplayInputs';
import type { INote } from '@/booking/interfaces';
import { FlexDeprecated } from '@/components/deprecated/FlexDeprecated';

interface IPaymentCommentAndReceiptsProps {
  paymentNotes: INote[];
  receiptUrls: string[];
  paymentFailureReason?: string;
}

export const PaymentCommentAndReceipts = (
  props: IPaymentCommentAndReceiptsProps
) => {
  const { paymentNotes, receiptUrls, paymentFailureReason = '' } = props;

  return (
    <>
      <ConditionalRendering
        showIf={!paymentNotes.length && !paymentFailureReason}>
        <Text text='No Comment' color='color-cp-primary-83' />
      </ConditionalRendering>
      <ConditionalRendering showIf={paymentNotes.length}>
        <>
          <FlexDeprecated flexRow spacing={1}>
            <Text text='Comment' color='color-primary-64' />
            <Text text=':' color='color-primary-64' />
            <Text
              text={paymentNotes[paymentNotes.length - 1]?.value}
              color='color-primary-text-grey-45'
            />
          </FlexDeprecated>
          <DisplayInputs
            type='image'
            displayInputs={receiptUrls}
            startIcon='image'
          />
        </>
      </ConditionalRendering>
      <ConditionalRendering showIf={paymentFailureReason}>
        <FlexDeprecated flexRow spacing={1}>
          <Text text='Reason' color='color-primary-64' />
          <Text text=':' color='color-primary-64' />
          <Copy>
            <Text
              text={paymentFailureReason}
              color='color-primary-text-grey-45'
            />
          </Copy>
        </FlexDeprecated>
      </ConditionalRendering>
    </>
  );
};
